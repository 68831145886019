var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TssSelect", {
    attrs: {
      label: "display_name",
      options: _vm.options,
      loading: _vm.isLoading,
      multiple: _vm.multiple,
      tabindex: _vm.tabindex,
      disabled: _vm.disabled,
      dropdownPlaceholder: _vm.placeholder
    },
    model: {
      value: _vm.selected,
      callback: function($$v) {
        _vm.selected = $$v
      },
      expression: "selected"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }