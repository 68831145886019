var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "behavior-row-list no-padding" },
    [
      _vm.saving ? _c("SavingIndicator") : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.rows, function(row, i) {
        return _c("BehaviorRow", { key: i, attrs: { row: row } })
      }),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "state-wrapper" } },
        [
          _vm.loading
            ? _c("SpencilLoader", { staticClass: "mini" })
            : !_vm.studentGroup || !_vm.studentGroup.student_group_id
            ? _c("EmptyState", {
                staticClass: "mini",
                attrs: {
                  icon: "icon-group",
                  title: "Select a group",
                  details:
                    "Select a group above to log behaviors for multiple " +
                    _vm.studentsLower +
                    " in that group. Or choose individuals from the '" +
                    _vm.studentsTitle +
                    "' menu."
                }
              })
            : !_vm.students.length
            ? _c("EmptyState", {
                staticClass: "mini",
                attrs: {
                  icon: "icon-user",
                  title: "No " + _vm.studentsLower + " found",
                  details:
                    "There are no " + _vm.studentsLower + " in this group."
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }