var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dropdown v-select",
      class: _vm.dropdownClasses,
      attrs: { dir: _vm.dir }
    },
    [
      _c(
        "div",
        {
          ref: "toggle",
          class: ["dropdown-toggle", "clearfix"],
          attrs: {
            "tss-tooltip": "",
            "tss-tooltip-size": "medium",
            "tss-tooltip-template": "my-vue-select-tooltip",
            "tss-tooltip-force-align": "left",
            "tss-tooltip-data": JSON.stringify({
              values:
                !_vm.dropdownOpen && _vm.valueAsArray
                  ? _vm.valueAsArray.map(function(option) {
                      return _vm.getOptionLabel(option)
                    })
                  : []
            })
          },
          on: {
            mousedown: function($event) {
              $event.preventDefault()
              return _vm.toggleDropdown($event)
            }
          }
        },
        [
          _vm._t("spinner", [
            _vm.mutableLoading
              ? _c("i", { staticClass: "icon-spinner icon-spin" })
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.valueAsArray ||
          (!_vm.valueAsArray.length && _vm.dropdownPlaceholder)
            ? _c("span", { staticClass: "dropdown-placeholder" }, [
                _c("span", { staticClass: "overflow-ellipsis" }, [
                  _vm._v(
                    "\n        " + _vm._s(this.dropdownPlaceholder) + "\n      "
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.noTags && _vm.valueAsArray && _vm.valueAsArray.length
            ? _c("span", { staticClass: "selected-text" }, [
                _c("span", { staticClass: "overflow-ellipsis" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.valueAsArray
                          .map(function(option) {
                            return _vm.getOptionLabel(option)
                          })
                          .join(", ")
                      ) +
                      "\n      "
                  )
                ])
              ])
            : _vm._l(_vm.valueAsArray, function(option) {
                return _c(
                  "span",
                  { key: option.index, staticClass: "selected-tag" },
                  [
                    _vm._t(
                      "selected-option",
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getOptionLabel(option)) +
                            "\n        "
                        )
                      ],
                      null,
                      option
                    ),
                    _vm._v(" "),
                    _vm.multiple
                      ? _c(
                          "button",
                          {
                            staticClass: "close",
                            attrs: {
                              disabled: _vm.disabled,
                              type: "button",
                              "aria-label": "Remove option"
                            },
                            on: {
                              click: function($event) {
                                return _vm.deselect(option)
                              }
                            }
                          },
                          [
                            _c("span", { attrs: { "aria-hidden": "true" } }, [
                              _vm._v("×")
                            ])
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
              }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search"
              }
            ],
            ref: "search",
            staticClass: "form-control",
            style: { width: _vm.isValueEmpty ? "100%" : "auto" },
            attrs: {
              type: "search",
              autocomplete: "false",
              disabled: _vm.disabled,
              placeholder:
                _vm.addAndClear && !_vm.dropdownOpen
                  ? _vm.dropdownPlaceholder
                  : _vm.searchPlaceholder,
              tabindex: _vm.tabindex,
              readonly: !_vm.searchable,
              id: _vm.inputId,
              "aria-label": "Search for option"
            },
            domProps: { value: _vm.search },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape"
                  ])
                ) {
                  return null
                }
                return _vm.onEscape($event)
              },
              keydown: [
                _vm.onSearchKeydown,
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp"
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.typeAheadUp($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown"
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.typeAheadDown($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.typeAheadSelect($event)
                }
              ],
              blur: _vm.onSearchBlur,
              focus: _vm.onSearchFocus,
              click: _vm.onSearchClick,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.search = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSelectAllButton,
                expression: "showSelectAllButton"
              }
            ],
            staticClass: "select-all icon-ok",
            attrs: { disabled: _vm.disabled, title: "Select all" },
            on: { click: _vm.selectAllFilteredOptions }
          }),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showClearButton,
                expression: "showClearButton"
              }
            ],
            staticClass: "clear icon-remove",
            attrs: { disabled: _vm.disabled, title: "Clear selection" },
            on: { click: _vm.clearSelection }
          }),
          _vm._v(" "),
          !_vm.noDrop
            ? _c("i", {
                ref: "openIndicator",
                staticClass: "open-indicator",
                attrs: { role: "presentation" }
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: _vm.transition } }, [
        _vm.dropdownOpen
          ? _c(
              "ul",
              {
                ref: "dropdownMenu",
                staticClass: "dropdown-menu",
                style: { "max-height": _vm.maxHeight }
              },
              [
                _vm._l(_vm.groupedFilteredOptions, function(group) {
                  return [
                    group.optgroup
                      ? _c(
                          "li",
                          { key: group._index, staticClass: "optgroup" },
                          [
                            _c(
                              "a",
                              {
                                on: {
                                  mousedown: function($event) {
                                    $event.preventDefault()
                                    _vm.multiple
                                      ? _vm.selectMultiple(group.options)
                                      : null
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(group.optgroup) +
                                    "\n          "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(group.options, function(option) {
                      return _c(
                        "li",
                        {
                          key: option._index,
                          class: {
                            active: _vm.isOptionSelected(option),
                            highlight: option._index === _vm.typeAheadPointer
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { title: _vm.getOptionLabel(option) },
                              on: {
                                mousedown: function($event) {
                                  $event.preventDefault()
                                  return _vm.select(option)
                                }
                              }
                            },
                            [
                              _vm._t(
                                "option",
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.getOptionLabel(option)) +
                                      "\n          "
                                  )
                                ],
                                null,
                                option
                              )
                            ],
                            2
                          )
                        ]
                      )
                    })
                  ]
                }),
                _vm._v(" "),
                !_vm.groupedFilteredOptions.length
                  ? _c(
                      "li",
                      { staticClass: "no-options" },
                      [
                        _vm._t("no-options", [
                          _vm._v("Sorry, no matching options.")
                        ])
                      ],
                      2
                    )
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }