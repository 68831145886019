var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    { class: _vm.clazz },
    _vm._l(_vm.cellData, function(data, k) {
      return _c("GridCellEditable", {
        attrs: {
          rowIndex: _vm.rowIndex,
          colIndex: _vm.colIndex,
          data: data,
          absenceTypeId: data.absence_type_id,
          k: k,
          cellSaving: _vm.cellSaving
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }