<template>
    <div id="behavior-modal-app">
        <TssProgressBar
            v-if="Object.keys(saveProgress).length"
            :rows="saveProgress"
            header="Saving Progress" />
    </div>
</template>

<script>
import _ from 'tss/lodash'
import moment from 'moment-timezone'
import { mapActions, mapState, mapGetters } from 'vuex'
import BehaviorModal from './BehaviorModal.vue'
import TssProgressBar from 'shared/TssProgressBar.vue'

export default {
    props: [
        'school_id',
        'logged_in_user_id',
        'staff_member',
        'student_group_id',
        'date',
        'text',
        'settings',
        'auth',
    ],

    data() {
        return {
            modalOptions: {
                root: this.$root,
                height: '600px',
                width: '700px',
                minHeight: 600,
                minWidth: 700,
                maxHeight: 600,
                maxWidth: 700,
            },
            modalEvents: {
                'before-close': this.beforeClose,
                'closed': this.closed,
            },
        }
    },

    components: {
        BehaviorModal,
        TssProgressBar,
    },

    computed: {
        ...mapState([
            'rows',
            'saveProgress',
            'hasAnyData',
            'saving',
        ]),
    },

    methods: {
        ...mapActions([
            'setSchool',
            'setLoggedInUserId',
            'setDate',
            'setTextReplacements',
            'setAuth',
            'setSettings',
            'setStudentGroup',
            'setStaffMember',
            'loadDates',
            'loadLocations',
            'loadBehaviorTypes',
            'loadStudentGroups',
            'loadDemeritAttributeTypes',
            'loadStudents',
            'loadStaffMembers',
            'loadFavoriteAndRecentBehaviors',
            'handleRemoveRow',
            'getRowsToSave',
        ]),
        showBehaviorModal: function(params) {
            this.handleRemoveRow(0) // start with a clean state
            let row = this.rows[0]
            this.$modal.show(BehaviorModal, { params, row }, this.modalOptions, this.modalEvents)
        },
        beforeClose: function(event) {
            if (this.hasAnyData && this.rows[0].changed && !this.saving) {
                const message = "It looks like you have unsaved changes. Are you sure?"
                if (!confirm(message)) {
                    return event.stop()
                }
            }
        },
        closed: function(event) {
            // reset to a clean state so we know we can leave without a warning
            this.handleRemoveRow(0)
        },
    },

    async mounted() {
        this.setSchool(this.school_id)
        this.setLoggedInUserId(this.logged_in_user_id)
        this.setDate(this.date)
        this.setTextReplacements(this.text)
        this.setAuth(this.auth)
        this.setSettings(this.settings)

        if (this.staff_member) {
            this.setStaffMember(this.staff_member)
        }

        // load all in parallel
        this.loadBehaviorTypes();
        this.loadLocations();
        this.loadDemeritAttributeTypes();
        this.loadStudents();
        this.loadStaffMembers();
        this.loadStudentGroups();
        await this.loadDates(); // termbin start/end dates
        this.loadFavoriteAndRecentBehaviors();

        document.addEventListener('VueEvent.BehaviorModal', event => {
            this.showBehaviorModal(event.detail)
        })
    },
}
</script>

<style lang="scss">
.v--modal-overlay .v--modal-box {
    overflow: initial !important;
}
</style>