<template>
    <span class="change-history" v-html="changeHistory">
    </span>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from 'services/Api'

export default {
    data() {
        return {
            changeHistory: '<h3>Change History</h3><div>Loading...</div>',
        }
    },
    props: {
        ormClass: {
            type: String,
            default: null,
        },
        ormId: {
            type: String,
            default: null,
        },
    },
    mounted: async function() {
        const response = await Api.get(`/changelog/object/${this.ormClass}/${this.ormId}`)

        this.changeHistory = response
    },
}
</script>

<style lang="scss">
.change-history {
    h3 {
        font-size: 1.7rem;
        text-align: center;
    }

    .changelogWrapper {
        // height: 490px;
        overflow-y: scroll;
        border: none;

        .changelog {
            border: none;
            padding-left: 0;
            padding-right: 0;

            .undo {
                display: none;
            }
        }

        .changelog + .changelog {
            border-top: 1px solid $section-border;
        }
    }
}
</style>