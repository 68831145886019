var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "behavior-filter-bar gray",
      attrs: {
        "data-module": "Student Group, Staff, and Date Info",
        "data-vertical": "Behaviors"
      }
    },
    [
      _c("div", { staticClass: "row gutters" }, [
        _c(
          "div",
          { staticClass: "col span-5" },
          [
            _c("label", { staticClass: "label" }, [
              _vm._v("group \n                "),
              _c("i", {
                staticClass: "icon-question-sign",
                attrs: { title: _vm.groupsTooltipMessage, "tss-tooltip": "" }
              })
            ]),
            _vm._v(" "),
            _c("StudentGroupSelect", {
              attrs: {
                placeholder: "No Option Selected",
                initialOptions: _vm.allStudentGroups,
                value: _vm.studentGroup,
                input: _vm.setStudentGroup
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col span-6 offset-9",
            class: { invalid: !_vm.staffMember },
            attrs: {
              title: !_vm.staffMember ? _vm.staffMemberErrorMessage : ""
            }
          },
          [
            _vm.hasPermission("edit_behavior_user")
              ? [
                  _c("label", { staticClass: "label" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.getText("staff_member_title")) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("StaffMemberSelect", {
                    attrs: {
                      placeholder: "No Option Selected",
                      initialOptions: _vm.allStaffMembers,
                      value: _vm.staffMember,
                      input: _vm.setStaffMember
                    }
                  })
                ]
              : !_vm.staffMember
              ? _c("div", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.staffMemberErrorMessage) +
                      "\n            "
                  )
                ])
              : _c("div", [_vm._v("\n                 \n            ")])
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col span-4" }, [
          _c("label", { staticClass: "label" }, [_vm._v("as of")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tss-date" },
            [
              _c("TssDatepicker", {
                staticClass: "right-align",
                attrs: {
                  dayCellContent: _vm.cellContent,
                  highlighted: _vm.highlightedDates,
                  value: _vm.dateObj
                },
                on: { selected: _vm.setDate }
              }),
              _vm._v(" "),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !!_vm.dateDesc,
                    expression: "!!dateDesc"
                  }
                ],
                staticClass: "icon-calendar holiday-indicator",
                attrs: { title: _vm.dateDesc, "tss-tooltip": "" }
              })
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }