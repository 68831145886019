<template>
    <section class="behavior-filter-bar gray"
            data-module="Student Group, Staff, and Date Info"
            data-vertical="Behaviors">
        <div class="row gutters">
            <div class="col span-5">
                <label class="label">group 
                    <i class="icon-question-sign" :title="groupsTooltipMessage"
                tss-tooltip></i></label>
                <StudentGroupSelect
                    placeholder="No Option Selected"
                    :initialOptions="allStudentGroups"
                    :value="studentGroup"
                    :input="setStudentGroup"/>
            </div>
            <div class="col span-6 offset-9"
                    :class="{ invalid: !staffMember }"
                    :title="!staffMember ? staffMemberErrorMessage : ''">
                <template v-if="hasPermission('edit_behavior_user')">
                    <label class="label">
                        {{ getText('staff_member_title') }}
                    </label>
                    <StaffMemberSelect
                        placeholder="No Option Selected"
                        :initialOptions="allStaffMembers"
                        :value="staffMember"
                        :input="setStaffMember"/>
                </template>
                <div v-else-if="!staffMember">
                    {{ staffMemberErrorMessage }}
                </div>
                <div v-else>
                    &nbsp;
                </div>
            </div>
            <div class="col span-4">
                <label class="label">as of</label>
                <!-- FIXME refactor into TssDatepicker that just composes components instead of extending
                and gets the highlighted dates inside it in a static way using the service? -->
                <div class="tss-date">
                    <TssDatepicker
                        :dayCellContent="cellContent"
                        :highlighted="highlightedDates"
                        :value="dateObj"
                        @selected="setDate"
                        class="right-align">
                    </TssDatepicker>
                    <i v-show="!!dateDesc"
                        :title="dateDesc"
                        tss-tooltip
                        class="icon-calendar holiday-indicator"></i>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import TssDatepicker from 'shared/TssDatepicker.vue'
import StaffMemberSelect from 'shared/Selects/StaffMemberSelect.vue'
import StudentGroupSelect from 'shared/Selects/StudentGroupSelect.vue'

export default {
    components: {
        TssDatepicker,
        StaffMemberSelect,
        StudentGroupSelect,
    },

    computed: {
        ...mapState([
            'staffMember',
            'studentGroup',
            'date',
            'dateDesc',
            'importantDates',
            'allStudentGroups',
            'allStaffMembers',
        ]),
        ...mapGetters([
            'hasPermission',
            'getText',
        ]),
        dateObj: function() {
            return moment(this.$store.state.date).toDate()
        },
        importantDateMap: function() {
            let dates = {}
            let termbins = _.get(this.$store.state.importantDates, 'termbins')

            _.each(termbins, termbin => {
                dates[termbin.start_date] = (dates[termbin.start_date] || '')
                    + (dates[termbin.start_date] ? ', ' : '')
                    + `${termbin.short_name} Start`
                dates[termbin.end_date] = (dates[termbin.end_date] || '')
                    + (dates[termbin.end_date] ? ', ' : '')
                    + `${termbin.short_name} End`
            })

            return dates
        },
        highlightedDates: function() {
            let dates = _.chain(this.importantDateMap)
                .keys()
                .map(dateStr => moment(dateStr).toDate())
                .value()

            return {
                dates
            }
        },
        staffMemberErrorMessage: function() {
            let msg = undefined
            let staff_member = this.getText('staff_member_title')

            if (this.hasPermission('edit_behavior_user')) {
                msg = `${staff_member}: Select one value`
            } else {
                msg = `You don't have permission to record behaviors: no Linked ${staff_member}. Please contact your school's administrator to update your user account.`
            }

            return msg
        },
        groupsTooltipMessage: function(){
            let student = this.getText('student_lower')
            let students = this.getText('students_lower')
            let capitalStudents = this.getText('students_title')
            
            let msg = `Select the group you want to assign behaviors to. 
                This adds a row for each ${student} in the group and is best used if you want to log different behaviors for many kids. 
                If you'd rather add the same behavior(s) for many ${students}, choose the group from the "${capitalStudents}" dropdown below.`;
                
            return msg;
        },
    },

    methods: {
        ...mapActions([
            'setStudentGroup',
            'setDate',
            'setStaffMember',
        ]),
        cellContent: function(day) {
            let desc = _.get(this.importantDateMap, moment(day.timestamp).format('YYYY-MM-DD')) || ''
            return `<div title="${desc}">${day.date}</div>`
        },
    },
}
</script>

<style lang="scss">
.behavior-filter-bar {
    margin-bottom: $g-pad / 2 !important;
    
    .row {
        .col:last-child > * {
            margin-right: 15px; // FIXME make this a variable?
        }
    }
}
</style>