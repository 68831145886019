var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "empty-state text-center" }, [
    _c("i", { staticClass: "icon-8x text-sr-gray-07", class: _vm.icon }),
    _vm._v(" "),
    _c("h2", { staticClass: "no-margin-bottom" }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("p", { staticClass: "no-margin-top" }, [_vm._v(_vm._s(_vm.details))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }