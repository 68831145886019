<template>
    <div id="grid-controls">
        <section class="grid-controls flex">
            <div>
                <label>Date</label>
                <!-- FIXME refactor into TssDatepicker that just composes components instead of extending
                and gets the highlighted dates inside it in a static way using the service? -->
                <div class="tss-date">
                    <TssDatepicker
                        :dayCellContent="cellContent"
                        :highlighted="highlightedDates"
                        :value="dateObj"
                        :disabled="loading || refreshing"
                        @selected="reloadGrid">
                    </TssDatepicker>
                    <i v-if="dateDesc"
                        :title="dateDesc"
                        tss-tooltip
                        class="icon-calendar holiday-indicator"></i>
                </div>
            </div>
            <div class="grow flex">
                <div
                    class="btn"
                    :class="loading || refreshing ? 'disabled': ''"
                    title="Refresh"
                    @click="$emit('refresh')"
                >
                    <i
                        class="icon-refresh"
                        :class="refreshing ? 'icon-spin' : ''"
                    >
                    </i>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment-timezone';
import TssDatepicker from 'shared/TssDatepicker.vue'

export default {
    components: {
        TssDatepicker,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        refreshing: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState([
            'date',
            'dateDesc',
            'importantDates',
        ]),
        dateObj: function() {
            return moment(this.date).toDate()
        },
        importantDateMap: function() {
            let dates = {}
            let termbins = _.get(this.importantDates, 'termbins')

            _.each(termbins, termbin => {
                dates[termbin.start_date] = (dates[termbin.start_date] || '')
                    + (dates[termbin.start_date] ? ', ' : '')
                    + `${termbin.short_name} Start`
                dates[termbin.end_date] = (dates[termbin.end_date] || '')
                    + (dates[termbin.end_date] ? ', ' : '')
                    + `${termbin.short_name} End`
            })

            return dates
        },
        highlightedDates: function() {
            let dates = _.chain(this.importantDateMap)
                .keys()
                .map(dateStr => moment(dateStr).toDate())
                .value()

            return {
                dates
            }
        },
    },
    methods: {
        ...mapActions([
            'setDate',
            'loadBellSchedulePeriods',
            'loadSectionPeriods',
            'loadClassAbsenceTotals',
            'loadImportantDates',
        ]),
        async reloadGrid(dateObj) {
            let date = moment(dateObj).format('YYYY-MM-DD')

            if (date != this.date) {
                this.setDate(date)
                this.$emit('reload')
            }
        },
        cellContent: function(day) {
            let desc = _.get(this.importantDateMap, moment(day.timestamp).format('YYYY-MM-DD')) || ''
            return `<div title="${desc}">${day.date}</div>`
        },
    },
}
</script>

<style>
#grid-controls {
    section.grid-controls {
        padding: 0 0 20px 0;
    }

    .disabled {
        pointer-events: none;
    }

    .flex {
        display: flex;
        align-items: flex-end;

        > * {
            flex-grow: 0;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }

        .grow {
            flex-grow: 1;
            flex-shrink: 0;
        }
    }    
}
</style>