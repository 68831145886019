var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tbody",
    _vm._l(_vm.staffMembers, function(staffMember, i) {
      return _c(
        "tr",
        { key: "" + staffMember.staff_member_id },
        [
          _c("td", [
            _c("div", { staticClass: "flex" }, [
              _c("span", { staticClass: "overflow-ellipsis" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/staffmember/" + staffMember.staff_member_id,
                      tooltip: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(staffMember.display_name) +
                        "\n                    "
                    )
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.bellSchedulePeriods, function(bellSchedulePeriod, j) {
            return _c(
              "td",
              {
                key:
                  staffMember.staff_member_id +
                  "_" +
                  bellSchedulePeriod.bell_schedule_period_id,
                staticClass: "text-center",
                class: _vm.getClass(staffMember, bellSchedulePeriod),
                attrs: {
                  title: _vm.getTitle(staffMember, bellSchedulePeriod),
                  "tss-tooltip": "",
                  "tss-tooltip-size": "medium"
                }
              },
              [
                _vm.getUrl(staffMember, bellSchedulePeriod)
                  ? _c(
                      "a",
                      {
                        staticClass: "block",
                        attrs: {
                          href: _vm.getUrl(staffMember, bellSchedulePeriod)
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.getDescription(
                                staffMember,
                                bellSchedulePeriod
                              )
                            ) +
                            "\n            "
                        )
                      ]
                    )
                  : _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.getDescription(staffMember, bellSchedulePeriod)
                          ) +
                          "\n            "
                      )
                    ])
              ]
            )
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }