<template>
    <section class="lms-analysis-filter-bar gray"
            data-module="Page Controls"
            data-vertical="LMS Analysis">
        <div class="row gutters">
            <div class="col span-4">
                <label class="label">Timeline</label>
                <TssSelect
                    dropdown-placeholder="No Option Selected"
                    :options="allDateGroupBys"
                    :value="dateGroupBy"
                    @input="setDateGroupBy"/>
            </div>
            <div class="col span-4">
                <label class="label">Group By</label>
                <TssSelect
                    placeholder="No Option Selected"
                    :options="allLmsGroupBys"
                    :value="lmsGroupBy"
                    @input="setLmsGroupBy"/>
            </div>
            <div class="col span-4">
                <label class="label">Metric</label>
                <TssSelect
                    placeholder="No Option Selected"
                    :options="allLmsMetrics"
                    :value="lmsMetric"
                    @input="setLmsMetric"/>
            </div>
            <div v-show="lmsMetric.key == 'pct_engaged_students'"
                class="col span-4"
            >
                <label class="label">Engagement %</label>
                <input
                    type="number"
                    name="engaged_pct"
                    value="0"
                    min="0"
                    max="100"
                    step="1"
                    @change="e => setEngagedPct(e.target.value)" />
            </div>
            <div class="col text-right"
                :class="lmsMetric.key == 'pct_engaged_students' ? 'span-8' : 'span-12'"
            >
                <label class="label">&nbsp;</label>
                <button
                    type="button"
                    class="btn btn-blue"
                    @click="showFilterModal">
                    <i class="icon-filter"></i>
                    Filters
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import TssDatepicker from 'shared/TssDatepicker.vue'
import TssSelect from 'shared/TssSelect.vue'
import LmsFilters from './LmsFilters.vue'

export default {
    components: {
        TssDatepicker,
        TssSelect,
        LmsFilters,
    },
    data: () => ({
        modalOptions: {
            height: 'auto',
            adaptive: true,
            scrollable: true,
            resizable: true
        },
    }),
    computed: {
        ...mapState([
            'lmsGroupBy',
            'allLmsGroupBys',
            'lmsMetric',
            'allLmsMetrics',
            'dateGroupBy',
            'allDateGroupBys',
        ]),
    },

    methods: {
        ...mapActions([
            'setEngagedPct',
            'setLmsMetric',
            'setLmsGroupBy',
            'setDateGroupBy',
        ]),
        showFilterModal: function() {
            this.$modal.show(LmsFilters, {}, this.modalOptions)
        },
    },
}
</script>

<style lang="scss">
.lms-analysis-filter-bar {
    margin-bottom: $g-pad / 2 !important;
}
</style>