<script>
import Datepicker from 'vuejs-datepicker';
import _ from 'tss/lodash'

export default {
    extends: Datepicker,
    name: 'TssDatepicker',
    props: {
        importantDates: {
            type: Object,
            default: null
        },
        format: {
            type: String,
            default: 'MM/dd/yyyy'
        },
        placeholder: {
            type: String,
            default: 'mm/dd/yyyy'
        },
        fullMonthName: {
            type: Boolean,
            default: true
        },
        typeable: {
            type: Boolean,
            default: true
        },
        maximumView: {
            type: String,
            default: 'day'
        },
        refName: {
            type: String,
            default: 'input'
        },
        tabindex: {
            type: Number,
            default: null
        }
    },
    mounted() {
        const dateinput = this.$children.find(child => child.$options._componentTag === 'date-input')

        dateinput.$refs.input.tabIndex = this.tabindex
        dateinput.$refs.input.addEventListener('focus', e => {
            // if we got focus from clicking it'll take a second to fire
            // and to set the isOpen flag so do this in a timeout so that
            // isOpen will reflect reality: click => isOpen, tab => !isOpen
            setTimeout(() => {
                if (!this.isOpen) {
                    this.showCalendar()
                }
            }, 50)
        })
    },
}
</script>

<style lang="scss">
body.logged-in .vdp-datepicker {
    &.right-align {
        .vdp-datepicker__calendar {
            right: 0px !important;
            left: auto;
        }
    }

    .vdp-datepicker__calendar {
        padding: 10px;
        padding-right: 3px;

        header {
            margin-right: 7px;

            span.prev {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==');
            }

            span.next {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=');
            }

            .prev,
            .next {
                background: none;
                left: 0;
                top: 0;
                margin: 0;
                overflow: visible;
                color: $sr-gray-01;
                display: block;
                cursor: pointer;
                position: relative;
                outline: none;
                border: 0;
                padding: 0;
                /* hide text using text-indent trick, using width value (it's enough) */
                text-indent: -200px;
                white-space: nowrap;
                overflow: hidden;
                background-color: transparent;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 37.5% 50%; // instead of 75%/75% due to this cell being 40x40 instead of 20x30
                opacity: .5;

                &:after {
                    border: 0 !important;
                }

                &:not(.disabled):hover {
                    opacity: 1;
                    color: $sr-gray-01;
                    text-decoration: none;
                    background-color: transparent;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 37.5% 50%; // instead of 75%/75% due to this cell being 40x40 instead of 20x30

                    &:after {
                        border: 0;
                    }
                }
            }
        }
    }

    .cell {
        margin-left: -1px; /* fake out for border-collapse: collapse without tables */
        margin-top: -1px;

        &.day-header {
            font-size: inherit;

            &:nth-of-type(1),
            &:nth-of-type(7) {
                color: $sr-gray-05;
            }
        }

        &:not(.blank):not(.disabled).day,
        &:not(.blank):not(.disabled).month,
        &:not(.blank):not(.disabled).year {
            > div {
                text-align: center;
            }

            border-color: $datepicker-hover;

            &:hover {
                background-color: $datepicker-hover;
                border-color: $datepicker-hover;
            }
            
            &.highlighted {
                font-weight: bold;
                color: $sr-green;
                background-color: $white;

                &:hover {
                    background-color: $datepicker-hover;
                }
            }

            &.selected {
                background: $sr-blue;
                color: $white;
                font-weight: normal;

                &.highlighted {
                    color: $sr-green;
                }
            }
            
            &.weekend {
                color: $sr-gray-05;
            }
        }
    }

    & + .holiday-indicator {
        font-size: 1.1em !important;
        right: 18px; // to match tss-select dropdown indicator
    }
}
</style>