var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "student-class-absence-grid-app" } },
    [
      _vm.defaultAbsenceType
        ? _c("GridControls", { attrs: { hasData: _vm.hasData } })
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("SpencilLoader", { staticClass: "short" })
        : !_vm.hasData
        ? _c("EmptyState", {
            staticClass: "short",
            attrs: {
              title: "No sections found",
              details: "There are no enrollments for these dates"
            }
          })
        : _c("div", [
            _c(
              "table",
              { staticClass: "demerit_table simple full-width" },
              [_c("GridHeader"), _vm._v(" "), _c("GridBody")],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }