import { render, staticRenderFns } from "./LmsPageControls.vue?vue&type=template&id=8131a402&"
import script from "./LmsPageControls.vue?vue&type=script&lang=js&"
export * from "./LmsPageControls.vue?vue&type=script&lang=js&"
import style0 from "./LmsPageControls.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/sr/prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8131a402')) {
      api.createRecord('8131a402', component.options)
    } else {
      api.reload('8131a402', component.options)
    }
    module.hot.accept("./LmsPageControls.vue?vue&type=template&id=8131a402&", function () {
      api.rerender('8131a402', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "client/views/LmsAnalysis/LmsPageControls.vue"
export default component.exports