<template>
    <section class="video-analysis-filter-bar gray"
            data-module="Page Controls"
            data-vertical="Video Analysis">
        <div class="row gutters">
            <div class="col span-4">
                <label class="label">Group By</label>
                <TssSelect
                    placeholder="No Option Selected"
                    :options="allVideoGroupBys"
                    :value="videoGroupBy"
                    @input="setVideoGroupBy"/>
            </div>
            <div class="col span-4">
                <label class="label">Metric</label>
                <TssSelect
                    placeholder="No Option Selected"
                    :options="allVideoMetrics"
                    :value="videoMetric"
                    @input="setVideoMetric"/>
            </div>
            <div class="col span-16 text-right">
                <label class="label">&nbsp;</label>
                <button
                    type="button"
                    class="btn btn-blue"
                    @click="showFilterModal">
                    <i class="icon-filter"></i>
                    Filters
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import TssDatepicker from 'shared/TssDatepicker.vue'
import TssSelect from 'shared/TssSelect.vue'
import VideoFilters from './VideoFilters.vue'

export default {
    components: {
        TssDatepicker,
        TssSelect,
        VideoFilters,
    },
    data: () => ({
        modalOptions: {
            height: 'auto',
            adaptive: true,
            scrollable: true,
            resizable: true
        },
    }),
    computed: {
        ...mapState([
            'videoGroupBy',
            'allVideoGroupBys',
            'videoMetric',
            'allVideoMetrics',
        ]),
    },

    methods: {
        ...mapActions([
            'setVideoMetric',
            'setVideoGroupBy',
        ]),
        showFilterModal: function() {
            this.$modal.show(VideoFilters, {}, this.modalOptions)
        },
    },
}
</script>

<style lang="scss">
.video-analysis-filter-bar {
    margin-bottom: $g-pad / 2 !important;
}
</style>