<template>
    <div id="student-credentials-app">
        <SpencilLoader v-if="loading"></SpencilLoader>
        <div v-else>
            <el-collapse class="credential-sections" v-model="activeSections">
                <el-collapse-item title="Student Access" name="Student">
                    <div class="row gutters no-padding">
                        <div
                            class="btn"
                            :class="{disabled: downloadingStudent}"
                            @click.prevent="downloadStudentCredentials">
                            <i v-if="studentUserExists" class="icon-download-alt" ></i>
                            {{ downloadingStudent ? 'Downloading...' : 'Download Login Instructions' }}
                        </div>
                    </div>
                    <div class="row gutters no-padding">
                        <el-checkbox v-if="accessCode" v-model="includeGuardian">Include Parent/Guardian Login Instructions</el-checkbox>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Parent/Guardian Access" name="Guardian">
                    <div class="row gutters no-padding">
                        <div
                            class="btn"
                            :class="{disabled: generatingAccessCode}"
                            @click="showStudentAccessCodeModal">
                            {{ accessCodeModalText }}
                        </div>
                        <div
                            v-if="accessCode"
                            class="btn"
                            :class="{disabled: downloadingGuardian}"
                            @click.prevent="downloadGuardianCredentials">
                            <i class="icon-download-alt"></i>
                            {{ downloadingGuardian ? 'Downloading...' : 'Download Login Instructions' }}
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
            <section class="white">
                <el-table
                    v-if="guardians.length"
                    :data="guardians"
                    :default-sort="{prop: 'user.display_name', order: 'ascending'}"
                    style="width: 100%">
                    <el-table-column prop="user.display_name" label="Parent/Guardian" sortable>
                    </el-table-column>
                    <el-table-column prop="user.email" label="Email" sortable>
                        <template slot-scope="scope">
                            <a :href="'mailto:' + scope.row.user.email">{{ scope.row.user.email }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column prop="getGuardianStudentCreatedDate(guardian_students)" label="Paired With Student On" sortable>
                        <template slot-scope="scope">
                            {{ getGuardianStudentCreatedDate(scope.row.guardian_students) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="revoke" label="Manage Access">
                        <template slot-scope="scope">
                            <i class="icon icon-pencil" @click="showGuardianAccessModal(scope.row)"></i>
                        </template>
                    </el-table-column>
                </el-table>
            </section>
        </div>
    </div>
</template>

<script>
import _ from 'tss/lodash'
import moment from 'moment-timezone'
import { mapActions, mapState, mapGetters } from 'vuex'
import SpencilLoader from 'shared/Loaders/SpencilLoader.vue'
import StudentAccessCodeModal from './StudentAccessCode.vue'
import GuardianAccessModal from './GuardianAccess.vue'

export default {
    props: [
        'studentId' // passed to Vue instance via data attrs
    ],

    data() {
        return {
            modalOptions: {
                height: 'auto',
                adaptive: true,
                scrollable: true,
                resizable: true
            },
            activeSections: ['Student', 'Guardian'],
            includeGuardian: false,
            generatingAccessCode: false,
            downloadingStudent: false,
            downloadingGuardian: false
        }
    },

    components: {
        SpencilLoader,
        StudentAccessCodeModal,
        GuardianAccessModal,
    },

    computed: {
        ...mapState({
            loading: 'loading',
            guardians: 'guardians',
            student: state => _.get(state, 'student'),
        }),
        ...mapGetters([
            'accessCode',
            'accessCodeExpiration',
            'getSetting',
            'studentUserExists'
        ]),
        confirmMsg: function() {
            return this.studentUserExists
                ? 'Are you sure? This will reset credentials for the student. They will no longer be able to log in with their current username/password.'
                : ''
        },
        studentCredentialsText: function() {
            return this.studentUserExists
                ? ' Reset and Download Credentials'
                : ' Generate and Download Credentials'
        },
        accessCodeModalText: function() {
            return this.generatingAccessCode
                ? ' Loading Access Code...'
                : (this.accessCode
                    ? ' View Login Instructions'
                    : ' Enable Parent/Guardian Login')
        },
        studentCredentialsUrl: function() {
            return `/student/generate/credentials/${this.studentId}?overwrite-existing=1&include-student-credentials=1&include-guardian-access-codes=${this.includeGuardian ? 1 : 0}`
        },
        guardianCredentialsUrl: function() {
            return `/student/generate/credentials/${this.studentId}?overwrite-existing=0&include-student-credentials=0&include-guardian-access-codes=1`
        },
    },

    methods: {
        ...mapActions([
            'fetchStudent',
            'getStudentAccessCode',
            'getGuardiansForStudent',
            'createStudentAccessCode',
            'setLoading',
            'loadSettings',
        ]),
        downloadStudentCredentials: function () {
            let url = this.studentCredentialsUrl
            let confirmMsg = this.confirmMsg

            // if you've either confirmed you want to overwrite credentials
            // or there weren't any credentials to overwrite, go forth
            if (url && ((confirmMsg && window.confirm(confirmMsg)) || !confirmMsg)) {
                this.downloadingStudent = true

                let link = document.createElement("a");
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                
                // how to tell when a file is downloaded? this seems better than
                // giving no indication to the user at all
                setTimeout(() => {
                    this.downloadingStudent = false
                }, 3000);
            }
        },
        downloadGuardianCredentials: function () {
            let url = this.guardianCredentialsUrl
            this.downloadingGuardian = true
            window.location.href = url
            setTimeout(() => {
                this.downloadingGuardian = false
            }, 3000);
        },
        showStudentAccessCodeModal: async function () {
            // generate a new code if one doesn't exist or the current one is expired
            if (!this.accessCode || moment.tz(this.accessCodeExpiration, 'UTC').isBefore(moment.utc())) {
                this.generatingAccessCode = true
                await this.createStudentAccessCode(this.studentId)
                this.generatingAccessCode = false
            }
            this.$modal.show(StudentAccessCodeModal, {}, this.modalOptions)
        },
        showGuardianAccessModal: async function (guardian) {
            this.$modal.show(
                GuardianAccessModal,
                { guardianId: guardian.guardian_id },
                _.extend({}, this.modalOptions, { width: 800 })
            )
        },
        getGuardianStudentCreatedDate(guardianStudents) {
            const thisStudent = _.find(guardianStudents, { student_id: this.studentId })
            const fromDate = thisStudent.from_date
            const tz = this.getSetting('tz');

            return moment.tz(thisStudent.from_date, 'UTC').tz(tz).format('MMMM Do YYYY');
        },
        handleChange(val) {
      }
    },

    async mounted () {
        // don't care what order these things load, but make sure we have them before we do stuff
        await Promise.all([
            this.fetchStudent(this.studentId),
            this.getGuardiansForStudent(this.studentId),
            this.getStudentAccessCode(this.studentId),
            this.loadSettings()
        ])
        this.setLoading(false)
    }
}
</script>

<style lang="scss">
#student-credentials-app {
    .credential-sections {
        .el-collapse-item {
            .el-collapse-item__header {
                padding-left: 25px;
                color: $sr-gray-02;
                font-size: 1.7rem;
            }
            .el-collapse-item__content {
                padding-left: 25px;
            }
        }
    }
    .guardians-table {
        margin-top: 20px;
    }
    table {
        border-bottom: none;
        tr {
            border-bottom: none !important;
        }
        td {
            i.icon-pencil {
                color: $sr-gray-05;
                &:hover {
                    cursor: pointer;
                    color: $sr-gray-03;
                }
            }
            a:hover {
                color: lighten($sr-orange, 10%);
            }
        }
    }
}
</style>
