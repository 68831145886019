<template>
    <div class="student-avatar">
        <span
            class="avatar-wrapper"
            :class="{ 'has-absence-badge': absent }">
            <img class="avatar" :src="student.photo"/>
            <span v-if="absent"
                class="absence-badge-wrapper"
                :title="absenceDisplayName">
                <span class="absence-badge">
                    {{absenceCode}}
                </span>
            </span>
        </span>
        <div>
            <a :href="`/student/${student.student_id}`" tooltip tabindex="-1">
                {{student.display_name}}
            </a>
            <br/>
            <a v-if="studentHomeroom" :href="'/studentgroup/' + studentHomeroom['student_group_id']" class="student-name" tabindex="-1">
                {{studentHomeroom['group_name']}}
            </a>
            <span v-if="studentGradeLevel">
                - {{studentGradeLevel['grade_level_name']}}
            </span>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
    props: {
        student: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        absent() {
            return _.get(this.student, 'absence.absence_type.in_school', 1) == 0
        },
        absenceDisplayName() {
            return this.absent
                ? _.get(this.student, 'absence.absence_type.name', '')
                : ''
        },
        absenceCode() {
            return this.absent
                ? _.get(this.student, 'absence.absence_type.code', '')
                : ''
        },
        studentHomeroom() {
            return _.isObject(_.get(this.student, 'homeroom'))
                ? this.student.homeroom
                : null
        },
        studentGradeLevel() {
            return _.isObject(_.get(this.student, 'grade_level'))
                ? this.student.grade_level
                : null
        },
    },
}
</script>

<style lang="scss">
.student-avatar {
    a {
        @extend .semi-bold;
    }

    .avatar {
        float: left;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    > div {
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .avatar-wrapper {
        position: relative;
        float: left;
        margin-right: 5px;

        &.has-absence-badge {
            margin-bottom: 1.4em;
        }
    }

    .absence-badge-wrapper {
        @include opacity(0.9);
        position: absolute;
        width: 32px;
        padding: 2px;
        bottom: -1.3em;
        left: 2px;
        text-align: center;
        color: $white;
        background: $sr-orange;
        font-weight: bold;
    }

    .absence-badge {
        width: 32px;
        // text-overflow: clip;
        overflow: hidden;
        white-space: nowrap;
        display: block;
    }
}
</style>