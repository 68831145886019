<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
    extends: Line,
    mixins: [reactiveProp],
    props: {
        options: {
            type: Object,
            default: null,
        },
        semaphore: {
            type: Number,
            default: 0,
        },
    },
    mounted() {
        // initial render
        this.renderChart(this.chartData, this.options)
    },
    watch: {
        chartData: function() {
            // force re-render rather than simple update()
            // otherwise our semaphore update doesn't work
            this.renderChart(this.chartData, this.options)
        },
        semaphore: function() {
            // update fast / no animations
            this.$data._chart.update(0)
        },
    },
}
</script>

<style>
</style>
