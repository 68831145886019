<template>
    <div id="class-absence-modal-app">
    </div>
</template>

<script>
import _ from 'tss/lodash'
import { mapActions, mapState, mapGetters } from 'vuex'
import ClassAbsenceModal from './ClassAbsenceModal.vue'

export default {
    props: [
        'school_id',
        'student_id',
        'student_display_name',
        'student_photo_url',
        'staff_member_id',
        'excuse_class_absences',
        'date',
    ],

    data() {
        return {
            modalOptions: {
                root: this.$root,
                height: '600px',
                width: '700px',
                minHeight: 600,
                minWidth: 700,
                maxHeight: 600,
                maxWidth: 700,
            },
            modalEvents: {
                'before-close': this.beforeClose
            },
        }
    },

    components: {
        ClassAbsenceModal,
    },

    computed: {
        ...mapState([
            'changed',
            'saving',
        ]),
    },

    methods: {
        ...mapActions([
            'fetchClassAbsenceTypes',
            'setSchoolId',
            'setStaffMemberId',
            'setExcuseClassAbsences',
            'setChanged',
        ]),
        showClassAbsenceModal: function(params) {
            const props = { params }
            console.log(props)
            this.$modal.show(ClassAbsenceModal, props, this.modalOptions, this.modalEvents)
        },
        beforeClose: function(event) {
            if (this.changed && !this.saving) {
                const message = "It looks like you have unsaved changes. Are you sure?"
                if (!confirm(message)) {
                    return event.stop()
                }
            }

            this.setChanged(false)
        },
    },

    mounted: async function() {
        this.setSchoolId(this.school_id)
        this.setStaffMemberId(this.staff_member_id)
        this.setExcuseClassAbsences(this.excuse_class_absences)

        this.fetchClassAbsenceTypes()

        document.addEventListener('VueEvent.ClassAbsenceModal', event => {
            this.showClassAbsenceModal(event.detail)
        })
    },
}
</script>

<style lang="scss">
.v--modal-overlay .v--modal-box {
    overflow: initial !important;
}
</style>