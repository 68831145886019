<template>
    <div class="behavior-modal">
        <div class="left-column">
            <section class="white">
                <h3 class="no-margin text-center">
                    {{ header }}
                </h3>
                <i class="icon-remove" @click="$emit('close')"></i>
            </section>
            <section class="white middle">
                <SpencilLoader v-if="loading"/>
                <template v-else>
                    <div v-if="isEdit && row.student" class="student-name">
                        <img class="avatar" :src="row.student.photo"/>
                        <div class="student-name-display">
                            <a :href="`/student/${row.student.student_id}`" tooltip tabindex="-1">
                                {{row.student.display_name}}
                            </a>
                        </div>
                    </div>
                    <div v-else
                            class="row gutters"
                            :class="{ invalid: row.validationErrors['students'] }"
                            :title="row.validationErrors['students']">
                        <div class="col span-24">
                            <StudentAndGroupSelect
                                :tabindex="getTabIndex('students')"
                                :multiple="true"
                                :value="row.studentsAndStudentGroups"
                                :initialStudentOptions="studentOptions"
                                :initialStudentGroupOptions="allStudentGroups"
                                :input="handleStudentsAndStudentGroupsChange"
                                :addAndClear="false"
                                :placeholder="getText('students_title')"/>
                        </div>
                    </div>

                    <div class="row gutters">
                        <div class="col span-24"
                                :class="{ invalid: row.validationErrors['behavior_types'] }"
                                :title="row.validationErrors['behavior_types']">
                            <BehaviorTypeSelect
                                :tabindex="getTabIndex('behavior_types')"
                                :multiple="!isEdit"
                                :value="isEdit ? row.behaviorTypes[0] : row.behaviorTypes"
                                :initialOptions="behaviorTypeOptions"
                                :input="handleBehaviorTypesChange"/>
                        </div>
                    </div>

                    <div class="row gutters">
                        <div class="col span-24"
                                :class="{ invalid: row.validationErrors['comments'] }"
                                :title="row.validationErrors['comments']">
                            <textarea
                                :tabindex="getTabIndex('comments')"
                                class="comments"
                                :class="{ smaller: hasPermission('edit_behavior_user') }"
                                :value="row.comments"
                                placeholder="Comments"
                                @change="e => handleRowChange({ comments: e.target.value })"/>
                        </div>
                    </div>

                    <div class="row gutters">
                        <div class="col span-10"
                                :class="{ invalid: row.validationErrors['multiplier'] }"
                                :title="row.validationErrors['multiplier']">
                            <input
                                type="number"
                                :tabindex="getTabIndex('multiplier')"
                                :disabled="!showMultiplier"
                                :value="row.multiplier"
                                placeholder="Amount"
                                @change="e => handleRowChange({ multiplier: e.target.value })"/>
                        </div>

                        <div v-if="!allLocations || allLocations.length"
                                class="col span-14"
                                :class="{ invalid: row.validationErrors['location'] }"
                                :title="row.validationErrors['location']">
                            <LocationSelect
                                :tabindex="getTabIndex('location')"
                                :value="row.location"
                                :initialOptions="allLocations"
                                placeholder="Location"
                                :input="obj => handleRowChange({ location: obj })"/>
                        </div>
                    </div>

                    <div class="row gutters">
                        <div v-if="showTime"
                                class="col span-10"
                                :class="{ invalid: row.validationErrors['time'] }"
                                :title="row.validationErrors['time']">
                            <input
                                :tabindex="getTabIndex('time')"
                                type="time"
                                :value="row.time"
                                placeholder="Time"
                                @change="e => handleRowChange({ time: e.target.value })"/>
                        </div>

                        <div class="col"
                                :class="{ invalid: row.validationErrors['date'], 'span-14': showTime, 'span-24': !showTime }"
                                :title="row.validationErrors['date']">
                            <div class="tss-date">
                                <TssDatepicker
                                    :dayCellContent="cellContent"
                                    :highlighted="highlightedDates"
                                    :value="dateObj"
                                    :tabindex="getTabIndex('date')"
                                    ref="datepicker"
                                    @selected="setDate"
                                >
                                    <!-- @focus="e => this.$refs.datepicker.showCalendar()" -->
                                </TssDatepicker>
                                <i v-show="!!dateDesc"
                                    :title="dateDesc"
                                    tss-tooltip
                                    class="icon-calendar holiday-indicator"></i>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="hasPermission('edit_behavior_user')"
                        class="row gutters"
                    >
                        <div class="col span-24"
                            :class="{ invalid: !staffMember }"
                            :title="staffMember ? '' : ('Please select a ' + getText('staff_member_title'))"
                        >
                            <StaffMemberSelect
                                :tabindex="getTabIndex('staff_member')"
                                :placeholder="getText('staff_member_title')"
                                :initialOptions="allStaffMembers"
                                :value="staffMember"
                                :input="setStaffMember"/>
                        </div>
                    </div>

                    <div v-for="(attr, i) in attrsToShow" :key="i" class="row gutters">
                        <!-- if no active attrs don't show dropdown -->
                        <!-- otherwise you may get stuck with a required field -->
                        <!-- that has no options -->
                        <div v-if="attr.demerit_attribute_values.length" class="col span-24"
                                :class="{ invalid: row.validationErrors.attrs && row.validationErrors.attrs[attr.demerit_attribute_type_id] }"
                                :title="row.validationErrors.attrs ? row.validationErrors.attrs[attr.demerit_attribute_type_id] : ''">
                            <DemeritAttributeValueSelect
                                :tabindex="getTabIndex('attrs') + i"
                                :value="row.attrs[attr.demerit_attribute_type_id]"
                                :initialOptions="attr.demerit_attribute_values"
                                :multiple="attr.multiple == 1"
                                :placeholder="attr.display_name"
                                :input="dav => handleAttrChange(attr.demerit_attribute_type_id, dav)"/>
                        </div>
                    </div>
                </template>
            </section>
            <section class="bg-gray-09 footer">
                <div class="row">
                     <div v-if="!isEdit" class="col span-18 btn-max-height overflow-y-hidden">
                         <div v-if="numBehaviors === 0">&nbsp;</div>
                        <div   v-if="numBehaviors > 0" v-bind:class="(numBehaviors >= highlightThreshold) ? 'background-orange padding-xsm  btn-max-height white' : 'padding-xsm  btn-max-height'">
                            <i v-if= "numBehaviors >= highlightThreshold" class="icon-warning-sign"></i>
                            <span tss-tooltip>
                            &nbsp;{{ numBehaviors }} behaviors will be saved!
                            </span>
                        </div>
                    </div>
                    <div v-else class="col span-6">
                        <button
                            v-if="isEdit"
                            tabindex="-1"
                            type="button"
                            class="btn btn-red"
                            :class="{disabled: saving || loading || deactivating}"
                            :disabled="saving || loading || deactivating"
                            @click="handleClickDeactivate">
                            <i v-if="deactivating" class="icon-spinner icon-spin"></i>
                            <i v-else class="icon-trash"></i>
                            Deactivate
                        </button>
                        <div v-else>&nbsp;</div>
                    </div>
                    <div class="col span-6 text-right">
                        <button
                            tabindex="-1"
                            type="button"
                            class="btn btn-green"
                            :class="{disabled: saving || !hasAnyData || !canSave || loading || deactivating}"
                            :disabled="saving || !hasAnyData || !canSave || loading || deactivating"
                            :title="canSave ? 'Save' : (hasAnyData ? 'There are one or more validation errors!' : '')"
                            @click="handleClickSave">
                            <i v-if="saving" class="icon-spinner icon-spin"></i>
                            Save
                        </button>
                    </div>
                </div>
            </section>
            
        </div>

        <ChangeHistory
            v-if="isEdit"
            orm-class="Demerit"
            :orm-id="this.params.demerit_id"
            class="right-column">
        </ChangeHistory>
        <div v-else class="right-column favorites">
            <FavoriteBehaviorsList/>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { shallowDiff } from '../store/helpers'
import BehaviorTypeSelect from 'shared/Selects/BehaviorTypeSelect.vue'
import ChangeHistory from 'shared/ChangeHistory.vue'
import DemeritAttributeValueSelect from 'shared/Selects/DemeritAttributeValueSelect.vue'
import FavoriteBehaviorsList from './FavoriteBehaviorsList.vue'
import LocationSelect from 'shared/Selects/LocationSelect.vue'
import StudentAndGroupSelect from 'shared/Selects/StudentAndGroupSelect.vue'
import StaffMemberSelect from 'shared/Selects/StaffMemberSelect.vue'
import SpencilLoader from 'shared/Loaders/SpencilLoader.vue'
import TssDatepicker from 'shared/TssDatepicker.vue'
import BehaviorService from 'services/Behavior'
import Api from 'services/Api'

export default {
    components: {
        BehaviorTypeSelect,
        ChangeHistory,
        DemeritAttributeValueSelect,
        FavoriteBehaviorsList,
        LocationSelect,
        StudentAndGroupSelect,
        StaffMemberSelect,
        SpencilLoader,
        TssDatepicker,
    },

    data() {
        let tabindexes = {}
        let i = 1

        tabindexes.students = i++
        tabindexes.behavior_types = i++
        tabindexes.comments = i++
        tabindexes.multiplier = i++
        tabindexes.location = i++
        tabindexes.time = i++
        tabindexes.date = i++
        tabindexes.staff_member = i++
        tabindexes.attrs = i++

        return {
            loading: false,
            deactivating: false,
            tabindexes,
            behavior: null,
            highlightThreshold: 35, //num behaviors before highlighting
        }
    },

    props: [
        'params',
        'row',

    ],

    computed: {
        ...mapState([
            // 'rows',
            'allStudents',
            'allStudentGroups',
            'allLocations',
            'allStaffMembers',
            'staffMember',
            'favoriteBehaviors',
            'recentBehaviors',
            'date',
            'dateDesc',
            'importantDates',
            'canSave',
            'hasAnyData',
            'saving',
        ]),
        ...mapGetters([
            'hasPermission',
            'getText',
            'getSetting',
            'behaviorTypesForDisplay',
            'demeritAttrsForDisplay',
            'demeritAttrsForDemeritTypeId'
        ]),
        isEdit() {
            return !!this.params.demerit_id
        },
        header() {
            return !!this.params.demerit_id
                ? 'Edit Behavior'
                : 'Record Behavior'
        },
        hasHomeroom() {
            return 'student_group_id' in this.row.student.homeroom
        },
        hasGradeLevel() {
            return 'grade_level_name' in this.row.student.grade_level
        },
        homeroomTitle() {
            return _.filter([
                _.get(this.row, 'student.homeroom.group_name'),
                _.get(this.row, 'student.grade_level.grade_level_name'),
            ]).join(' - ')
        },
        showTime() {
            return this.getSetting('show_demerit_time') == 1
        },
        behaviorTypeOptions() {
            return this.behaviorTypesForDisplay(this.behavior)
        },
        studentOptions() {
            return this.params.class_students
                ? _.map(this.allStudents, s => {
                    const inClass = s.student_id in this.params.class_students

                    return _.extend({
                        optgroup: inClass ? 'Class' : 'All Students',
                        order_key: inClass ? 1 : 2,
                    }, s)
                })
                : this.allStudents
        },
        showMultiplier() {
            let showMult = !!_.filter(this.row.behaviorTypes, { show_multiplier: '1' }).length

            if (!showMult && this.row.multiplier != 1) {
                this.handleRowChange({ multiplier: 1 })
            }

            return showMult
        },
        /**
         * @return array [i => DemeritAttributeType]
         * active attrs plus inactive attrs on a behavior being edited
         */
        attrsToShow() {
            let demeritTypeIds = _.map(this.row.behaviorTypes, o => o.demerit_type_id)
            let attrTypes = this.demeritAttrsForDisplay(this.behavior)

            let genericAttrs = _.filter(attrTypes,
                attr => !attr.demerit_type_id // e.g. core values, etc.
            )
            let behaviorAttrs = _.map(demeritTypeIds,
                demerit_type_id => _.find(attrTypes, { demerit_type_id })
            )
            let attrs = _.filter((genericAttrs || []).concat(behaviorAttrs))
            let newRowAttrs = _.pick(this.row.attrs, _.map(attrs, 'demerit_attribute_type_id'))
            let diff = shallowDiff(newRowAttrs, this.row.attrs)

            // keep row attrs consistent with which attrs are actually visible
            if (diff.length) {
                this.handleRowChange({ 'attrs': newRowAttrs })
            }

            return attrs
        },

        dateObj: function() {
            return moment(this.$store.state.date).toDate()
        },

        importantDateMap: function() {
            let dates = {}
            let termbins = _.get(this.$store.state.importantDates, 'termbins')

            _.each(termbins, termbin => {
                dates[termbin.start_date] = (dates[termbin.start_date] || '')
                    + (dates[termbin.start_date] ? ', ' : '')
                    + `${termbin.short_name} Start`
                dates[termbin.end_date] = (dates[termbin.end_date] || '')
                    + (dates[termbin.end_date] ? ', ' : '')
                    + `${termbin.short_name} End`
            })

            return dates
        },

        highlightedDates: function() {
            let dates = _.chain(this.importantDateMap)
                .keys()
                .map(dateStr => moment(dateStr).toDate())
                .value()

            return {
                dates
            }
        },

        //returns the number of behaviors that will be created (students * behaviors) based on the current state
        numBehaviors () {
            let state = this.$store.getters.getRowsToSave;
            if(state.length > 0){
                state = state[0]
            }
            if(state.demerits == null || state.student_ids == null){
                return 0
            }
            let total = 0; 
            state['demerits'].forEach(demerit =>
                total += demerit.multiplier
            )
            total *= state['student_ids'].length
            return total;
        }
    },

    mounted: async function() {
        let student_id = this.params.student_id
        let location_id = this.params.location_id
        let changes = {}
        let attrs = []

        if (this.params.demerit_id) {
            this.loading = true
            const response = await BehaviorService.get(this.params.demerit_id)
            const behavior = _.get(response, 'results.behavior')
            this.behavior = behavior
            const demerit_type_id = behavior.demerit_type_id

            student_id = behavior.student_id
            location_id = behavior.location_id

            const attrValuesGroupedByAttrTypeId = _.chain(behavior.demerit_attrs)
                .map('demerit_attribute_value')
                .groupBy('demerit_attribute_type_id')
                .value()

            changes = {
                student: behavior.student,
                demeritId: this.params.demerit_id,
                behaviorTypes: _.filter(this.behaviorTypeOptions, { demerit_type_id }),
                attrs: attrValuesGroupedByAttrTypeId,
                multiplier: behavior.multiplier,
                comments: behavior.comments,
                time: behavior.time,
            }

            const staff_member_id = behavior.staff_member_id
            const staffMember = _.find(this.allStaffMembers, { staff_member_id })
            this.setStaffMember(staffMember)
            this.setDate(behavior.date)
            this.loading = false
        }

        if (this.params.class_students) {
            const classStudentsNotInAllStudents = _.chain(this.params.class_students)
                .map((display_name, student_id) => ({ student_id, display_name }))
                .filter(s => !_.find(this.allStudents, {student_id: s.student_id}))
                .value()

            if (classStudentsNotInAllStudents.length) {
                this.setAllStudents(this.allStudents.concat(classStudentsNotInAllStudents))
            }
        }

        const studentsAndStudentGroups = _.filter(this.allStudents, { student_id })
        const location = _.find(this.allLocations, { location_id })

        _.extend(changes, {
            studentsAndStudentGroups,
            location,
        })

        this.updateRow({
            changes,
            index: 0,
        })

        this.$nextTick(() => this.updateRow({
            changes: { changed: false },
            index: 0,
        }))
    },

    methods: {
        ...mapActions([
            'updateRow',
            'setDate',
            'setStaffMember',
            'setAllStudents',
            'handleSaveRows',
            'loadFavoriteAndRecentBehaviors',
            'updateStudentGroupStudents',
            'setSaving',
        ]),

        handleAttrChange: function(demeritAttributeTypeId, demeritAttributeValue) {
            let attrs = _.extend({}, this.row.attrs)

            attrs[demeritAttributeTypeId] = demeritAttributeValue

            this.handleRowChange({ attrs })
        },

        handleRowChange: function(changes) {
            this.updateRow({
                changes,
                index: this.row.index,
            })
        },

        handleStudentsAndStudentGroupsChange: function(studentsAndStudentGroups) {
            this.updateStudentGroupStudents({
                studentsAndStudentGroups,
                index: this.row.index
            })
        },

        handleBehaviorTypesChange: function(behaviorTypes) {
            if (!Array.isArray(behaviorTypes)) {
                behaviorTypes = behaviorTypes ? [behaviorTypes] : []
            }

            this.handleRowChange({ behaviorTypes })

            // set default values based on the selected behaviors
            if (behaviorTypes) {
                _.each(behaviorTypes, behaviorType => {
                    _.chain(behaviorType.demerit_default_attribute_values)
                        .filter(ddav => ddav.active == 1)
                        .each(ddav => {
                            let dav = ddav.demerit_attribute_value
                            this.handleAttrChange(dav.demerit_attribute_type_id, dav)
                        })
                        .value()
                })
            }
        },

        handleClickDeactivate: _.debounce(async function() {
            this.deactivating = true
            const demeritId = this.params.demerit_id
            const response = await BehaviorService.deactivate(demeritId)

            this.updateLegacyApp(response, demeritId)

            this.deactivating = false
            this.$emit('close')
        }, 500, { leading: true }),

        handleClickSave: _.debounce(async function() {
            this.setSaving(true)
            this.$emit('close')

            const bulkResponse = await this.handleSaveRows()
            const response = bulkResponse[0]
            const demeritId = response.results.behaviors[0].demerit_id

            // load the demerit back in a format that filtering.js can use
            const demeritsResponse = await BehaviorService.getDemeritData(demeritId)

            this.updateLegacyApp(demeritsResponse, demeritId)
            this.loadFavoriteAndRecentBehaviors()
            fetch("/totango/logBehaviorClassAttendancePage/Culture")
        }, 500, { leading: true }),

        updateLegacyApp: function(response, demeritId) {
            _.extend(response.results, {
                'class': 'Demerit',
                'id': demeritId,
                'behaviors': response.results.behaviors || response.results.records
            })

            window.handleInPlaceEdit(response)
        },

        getTabIndex: function(key) {
            return (this.row.index * 10000) + this.tabindexes[key]
        },

        cellContent: function(day) {
            let desc = _.get(this.importantDateMap, moment(day.timestamp).format('YYYY-MM-DD')) || ''
            return `<div title="${desc}">${day.date}</div>`
        },
    },
}
</script>

<style lang="scss">
.behavior-modal {
    position: relative;
    height: 600px;
    width: 700px;
    display: flex;

    section {
        width: 100%;
        box-sizing: border-box;
    }

    .left-column {
        width: 400px;

        display: flex;
        flex-direction: column;

        .middle,
        .loading-state {
            flex: 1;
        }
    }

    .middle {
        height: 427px;
        max-height: 427px;
        overflow: visible; // so that dropdowns show up
        padding-top: 10px;
    }

    .right-column {
        height: 600px;
        width: 300px;
        display: inline-block;
        background: #fff;
        box-sizing: border-box;
        border-left: 3px solid $section-border;
        padding: $g-pad;

        &.favorites {
            padding-left: 0;
        }

        .changelogWrapper {
            height: 490px;
        }

        .scroll-wrapper {
            height: 490px;
        }
    }

    .loading-state {
        margin-top: 50px;
        margin-bottom: 20px;
    }

    &:not(.odd) {
        background-color: $sr-gray-09;
    }

    textarea.comments {
        resize: vertical;
        max-height: 140px;
        &.smaller {
            height: 85px;
        }
    }

    .row.gutters:not(:first-child) {
        padding-top: 5px;
    }

    .icon-remove {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        color: lighten($sr-gray-02, 30%);
        font-size: 1.2em;

        &:hover {
            color: lighten($sr-gray-02, 40%);
        }
    }

    .student-name {
        max-width: 350px;
        display: flex;

        a {
            @extend .semi-bold;
        }

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        > div {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .student-name-display {
            margin-left: 5px;
            margin-top: 9px;
            margin-bottom: 13px;
        }
    }

    .col {
        .btn-green {
            margin-left: 5rem;
        }
    }
}
</style>