var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "absence-modal" },
    [
      _c("div", { staticClass: "left-column" }, [
        _c("section", { staticClass: "white" }, [
          _c("h3", { staticClass: "no-margin text-center" }, [
            _vm._v("\n                " + _vm._s(_vm.header) + "\n            ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "description" }),
          _vm._v(" "),
          _c("i", {
            staticClass: "icon-remove",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "white middle" },
          [
            _vm.loading
              ? _c("SpencilLoader")
              : [
                  _c("div", { staticClass: "row gutters" }, [
                    _c("div", { staticClass: "student-name" }, [
                      _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.studentPhotoUrl }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "student-name-display" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/student/" + _vm.studentId,
                              tooltip: ""
                            }
                          },
                          [_vm._v(_vm._s(_vm.studentDisplayName))]
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.dateDisplay) +
                            "\n                        "
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row gutters" }, [
                    _c(
                      "div",
                      { staticClass: "col span-24" },
                      [
                        _c("TssSelect", {
                          attrs: {
                            label: "display_name",
                            options: _vm.absenceTypesDisplay,
                            loading: _vm.absenceTypesLoading
                          },
                          on: { input: _vm.selectFirstIfEmpty },
                          model: {
                            value: _vm.selectedAbsenceType,
                            callback: function($$v) {
                              _vm.selectedAbsenceType = $$v
                            },
                            expression: "selectedAbsenceType"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row gutters" }, [
                    _c("div", { staticClass: "col span-24" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.comments,
                            expression: "comments"
                          }
                        ],
                        attrs: { rows: "5", placeholder: "Comments" },
                        domProps: { value: _vm.comments },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.comments = $event.target.value
                          }
                        }
                      })
                    ])
                  ])
                ]
          ],
          2
        ),
        _vm._v(" "),
        _c("section", { staticClass: "bg-gray-09 footer" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col span-6" }, [
              _vm.isEdit
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-red",
                      class: {
                        disabled: _vm.loading || _vm.saving || _vm.deactivating
                      },
                      attrs: {
                        tabindex: "-1",
                        type: "button",
                        disabled: _vm.loading || _vm.saving || _vm.deactivating
                      },
                      on: { click: _vm.handleClickDeactivate }
                    },
                    [
                      _vm.deactivating
                        ? _c("i", { staticClass: "icon-spinner icon-spin" })
                        : _c("i", { staticClass: "icon-trash" }),
                      _vm._v(
                        "\n                        Deactivate\n                    "
                      )
                    ]
                  )
                : _c("div", [_vm._v(" ")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col span-18 text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-green",
                  class: {
                    disabled: _vm.loading || _vm.saving || _vm.deactivating
                  },
                  attrs: {
                    tabindex: "-1",
                    type: "button",
                    disabled: _vm.loading || _vm.saving || _vm.deactivating
                  },
                  on: { click: _vm.handleClickSave }
                },
                [
                  _vm.saving
                    ? _c("i", { staticClass: "icon-spinner icon-spin" })
                    : _vm._e(),
                  _vm._v("\n                        Save\n                    ")
                ]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.recordId
        ? _c("ChangeHistory", {
            staticClass: "right-column",
            attrs: { "orm-class": "Absence", "orm-id": _vm.recordId }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }