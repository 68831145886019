var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTrends
    ? _c(
        "section",
        { staticClass: "lms-trends no-bottom-padding no-top-padding" },
        [
          _c("b", [_vm._v("Trends")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row gutters no-top-padding no-bottom-padding" },
            _vm._l(_vm.allTrends, function(trend, i) {
              return _c(
                "div",
                {
                  key: "trend-" + i,
                  staticClass: "col span-4 trend",
                  class: trend.val > 0 ? "up" : "down",
                  attrs: { title: _vm.tooltip(trend), "tss-tooltip": "" }
                },
                [
                  _c("div", { staticClass: "arrow" }, [
                    _c("i", {
                      class: trend.val > 0 ? "icon-arrow-up" : "icon-arrow-down"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "labels" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(trend.label))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.valueFormatter(trend.val)))
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }