<template>
    <thead>
        <tr>
            <th width="30%" @click="sortCol('staff_member.display_name')">
                {{ staffMemberTitle }}

                <div>
                    &nbsp;
                </div>
            </th>
            <th
                v-for="bellSchedulePeriod in bellSchedulePeriods"
                :key="bellSchedulePeriod.bell_schedule_period_id"
                @click="sortCol(bellSchedulePeriod)"
            >
                {{ bellSchedulePeriod.period.display_name }}
                <br/>
                {{ timeDisplay(bellSchedulePeriod) }}
            </th>
        </tr>
    </thead>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
    data() {
        return {
            sort: {},
        }
    },
    computed: {
        ...mapState([
            'bellSchedulePeriods',
        ]),
        ...mapGetters([
            'getText',
        ]),
        staffMemberTitle() {
            return this.getText('staff_member_title')
        },
    },
    methods: {
        timeDisplay(bellSchedulePeriod) {
            const startTime = moment(bellSchedulePeriod.start_time, 'hh:mm:ss').format('h:mm')
            const endTime = moment(bellSchedulePeriod.end_time, 'hh:mm:ss').format('h:mm')

            return `${startTime} - ${endTime}`
        },
        sortCol(col) {
            this.sort.dir = col == this.sort.col && this.sort.dir == 'asc'
                ? 'desc'
                : 'asc'
            this.sort.col = col

            this.$emit('sort', {
                ...this.sort
            })
        },
    },
}
</script>
<style lang="scss">
th {
    cursor: pointer;

    &:hover {
        background-color: $sr-gray-06 !important;
    }
}
</style>