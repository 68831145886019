<template>
    <div class="reset-cash-modal">
        <section class="white">
            <h3 class="no-margin text-center">
                Reset {{ getText('campus_cash_all') }} Balances
            </h3>
            <div class="row gutters">
                <div class="col span-24">
                    <p>
                        <i class="icon-warning-sign"></i>
                        This will reset {{ getText('campus_cash_all') }} balances for {{ studentsDescription }}.
                        Are you sure?
                    </p>
                </div>
            </div>
        </section>
        <section class="middle">
            <div class="row gutters">
                <div class="col span-11">
                    <TssCheckbox v-model="hasMax" label="Maximum balance to leave"/>
                </div>
                <div class="col span-11 offset-2">
                    <TssCheckbox v-model="setTo" label="Reset all balances to value"/>
                </div>
            </div>
            <div class="row gutters">
                <div class="col span-11">
                    <input :disabled="!hasMax" type="number" v-model="maxVal" class="small tiny"/>
                </div>
                <div class="col span-11 offset-2">
                    <input :disabled="!setTo" type="number" v-model="setToVal" class="small tiny"/>
                </div>
            </div>
        </section>
        <section class="bg-gray-09 footer">
            <div class="row">
                <div class="col span-6">
                    <button
                        type="button"
                        class="btn btn-grey close"
                        @click="$emit('close')">
                        Cancel
                    </button>
                </div>
                <div class="col span-18 text-right">
                    <button
                        type="button"
                        class="btn btn-red"
                        :class="{disabled: resetting}"
                        :disabled="resetting"
                        @click="handleResetCash">
                        <i v-if="resetting" class="icon-spinner icon-spin"></i>
                        Reset {{ getText('campus_cash_all') }} Balances!
                    </button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import TssCheckbox from 'shared/TssCheckbox.vue'
import BehaviorService from 'services/Behavior'

export default {
    components: {
        TssCheckbox,
    },

    data() {
        return {
            hasMax: true,
            maxVal: 0,
            setTo: false,
            setToVal: 0,
            resetting: false,
        }
    },

    computed: {
        ...mapState([
            'studentGroup',
            'date',
        ]),
        ...mapGetters([
            'getText',
        ]),

        studentsDescription() {
            return this.studentGroup && this.studentGroup.display_name
                ? this.studentGroup.display_name
                : ('all ' + this.getText('students_lower'))
        },
    },

    methods: {
        handleResetCash: function() {
            let params = {
                date: this.date,
            }

            if (this.studentGroup) {
                params['student_group_id'] = this.studentGroup.student_group_id
            }

            if (this.hasMax) {
                params['has_max'] = 1
                params['val'] = this.maxVal
            } else if (this.setTo) {
                params['set_to'] = 1
                params['val'] = this.setToVal
            }

            this.resetting = true
            BehaviorService.resetCashBalances(params)
                .then(x => {
                    this.$emit('close')
                    this.resetting = false

                    x.success
                        ? Growl.success({ message: x.info[0] })
                        : Growl.error({ message: x.errors[0] })
                })
        },
    },
    watch: {
        hasMax: function(newVal) {
            if (newVal && this.setTo) {
                this.setTo = false
            }
        },
        setTo: function(newVal) {
            if (newVal && this.hasMax) {
                this.hasMax = false
            }
        },
    }
}
</script>

<style lang="scss">
.reset-cash-modal {
    section.middle {
        padding-top: 0px !important;
        padding-bottom: 50px !important;
    }

    p {
        i {
            color: $sr-yellow !important;
        }
    }
}
</style>