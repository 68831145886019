var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "school-class-absence-grid-app" } },
    [
      _c("GridControls", {
        attrs: { loading: _vm.loading, refreshing: _vm.refreshing },
        on: { reload: _vm.loadGrid, refresh: _vm.loadTotals }
      }),
      _vm._v(" "),
      _vm.loading
        ? _c("SpencilLoader", { staticClass: "short" })
        : !_vm.hasData
        ? _c("EmptyState", {
            staticClass: "short",
            attrs: {
              title: "No bell schedule found",
              details: "There is no bell schedule set for this day"
            }
          })
        : _c(
            "div",
            [
              _c(
                "table",
                {
                  staticClass: "demerit_table simple full-width sticky-headers"
                },
                [
                  _c("GridHeader", {
                    on: {
                      sort: function($event) {
                        _vm.sort = $event
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("GridBody", { attrs: { sort: _vm.sort } })
                ],
                1
              ),
              _vm._v(" "),
              !_vm.sectionPeriods.length
                ? _c("EmptyState", {
                    staticClass: "short",
                    attrs: {
                      title: "No sections found",
                      details: "There are no sections scheduled on this day"
                    }
                  })
                : _vm._e()
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }