<template>
    <div class="video-analysis-filter-modal"
        data-module="Video Filters"
        data-vertical="LMS Analysis"
    >
        <section class="white">
            <h3 class="no-margin">
                <i class="icon-filter"></i> Filters
            </h3>
        </section>
        <section class="middle">
            <div class="row gutters">
                <div class="col span-12">
                    <label class="label">From</label>
                    <div class="tss-date">
                        <TssDatepicker
                            :dayCellContent="cellContent"
                            :highlighted="highlightedDates"
                            :value="minDateObj"
                            @selected="setMinDate"
                            class="right-align">
                        </TssDatepicker>
                        <i v-show="!!minDateDesc"
                            :title="minDateDesc"
                            tss-tooltip
                            class="icon-calendar holiday-indicator"></i>
                    </div>
                </div>
                <div class="col span-12">
                    <label class="label">To</label>
                    <div class="tss-date">
                        <TssDatepicker
                            :dayCellContent="cellContent"
                            :highlighted="highlightedDates"
                            :value="maxDateObj"
                            @selected="setMaxDate"
                            class="right-align">
                        </TssDatepicker>
                        <i v-show="!!maxDateDesc"
                            :title="maxDateDesc"
                            tss-tooltip
                            class="icon-calendar holiday-indicator"></i>
                    </div>
                </div>
            </div>
            <div class="row gutters">
                <div class="col span-24">
                    <label class="label">School</label>
                    <TssSelect
                        dropdown-placeholder="No Option Selected"
                        :options="allSchools"
                        :value="schools"
                        :multiple="true"
                        @input="setSchools"/>
                </div>
            </div>
            <div class="row gutters">
                <div class="col span-24">
                    <label class="label">Host</label>
                    <TssSelect
                        dropdown-placeholder="No Option Selected"
                        :options="allVideoHosts"
                        :value="videoHosts"
                        :multiple="true"
                        @input="setVideoHosts"/>
                </div>
            </div>
            <div class="row gutters">
                <div class="col span-24">
                    <label class="label">Meeting</label>
                    <TssSelect
                        dropdown-placeholder="No Option Selected"
                        :options="allVideoMeetings"
                        :value="videoMeetings"
                        :multiple="true"
                        :loading="loadingAllVideoMeetings"
                        @input="setVideoMeetings"/>
                </div>
            </div>
        </section>
        <section class="bg-gray-09 footer">
            <div class="row">
                <div class="col span-24 text-right">
                    <button
                        type="button"
                        class="btn btn-green"
                        @click="$emit('close')">
                        Done
                    </button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import TssDatepicker from 'shared/TssDatepicker.vue'
import TssSelect from 'shared/TssSelect.vue'

export default {
    components: {
        TssDatepicker,
        TssSelect,
    },
    computed: {
        ...mapState([
            'minDate',
            'minDateDesc',
            'maxDate',
            'maxDateDesc',
            'importantDates',
            'videoMeetings',
            'allVideoMeetings',
            'loadingAllVideoMeetings',
            'schools',
            'allSchools',
            'videoHosts',
            'allVideoHosts',
        ]),
        ...mapGetters([
        ]),
        minDateObj: function() {
            return moment(this.$store.state.minDate).toDate()
        },
        maxDateObj: function() {
            return moment(this.$store.state.maxDate).toDate()
        },
        importantDateMap: function() {
            let dates = {}
            let termbins = _.get(this.$store.state.importantDates, 'termbins')

            _.each(termbins, termbin => {
                dates[termbin.start_date] = (dates[termbin.start_date] || '')
                    + (dates[termbin.start_date] ? ', ' : '')
                    + `${termbin.short_name} Start`
                dates[termbin.end_date] = (dates[termbin.end_date] || '')
                    + (dates[termbin.end_date] ? ', ' : '')
                    + `${termbin.short_name} End`
            })

            return dates
        },
        highlightedDates: function() {
            let dates = _.chain(this.importantDateMap)
                .keys()
                .map(dateStr => moment(dateStr).toDate())
                .value()

            return {
                dates
            }
        },
    },

    methods: {
        ...mapActions([
            'setMinDate',
            'setMaxDate',
            'setEngagedPct',
            'setVideoMeetings',
            'setSchools',
            'setVideoHosts',
        ]),
        cellContent: function(day) {
            let desc = _.get(this.importantDateMap, moment(day.timestamp).format('YYYY-MM-DD')) || ''
            return `<div title="${desc}">${day.date}</div>`
        },
    },
}
</script>
<style lang="scss">
.video-analysis-filter-modal {
    section.middle {
        padding-top: 0px !important;
        padding-bottom: 50px !important;
        min-height: 400px;
    }
}
</style>