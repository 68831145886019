var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "student-avatar" }, [
    _c(
      "span",
      {
        staticClass: "avatar-wrapper",
        class: { "has-absence-badge": _vm.absent }
      },
      [
        _c("img", { staticClass: "avatar", attrs: { src: _vm.student.photo } }),
        _vm._v(" "),
        _vm.absent
          ? _c(
              "span",
              {
                staticClass: "absence-badge-wrapper",
                attrs: { title: _vm.absenceDisplayName }
              },
              [
                _c("span", { staticClass: "absence-badge" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.absenceCode) +
                      "\n            "
                  )
                ])
              ]
            )
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c("div", [
      _c(
        "a",
        {
          attrs: {
            href: "/student/" + _vm.student.student_id,
            tooltip: "",
            tabindex: "-1"
          }
        },
        [
          _vm._v(
            "\n            " + _vm._s(_vm.student.display_name) + "\n        "
          )
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.studentHomeroom
        ? _c(
            "a",
            {
              staticClass: "student-name",
              attrs: {
                href:
                  "/studentgroup/" + _vm.studentHomeroom["student_group_id"],
                tabindex: "-1"
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.studentHomeroom["group_name"]) +
                  "\n        "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.studentGradeLevel
        ? _c("span", [
            _vm._v(
              "\n            - " +
                _vm._s(_vm.studentGradeLevel["grade_level_name"]) +
                "\n        "
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }