<template>
    <div class="editable-cell">
        <div v-if="data.url">
            <div v-if="cellSaving"
                class="padme">
                <span class="overflow-ellipsis">
                    {{data.absence_type_code}}
                </span>
                <i title="saving..." class="icon-spinner icon-spin" tss-tooltip />
            </div>
            <div v-else-if="editing && !saving && data.can_i_edit && !data.is_holiday && !data.no_attendance"
                class="select-and-icon">
                <TssSelect
                    v-model="selected"
                    label="short_display_name"
                    :flippable="false"
                    :options="absenceTypesDisplay(cell)"
                    :loading="absenceTypesLoading"
                    :class="{ changed: data.changed }"
                    :filterBy="filterStartsWith"
                    @input="selectFirstIfEmpty">
                    <template slot="option" slot-scope="option">
                        <div :title="option.display_name" class="overflow-ellipsis" tss-tooltip>{{ option.code }}</div>
                    </template>
                </TssSelect>

                <i v-if="(selected && selected.absence_type_id) || data.class_absence_id"
                    :class="{'has-comments': data.comments}"
                    :title="data.comments"
                    tss-tooltip
                    class="icon-comment"
                    @click="showClassAbsenceModal" />
            </div>
            <div v-else
                class="flex padme">
                <i v-if="data.class_absence_id && (!data.can_i_edit || (data.staff_member_id == loggedInStaffMemberId && hasLockClassAttendance))"
                    :title="`Locked for editing by ${data.teacher_display_name}`"
                    tss-tooltip
                    class="gray icon-lock" />

                <a v-if="!data.is_holiday && !data.no_attendance"
                    :href="data.url"
                    :title="getTitle(data)"
                    tss-tooltip
                    class="overflow-ellipsis block">
                    {{ getDisplayName(data) }}
                </a>
                <span v-else
                    :title="getTitle(data)"
                    tss-tooltip
                    class="overflow-ellipsis"
                    :class="{ faded: editing }">
                    {{ getDisplayName(data) }}
                </span>

                <i v-if="data.comments"
                    :title="data.comments"
                    tss-tooltip
                    class="icon-comment has-comments" />
            </div>
            <div
                v-if="data.period_id"
                class="padme"
            >
                {{ timeDisplay(data) }}
            </div>
        </div>
        <div v-else
            class="padme">
            {{data.display_name}}
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import _ from 'tss/lodash'
import ClassAbsenceModal from '../ClassAbsenceModal/ClassAbsenceModal.vue'
import TssSelect from 'shared/TssSelect.vue'

export default {
    components: {
        ClassAbsenceModal,
        TssSelect,
    },

    computed: {
        ...mapState([
            'absenceTypes',
            'absenceTypesLoading',
            'bellSchedulePeriods',
            'defaultAbsenceType',
            'editing',
            'excuseClassAbsences',
            'settings',
            'loading',
            'saving',
            'tableData',
            'loggedInStaffMemberId',
            'hasLockClassAttendance',
            'studentDisplayName',
            'studentPhotoUrl',
            'studentId',
        ]),
        ...mapGetters([
            'absenceTypesDisplay',
        ]),
        cell() {
            return this.$store.state.tableData.cellData[this.rowIndex][this.colIndex][this.k]
        },
    },
    props: [
        'absenceTypeId',
        'rowIndex',
        'colIndex',
        'k',
        'data',
        'cellSaving',
    ],
    data: function() {
        return {
            selected: null,
            modalOptions: {
                root: this.$root,
                height: '600px',
                width: '700px',
                minHeight: 600,
                minWidth: 700,
                maxHeight: 600,
                maxWidth: 700,
            },
        }
    },
    mounted() {
        const absenceTypes = this.absenceTypesDisplay(this.cell)

        this.selected = _.find(absenceTypes, { absence_type_id: this.cell.absence_type_id })
    },
    methods: {
        ...mapActions([
            'setAbsenceType',
            'setComments',
        ]),
        selectFirstIfEmpty(absenceType) {
            if (!absenceType) {
                // don't allow them to select an empty option
                // so reset to the select to the default value which will re-fire
                // this event. Do it in a setTimeout to avoid a race condition.
                // NOTE: arrow function is important here to maintain 'this' reference
                // pointing to our component and not something else
                this.$nextTick(() => this.selected = this.defaultAbsenceType)
                return
            }

            this.setAbsenceType({
                rowIndex: this.rowIndex,
                colIndex: this.colIndex,
                k: this.k,
                absenceType
            })
        },
        filterStartsWith(label, search) {
            return _.startsWith((label || '').toLowerCase(), search.toLowerCase())
        },
        showClassAbsenceModal() {
            const props = {
                mode: 'commentsOnly',
                rowIndex: this.rowIndex,
                colIndex: this.colIndex,
                k: this.k,
                params: _.extend({}, this.data, {
                    absence_type_id: this.selected.absence_type_id,
                    sectionDisplayName: this.tableData.rowData[this.rowIndex].display_name,
                    student: {
                        student_id: this.studentId,
                        display_name: this.studentDisplayName,
                        photo: this.studentPhotoUrl,
                    },
                }),
            }
            this.$modal.show(ClassAbsenceModal, props, this.modalOptions)
        },
        timeDisplay(data) {
            const date = data.date
            const periodId = data.period_id
            const bellSchedulePeriod = _.get(this.bellSchedulePeriods, [date, periodId, 0])

            if (!bellSchedulePeriod) {
                return ''
            }

            const startTime = moment(bellSchedulePeriod.start_time, 'hh:mm:ss').format('h:mm')
            const endTime = moment(bellSchedulePeriod.end_time, 'hh:mm:ss').format('h:mm')

            return `${startTime} - ${endTime}`
        },
        getDisplayName(data) {
            return data.display_name == 'Present - Not Taken' && this.isInTheFuture(data)
                ? '—' // em-dash
                : data.display_name
        },
        isInTheFuture(data) {
            const date = data.date
            const periodId = data.period_id
            const today = moment().format('YYYY-MM-DD')
            const now = moment.tz(this.settings.tz).format('YYYY-MM-DD HH:mm:ss')
            const startTime = _.get(this.bellSchedulePeriods, [date, periodId, 0, 'start_time'])

            return date > today
                || (startTime && `${date} ${startTime}` > now)
        },
        getTitle(data) {
            return data.title
                ? data.title
                : this.isInTheFuture(data)
                ? `Period hasn't started yet.`
                : data.display_name == 'Present - Not Taken'
                ? `Students are considered present until marked otherwise.`
                : data.display_name
        },
    },
    watch: {
        absenceTypeId: function(newVal, oldVal) {
            // FIXME isn't there a better way to handle this with get/set functions on the prop or something? see tss-select?
            // handle fillDown action changing my absenceTypeId behind the scenes
            // this will update our select to match
            this.selected = _.find(this.$store.state.absenceTypes, { absence_type_id: newVal })
        }
    }
}
</script>

<style lang="scss">
.editable-cell {
    p.faded {
        opacity: .6;
    }

    .select-and-icon {
        position: relative;
        padding-right: 22px;

        > i {
            cursor: pointer;
            position: absolute;
            right: 2px;
            top: 14px;

            &:hover {
                color: $sr-gray-04;
            }
        }
    }

    .icon-comment {
        color: $sr-gray-06;

        &.has-comments {
            color: $sr-gray-04;
        }
    }

    a.block {
        width: 100%;
    }
}
</style>