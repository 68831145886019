var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manage-student-access-code" },
    [
      !_vm.accessCode ? _c("SpencilLoader") : _vm._e(),
      _vm._v(" "),
      _vm.accessCode
        ? _c("div", [
            _c("section", { staticClass: "white code-section" }, [
              _c("h3", { staticClass: "no-margin text-center" }, [
                _c(
                  "span",
                  {
                    staticClass: "access-code",
                    class: { "highlight-code": _vm.highlightCode }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.accessCode) +
                        "\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("i", {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.accessCode,
                      expression: "accessCode",
                      arg: "copy"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.copySuccess,
                      expression: "copySuccess",
                      arg: "success"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.copyError,
                      expression: "copyError",
                      arg: "error"
                    }
                  ],
                  staticClass: "icon-copy green",
                  attrs: { title: "copy to clipboard" }
                })
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-center" }, [
                _vm.copySucceeded == true
                  ? _c("span", { staticClass: "green" }, [
                      _vm._v("Copied to clipboard!")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.copySucceeded == false
                  ? _c("span", { staticClass: "red" }, [
                      _vm._v("Failed to copy.")
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row gutters" }, [
                _c("div", { staticClass: "col span-24" }, [
                  !_vm.newCode
                    ? _c("p", [
                        _vm._v(
                          "\n                        This code can be used to create an account with access to this student’s information in the Schoolrunner portal. It can also be used by an existing account to add this student’s info to the portal.\n                    "
                        )
                      ])
                    : _c("p", [
                        _vm._v(
                          "\n                        Access code has been reset! This new code can be used to create new guardian accounts or add this student to an existing account.\n                    "
                        )
                      ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("section", { staticClass: "bg-gray-09 footer" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col span-24 text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-grey close",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                        Done\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-red",
                      attrs: { type: "button", disabled: _vm.resetting },
                      on: { click: _vm.resetAccessCode }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.resetting ? "Resetting" : "Reset Access Code"
                          ) +
                          "\n                    "
                      )
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "white code-section no-margin" }, [
      _c("div", { staticClass: "col span-24" }, [
        _c("p", [
          _vm._v(
            "Resetting this code prevents anyone else from using the old code to gain access to this student's info in the portal. It does not revoke access from parents/guardians who signed up with the old code."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }