import { render, staticRenderFns } from "./VideoPageControls.vue?vue&type=template&id=eab04d30&"
import script from "./VideoPageControls.vue?vue&type=script&lang=js&"
export * from "./VideoPageControls.vue?vue&type=script&lang=js&"
import style0 from "./VideoPageControls.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/sr/prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('eab04d30')) {
      api.createRecord('eab04d30', component.options)
    } else {
      api.reload('eab04d30', component.options)
    }
    module.hot.accept("./VideoPageControls.vue?vue&type=template&id=eab04d30&", function () {
      api.rerender('eab04d30', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "client/views/LmsAnalysis/VideoPageControls.vue"
export default component.exports