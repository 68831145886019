<template>
    <div id="school-class-absence-grid-app">
        <GridControls
            @reload="loadGrid"
            @refresh="loadTotals"
            :loading="loading"
            :refreshing="refreshing"
        />

        <SpencilLoader
            v-if="loading"
            class="short"
        />
        <EmptyState
            v-else-if="!hasData"
            class="short"
            title="No bell schedule found"
            details="There is no bell schedule set for this day"
        />
        <div v-else>
            <table class="demerit_table simple full-width sticky-headers">
                <GridHeader @sort="sort = $event"/>
                <GridBody :sort="sort"/>
            </table>
            <EmptyState
                v-if="!sectionPeriods.length"
                class="short"
                title="No sections found"
                details="There are no sections scheduled on this day"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import GridBody from './GridBody.vue'
import GridControls from './GridControls.vue'
import GridHeader from './GridHeader.vue'
import EmptyState from 'shared/Loaders/EmptyState.vue'
import SpencilLoader from 'shared/Loaders/SpencilLoader.vue'

export default {
    components: {
        GridBody,
        GridControls,
        GridHeader,
        EmptyState,
        SpencilLoader,
    },
    props: [
        'school_id',
        'term_id',
        'asof',
        'settings',
        'text',
    ],
    data() {
        return {
            loading: false,
            refreshing: false,
            sort: {
                col: 'staff_member.display_name',
                dir: 'asc',
            },
        }
    },
    computed: {
        ...mapState([
            'date',
            'sectionPeriods',
            'bellSchedulePeriods',
        ]),
        hasData() {
            return !this.loading
                && this.bellSchedulePeriods
                && this.bellSchedulePeriods.length
        },
    },
    methods: {
        ...mapActions([
            'setSchoolId',
            'setTermId',
            'setDate',
            'setSettings',
            'setTextReplacements',
            'loadBellSchedulePeriods',
            'loadSectionPeriods',
            'loadClassAbsences',
            'loadClassAbsenceTotals',
            'loadImportantDates',
        ]),
        async loadTotals() {
            this.refreshing = true

            await this.loadClassAbsenceTotals()
            await this.loadClassAbsences()

            this.refreshing = false
        },
        async loadGrid() {
            this.loading = true

            await this.loadBellSchedulePeriods()
            await Promise.all([
                this.loadSectionPeriods(),
                this.loadImportantDates(),
            ])
            await this.loadClassAbsenceTotals()
            await this.loadClassAbsences()

            this.loading = false
        },
    },
    mounted() {
        this.setSchoolId(this.school_id)
        this.setTermId(this.term_id)
        this.setSettings(this.settings)
        this.setTextReplacements(this.text)
        this.setDate(this.asof)

        this.loadGrid()
    },
}
</script>

<style lang="scss">
#school-class-absence-grid-app {
    .flex {
        display: flex;
    }

    div.btn {
        @extend .no-transition;
    }

    table {
        table-layout: fixed;

        tr {
            // height: $btn-height + 14; // for 6px padding and 1px border
            
            td {
                .padme {
                    padding-left: 1em;
                    
                    i {
                        margin-left: 5px;
                        margin-right: 5px;
                        margin-top: 3px;
                    }
                }
            }
        }
    }
}
</style>