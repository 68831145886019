<template>
    <td :class="clazz">
        <GridCellEditable v-for="(data, k) in cellData"
            :rowIndex="rowIndex"
            :colIndex="colIndex"
            :data="data"
            :absenceTypeId="data.absence_type_id"
            :k="k"
            :cellSaving="cellSaving">
        </GridCellEditable>
    </td>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import GridCellEditable from './GridCellEditable.vue'

export default {
    components: {
        GridCellEditable,
    },
    computed: {
        ...mapState([
            'absenceTypes',
            'defaultAbsenceType',
            'saving',
            'bulkRecords',
        ]),
        cellSaving: function() {
            return this.$store.state.saving
                && _.find(this.$store.state.bulkRecords, { cellKey: this.cellData.cellKey })
        },
    },
    props: [
        'rowIndex',
        'colIndex',
        'clazz',
        'cellData',
    ],
}
</script>