<template>
    <tbody>
        <tr v-for="(row, i) in tableData.rowData">
            <td>
                <div class="flex">
                    <a :href="row.url"
                        :title="row.display_name"
                        class="overflow-ellipsis">
                        {{row.display_name}}
                    </a>
                </div>
            </td>
            <GridCell
                v-for="(col, j) in tableData.colData"
                :rowIndex="i"
                :colIndex="j"
                :class="col.clazz"
                :cellData="tableData.cellData[i][j]">
            </GridCell>
        </tr>
    </tbody>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import GridCell from './GridCell.vue'

export default {
    computed: mapState([
        'tableData',
    ]),
    components: {
        GridCell,
    }
}
</script>