var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-analysis-filter-modal",
      attrs: { "data-module": "Video Filters", "data-vertical": "LMS Analysis" }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("section", { staticClass: "middle" }, [
        _c("div", { staticClass: "row gutters" }, [
          _c("div", { staticClass: "col span-12" }, [
            _c("label", { staticClass: "label" }, [_vm._v("From")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tss-date" },
              [
                _c("TssDatepicker", {
                  staticClass: "right-align",
                  attrs: {
                    dayCellContent: _vm.cellContent,
                    highlighted: _vm.highlightedDates,
                    value: _vm.minDateObj
                  },
                  on: { selected: _vm.setMinDate }
                }),
                _vm._v(" "),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !!_vm.minDateDesc,
                      expression: "!!minDateDesc"
                    }
                  ],
                  staticClass: "icon-calendar holiday-indicator",
                  attrs: { title: _vm.minDateDesc, "tss-tooltip": "" }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col span-12" }, [
            _c("label", { staticClass: "label" }, [_vm._v("To")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tss-date" },
              [
                _c("TssDatepicker", {
                  staticClass: "right-align",
                  attrs: {
                    dayCellContent: _vm.cellContent,
                    highlighted: _vm.highlightedDates,
                    value: _vm.maxDateObj
                  },
                  on: { selected: _vm.setMaxDate }
                }),
                _vm._v(" "),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !!_vm.maxDateDesc,
                      expression: "!!maxDateDesc"
                    }
                  ],
                  staticClass: "icon-calendar holiday-indicator",
                  attrs: { title: _vm.maxDateDesc, "tss-tooltip": "" }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row gutters" }, [
          _c(
            "div",
            { staticClass: "col span-24" },
            [
              _c("label", { staticClass: "label" }, [_vm._v("School")]),
              _vm._v(" "),
              _c("TssSelect", {
                attrs: {
                  "dropdown-placeholder": "No Option Selected",
                  options: _vm.allSchools,
                  value: _vm.schools,
                  multiple: true
                },
                on: { input: _vm.setSchools }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row gutters" }, [
          _c(
            "div",
            { staticClass: "col span-24" },
            [
              _c("label", { staticClass: "label" }, [_vm._v("Host")]),
              _vm._v(" "),
              _c("TssSelect", {
                attrs: {
                  "dropdown-placeholder": "No Option Selected",
                  options: _vm.allVideoHosts,
                  value: _vm.videoHosts,
                  multiple: true
                },
                on: { input: _vm.setVideoHosts }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row gutters" }, [
          _c(
            "div",
            { staticClass: "col span-24" },
            [
              _c("label", { staticClass: "label" }, [_vm._v("Meeting")]),
              _vm._v(" "),
              _c("TssSelect", {
                attrs: {
                  "dropdown-placeholder": "No Option Selected",
                  options: _vm.allVideoMeetings,
                  value: _vm.videoMeetings,
                  multiple: true,
                  loading: _vm.loadingAllVideoMeetings
                },
                on: { input: _vm.setVideoMeetings }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "bg-gray-09 footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col span-24 text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-green",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_vm._v("\n                    Done\n                ")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "white" }, [
      _c("h3", { staticClass: "no-margin" }, [
        _c("i", { staticClass: "icon-filter" }),
        _vm._v(" Filters\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }