<template>
    <div class="empty-state text-center">
        <i class="icon-8x text-sr-gray-07" :class="icon"></i>
        <h2 class="no-margin-bottom">{{title}}</h2>
        <p class="no-margin-top">{{details}}</p>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        details: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'icon-list'
        },
    }
}

</script>