<template>
    <section id="async-task-manager" class="progress-bar">
        <header>{{ header }}</header>
        <div v-for="row in sortedRows" :key="row.id" class="async-task-row">
            <p>
                <span class="name">
                    {{ row.rowName }}
                </span>

                <span v-if="!row.count" class="initializing">
                    Initializing
                </span>
                <span v-else class="counter">
                    ({{ row.count }} of {{ row.total }})
                </span>

                <span v-if="row.count == row.total" class="done">
                    Done!
                </span>
            </p>
            <div class="task-progress-bar">
                <div class="task-progress" :style="'width: ' + ((row.count / row.total) * 100) + '%'"></div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
    props: [
        'header',
        'rows',
    ],
    computed: {
        sortedRows: function() {
            return _.sortBy(this.rows, 'id')
        },
    },
}
</script>

<style lang="scss">
#async-task-manager.progress-bar {
    display: block;
    z-index: map-get($zIndices, 'overlay-content');

    .counter {
        display: inline !important;
    }

    .done {
        display: inline;
        font-weight: bold;
    }
}
</style>