var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "video-analysis-filter-bar gray",
      attrs: {
        "data-module": "Page Controls",
        "data-vertical": "Video Analysis"
      }
    },
    [
      _c("div", { staticClass: "row gutters" }, [
        _c(
          "div",
          { staticClass: "col span-4" },
          [
            _c("label", { staticClass: "label" }, [_vm._v("Group By")]),
            _vm._v(" "),
            _c("TssSelect", {
              attrs: {
                placeholder: "No Option Selected",
                options: _vm.allVideoGroupBys,
                value: _vm.videoGroupBy
              },
              on: { input: _vm.setVideoGroupBy }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col span-4" },
          [
            _c("label", { staticClass: "label" }, [_vm._v("Metric")]),
            _vm._v(" "),
            _c("TssSelect", {
              attrs: {
                placeholder: "No Option Selected",
                options: _vm.allVideoMetrics,
                value: _vm.videoMetric
              },
              on: { input: _vm.setVideoMetric }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col span-16 text-right" }, [
          _c("label", { staticClass: "label" }, [_vm._v(" ")]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-blue",
              attrs: { type: "button" },
              on: { click: _vm.showFilterModal }
            },
            [
              _c("i", { staticClass: "icon-filter" }),
              _vm._v("\n                Filters\n            ")
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }