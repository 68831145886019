var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "grid-controls" } }, [
    _c("section", { staticClass: "grid-controls flex" }, [
      _c("div", [
        _c("label", [_vm._v("Date")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tss-date" },
          [
            _c("TssDatepicker", {
              attrs: {
                dayCellContent: _vm.cellContent,
                highlighted: _vm.highlightedDates,
                value: _vm.dateObj,
                disabled: _vm.loading || _vm.refreshing
              },
              on: { selected: _vm.reloadGrid }
            }),
            _vm._v(" "),
            _vm.dateDesc
              ? _c("i", {
                  staticClass: "icon-calendar holiday-indicator",
                  attrs: { title: _vm.dateDesc, "tss-tooltip": "" }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "grow flex" }, [
        _c(
          "div",
          {
            staticClass: "btn",
            class: _vm.loading || _vm.refreshing ? "disabled" : "",
            attrs: { title: "Refresh" },
            on: {
              click: function($event) {
                return _vm.$emit("refresh")
              }
            }
          },
          [
            _c("i", {
              staticClass: "icon-refresh",
              class: _vm.refreshing ? "icon-spin" : ""
            })
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }