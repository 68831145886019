var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reset-cash-modal" }, [
    _c("section", { staticClass: "white" }, [
      _c("h3", { staticClass: "no-margin text-center" }, [
        _vm._v(
          "\n            Reset " +
            _vm._s(_vm.getText("campus_cash_all")) +
            " Balances\n        "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row gutters" }, [
        _c("div", { staticClass: "col span-24" }, [
          _c("p", [
            _c("i", { staticClass: "icon-warning-sign" }),
            _vm._v(
              "\n                    This will reset " +
                _vm._s(_vm.getText("campus_cash_all")) +
                " balances for " +
                _vm._s(_vm.studentsDescription) +
                ".\n                    Are you sure?\n                "
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "middle" }, [
      _c("div", { staticClass: "row gutters" }, [
        _c(
          "div",
          { staticClass: "col span-11" },
          [
            _c("TssCheckbox", {
              attrs: { label: "Maximum balance to leave" },
              model: {
                value: _vm.hasMax,
                callback: function($$v) {
                  _vm.hasMax = $$v
                },
                expression: "hasMax"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col span-11 offset-2" },
          [
            _c("TssCheckbox", {
              attrs: { label: "Reset all balances to value" },
              model: {
                value: _vm.setTo,
                callback: function($$v) {
                  _vm.setTo = $$v
                },
                expression: "setTo"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row gutters" }, [
        _c("div", { staticClass: "col span-11" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.maxVal,
                expression: "maxVal"
              }
            ],
            staticClass: "small tiny",
            attrs: { disabled: !_vm.hasMax, type: "number" },
            domProps: { value: _vm.maxVal },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.maxVal = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col span-11 offset-2" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.setToVal,
                expression: "setToVal"
              }
            ],
            staticClass: "small tiny",
            attrs: { disabled: !_vm.setTo, type: "number" },
            domProps: { value: _vm.setToVal },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.setToVal = $event.target.value
              }
            }
          })
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "bg-gray-09 footer" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col span-6" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-grey close",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("\n                    Cancel\n                ")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col span-18 text-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-red",
              class: { disabled: _vm.resetting },
              attrs: { type: "button", disabled: _vm.resetting },
              on: { click: _vm.handleResetCash }
            },
            [
              _vm.resetting
                ? _c("i", { staticClass: "icon-spinner icon-spin" })
                : _vm._e(),
              _vm._v(
                "\n                    Reset " +
                  _vm._s(_vm.getText("campus_cash_all")) +
                  " Balances!\n                "
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }