<script>
import VueSelect from 'shared/MyVueSelect/MyVueSelect.vue'
// import VueSelect from 'vue-select'
import _ from 'tss/lodash'

export default {
    extends: VueSelect,
    name: 'TssSelect',
    computed: {
        filteredOptions() {
            if (!this.filterable && !this.taggable) {
                return this.mutableOptions.slice()
            }
            let options = this.mutableOptions.filter((option) => {
                if (typeof option === 'object' && option.hasOwnProperty(this.label)) {
                    // CDC: add line here to allow for overriding filter function
                    return this.filterBy(option[this.label], this.search)
                } else if (typeof option === 'object' && !option.hasOwnProperty(this.label)) {
                    return console.warn(`[vue-select warn]: Label key "option.${this.label}" does not exist in options object.\nhttp://sagalbot.github.io/vue-select/#ex-labels`)
                }

                // CDC: add line here to allow for overriding filter function
                return this.filterBy(option, this.search)
            })
            if (this.taggable && this.search.length && !this.optionExists(this.search)) {
                options.unshift(this.search)
            }
            return options
        },
        searchPlaceholder() {
            return 'Filter'
        },
        showClearButton() {
            return true
        },
    },
    methods: {
        clearSelection() {
            // first clear the selection, then blur.
            // otherwise we get into weird race conditions with clearSelection
            // being generated on click but toggleDropdown firing on mousedown
            this.mutableValue = this.multiple ? [] : null
            this.$refs.search.blur()
        },
        maybeDeleteValue() {
            // only touch the mutableValue for a multi-select
            if (!this.$refs.search.value.length && this.mutableValue && this.multiple) {
                return this.mutableValue.pop()
            }
        },
        // select(option) {
        //     // CDC: Just copied from latest to fix a bug where clicking the already selected option un-selects it
        //     // https://github.com/sagalbot/vue-select/commit/62adbb47b69fabb390ef4bfa9ee8a77bc11b6dce
        //     if (!this.isOptionSelected(option)) {
        //         if (this.taggable && !this.optionExists(option)) {
        //             option = this.createOption(option)
        //         }
        //         if (this.multiple && !this.mutableValue) {
        //             this.mutableValue = [option]
        //         } else if (this.multiple) {
        //             this.mutableValue.push(option)
        //         } else {
        //             this.mutableValue = option
        //         }
        //     }
        //     this.onAfterSelect(option)
        // },
    },
    mounted: function() {
        // Prevent bubbling of events on the search/filter input.
        // This makes it so I can select text like a normal input since we never
        // get to the preventDefault that vue-select puts on the event handler in
        // @mousedown.prevent on this.$refs.toggle.
        this.$refs.search.addEventListener('mousedown', function(e) {
            e.stopPropagation()
        })
    },
    props: {
        filterBy: {
            type: Function,
            default(label, search) {
                return (label || '').toLowerCase().indexOf(search.toLowerCase()) > -1
            }
        },
        flippable: {
            type: Boolean,
            default: true,
        },
    },

    watch: {
        open: function(newVal, oldVal) {
            this.flipped = false
            if (newVal && !oldVal && this.flippable) {
                this.$nextTick(() => {
                    if (!this.$refs.dropdownMenu) {
                        console.log('no dropdown menu?')
                        return
                    }

                    const inputElem = this.$refs.search
                    const inputBoundingRect = inputElem.getBoundingClientRect()
                    const windowHeight = window.innerHeight
                    const spaceBelow = windowHeight - inputBoundingRect.bottom
                    const spaceRequired = this.$refs.dropdownMenu.offsetHeight

                    this.flipped = spaceBelow < spaceRequired
                })
            }
        },
    },
}
</script>

<style lang="scss">
.mainBody .invalid .v-select,
#modals-container .invalid .v-select {
    div.dropdown-toggle {
        border: 2px solid $sr-red;

        input {
            border: none !important;
        }
    }
}

.mainBody .v-select,
#modals-container .v-select {
    cursor: pointer;

    @include fontSize(1.6,2.0);
    font-family: $font-stack;
    color: $sr-gray-01;
    height: $btn-height;
    transform: none !important;
    transition: none !important;
    min-width: $g-pad / 2 + 40 + 4; // padding of dropdown toggle + border

    .open-indicator {
        display: none;
    }

    div.dropdown-toggle {
        cursor: pointer;
        border: 2px solid $sr-gray-06;
        border-radius: 0;
        position: relative;
        transform: none !important;

        // from tss-select-search
        text-overflow: ellipsis;
        white-space: nowrap;
        @extend .section-input;
        transition: none !important;
        padding: 10px 40px 0 $g-pad / 2;

        &:after {
            @include fontSize($size-h2, $size-h2);
            visibility: initial;
            content: '\f107'; //icon-angle-down
            font-family: 'FontAwesome';
            position: absolute;
            right: 15px;
            top: 7px;
        }

        .selected-tag {
            cursor: pointer;
            float: none;
            height: $btn-height;
            border: none;
            border-radius: 0;
            color: inherit;
            margin: 0;
            padding: 0;
            line-height: $btn-height;
            padding: 0px 40px 0 $g-pad / 2;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    input[type=search],
    input[type=search]:focus {
        position: absolute;
        height: 0;
        padding: 0;
        padding-left: $g-pad / 2;
        transform: none !important;
        transition: none !important;
        cursor: pointer;
    }

    .fade-enter-active,
    .fade-leave-active {
        opacity: 0;
        transition: none !important;
    }

    i.clear {
        top: 52px;
        right: 10px;
        color: $sr-red;
        font-size: 16px;
        display: none;
        font-weight: normal;
        &:hover {
          text-decoration: none;
          color: lighten($sr-red, 10%);
        }
    }

    i.select-all {
        top: 52px;
        right: 30px;
        color: $sr-green;
        font-size: 16px;
        display: none;
        font-weight: normal;
        &:hover {
          text-decoration: none;
          color: lighten($sr-green, 10%);
        }
    }

    .dropdown-menu {
        border-radius: 0;
        padding: 0 !important;

        li {
            &.active {
                background: inherit !important;

                a {
                    @extend .semi-bold;
                    color: $sr-blue;
                    font-style: normal;
                }
            }
            &.highlight {
                background: inherit !important;
            }
            &.focused,
            &:hover:not(.optgroup) {
                background: $sr-gray-08 !important;
            }

            a {
                background: inherit !important;
                text-decoration: none !important;
                color: $sr-gray-03;
                @extend .overflow-ellipsis;
                @include fontSize($size-body, $size-body);
                height: $btn-height;
                line-height: $btn-height;
                padding: 0 15px;
            }
        }
    }

    &.open {
        z-index: 1;

        &.toggle-up {
            input[type=search] {
                top: 0;
            }

            i.clear,
            i.select-all {
                top: unset;
                bottom: $btn-height + 9;
            }
            
            div.dropdown-toggle {
                padding-top: $btn-height;
                top: -$btn-height;
                // border: 2px solid $sr-gray-06 !important;
                border-top: none !important;

                &:after {
                    top: unset;
                    bottom: $btn-height - 9;
                }
            }

            .selected-text {
                padding-top: 12px;
            }

            .dropdown-menu {
                border-bottom: none;
                border-top: 2px solid $sr-gray-06;
                top: unset;
                bottom: 200%;
            }

            .dropdown-placeholder {
                margin-top: 12px;
            }
        }

        input[type=search] {
            width: 100% !important;
            height: $btn-height;
            top: $btn-height;
            left: 0px;
            background: white !important;
            @include fontSize(1.6,2.0);
            font-family: $font-stack;
            color: $sr-gray-03;
            cursor: inherit;

            &:focus {
                background: $sr-gray-09 !important;

                &:hover {
                    background: white !important;
                }
            }
        }

        &.searching:after {
            content: '';
        }

        &.single {
            .selected-tag {
                opacity: 1;
            }

            &.searching {
                .selected-tag {
                    display: block;
                }
            }
        }

        div.dropdown-toggle {
            border-bottom: none;
            height: 200%;
            border: 2px solid $sr-gray-06 !important;

            &:hover {
                border: 2px solid $sr-gray-06;
                border-bottom: none;
                background-color: white !important;
            }

            &:after {
                content: '\f106'; //icon-angle-up
            }
        }

        .no-options {
            display: none;
        }

        i.clear,
        i.select-all {
            display: block;
        }

        .dropdown-menu {
            border-bottom: 2px solid $sr-gray-06;
            border-left: 2px solid $sr-gray-06;
            border-right: 2px solid $sr-gray-06;
            border-radius: 0;
            box-shadow: none !important;
            top: 200%;
            padding: 0 !important;
            transform: none !important;
            transition: none !important;
            min-width: 0;

            li {
                transition: none !important;
                &.active {
                    background: inherit !important;

                    a {
                        @extend .semi-bold;
                        color: $sr-blue;
                        font-style: normal;
                    }
                }
                &.highlight,
                &.focused,
                &:hover:not(.optgroup) {
                    background: $sr-gray-08 !important;
                }

                a {
                    background: inherit !important;
                    text-decoration: none !important;
                    color: $sr-gray-03;
                    @extend .overflow-ellipsis;
                    @include fontSize($size-body, $size-body);
                    height: $btn-height;
                    line-height: $btn-height;
                    padding: 0 $g-pad / 2;
                }
            }
        }
    }

    &.changed:not(.open) {
        .dropdown-toggle {
            border-color: $sr-orange !important;
        }

        .dropdown-menu {
            border-color: $sr-orange !important;
        }
    }

    // show the search box over the normal dropdown placeholder so we can still
    // see the cursor after making a selection
    &.add-and-clear:not(.open):not(.loading) {
        input[type=search] {
            height: $btn-height - 4;
            left: 0;
            top: 0;
            padding-top: 6px; // to line up with dropdown placeholder
        }
    }

    &.disabled .dropdown-toggle,
    &.disabled .dropdown-toggle:hover,
    &.disabled .dropdown-toggle .clear,
    &.disabled .dropdown-toggle .select-all,
    &.disabled .dropdown-toggle input,
    &.disabled .selected-tag .close,
    &.disabled .open-indicator {
        cursor: default;
        color: $sr-gray-04 !important;
        background-color: $sr-gray-08 !important;
        border-color: $sr-gray-06 !important;
    }
}

.v-select {
    // bug fix for clicking on selected-tag doesn't show the dropdown
    &.single .selected-tag {
        pointer-events: none;
    }
    // hide the weird pills
    &:not(.single) .selected-tag {
        display: none;
    }
}
/* end bug fix */
</style>
