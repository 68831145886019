var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tss-checkbox-v" }, [
    _c("span", { class: { checked: _vm.checked }, on: { click: _vm.toggle } }),
    _vm._v(" "),
    _c(
      "label",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.label,
            expression: "label"
          }
        ],
        staticClass: "tss-checkbox-label",
        on: { click: _vm.toggle }
      },
      [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }