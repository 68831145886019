var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "behaviors-app" } },
    [
      _c("PageStickyButtons"),
      _vm._v(" "),
      Object.keys(_vm.saveProgress).length
        ? _c("TssProgressBar", {
            attrs: { rows: _vm.saveProgress, header: "Saving Progress" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h1", [
        _vm._v("\n        " + _vm._s(_vm.getText("page_title")) + " "),
        _c(
          "a",
          { staticClass: "edit", attrs: { href: "/behaviors/history" } },
          [_vm._v("view full history")]
        )
      ]),
      _vm._v(" "),
      _c("PageControls"),
      _vm._v(" "),
      _c("BehaviorRowList")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }