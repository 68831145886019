<template>
    <div class="lms-chart">
        <SpencilLoader
            v-if="loading"
        />
        <template v-else-if="hasData">
            <LmsTrends
                :chart-data="chartData"
                :formatter="formatter"
                :metric="lmsMetric.label"
            />
            <section>
                <div class="row">
                    <div class="col span-24">
                        <h2 class="text-center no-margin">{{lmsMetric.label}}</h2>
                        <div class="text-center">{{subtitle}}</div>
                    </div>
                    <!-- <div class="col span-6">
                    </div> -->
                </div>
                <div class="row">
                    <div class="col span-18">
                        <BarChart
                            v-if="chartData.labels.length == 1"
                            :chart-data="chartData"
                            :options="chartOptions"
                            :styles="chartStyles"
                            :semaphore="chartSemaphore"/>
                        <LineChart
                            v-else
                            :chart-data="chartData"
                            :options="chartOptions"
                            :styles="chartStyles"
                            :semaphore="chartSemaphore"/>
                    </div>
                    <div class="col span-6 chart-legend">
                        <div class="legend-header">
                            <span class="bold">
                                Legend
                            </span>
                            <template v-if="numHidden > 0">
                                &nbsp;|&nbsp;
                                <a @click="showAll">
                                    show all
                                </a>
                            </template>
                        </div>
                        <ul>
                            <li v-for="(dataset, i) in this.chartData.datasets"
                                :key="`${lmsMetric.key}_${i}`"
                                @mouseover="() => onLegendHover(dataset, i)"
                                @mouseleave="() => onLegendLeave(dataset, i)"
                            >
                                <span :style="`background-color: ${dataset.stashedBackgroundColor}; border-color: ${dataset.stashedBorderColor}`"
                                    :class="dataset.stashedHidden ? 'dataset-hidden' : ''"
                                    class="legend-color"
                                    @click="() => onLegendClick(dataset, i)"
                                ></span>
                                <span :class="dataset.stashedHidden ? 'dataset-hidden' : ''"
                                    class="legend-label"
                                    @click="() => onLegendClick(dataset, i)"
                                >
                                    {{dataset.label}}
                                </span>
                                <a @click.stop="() => onLegendClickOnly(dataset, i)">
                                    only
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </template>
        <section v-else-if="!isEdlinkConnected"
            class="text-center"
        >
            <h2>Connect Schoolrunner to Edlink</h2>
            <p class="margin-bottom">
                To view LMS data in Schoolrunner you first need to complete the connection between Schoolrunner and Edlink!
            </p>
            <button :disabled="integrations.length"
                class="btn btn-blue"
                @click="createEdlinkAccount"
            >
                Step 1: Create Edlink Account
            </button>
            <button :disabled="!integrations.length"
                class="btn btn-orange"
                @click="attemptEdlinkSignIn"
            >
                Step 2: Log in with Edlink
            </button>
        </section>
        <div v-else-if="!isSyncFinished"
            class="loading-state"
        >
            <div class="spencil-loader-container">
                <div class="spencil-loader with-loader-message">
                    <span class="spencil-loader-message">{{syncStatus}}</span>
                </div>
            </div>
        </div>
        <EmptyState v-else title="No LMS data found" />
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import LineChart from './LineChart.vue'
import BarChart from './BarChart.vue'
import LmsTrends from './LmsTrends.vue'
import SpencilLoader from 'shared/Loaders/SpencilLoader.vue'
import EdlinkService from 'services/Edlink'
import Api from 'services/Api.js'

// color schemes like tableau/d3 without having to specify a color per dataset
// import * as foo from "chartjs-plugin-colorschemes";

Chart.defaults.global.defaultFontFamily = "Avenir"
Chart.defaults.global.datasets.bar.categoryPercentage = 0.95

export default {
    components: {
        BarChart,
        LineChart,
        SpencilLoader,
        LmsTrends,
    },
    props: {
        edlinkClientId: {
            type: String,
            default: null,
        },
        userEmail: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        loading: true,
        integrations: [],
        isEdlinkConnected: false,
        isSyncFinished: false,
        syncStatus: null,
        intervalId: null,
        rawData: null,
        chartData: null,
        chartOptions: {},
        chartSemaphore: 0,
        numHidden: 0,
    }),
    computed: {
        ...mapState([
            'minDate',
            'maxDate',
            'engagedPct',
            'lmsGroupBy',
            'lmsMetric',
            'lmsSchools',
            'lmsCourses',
            'dateGroupBy',
        ]),
        hasData() {
            return this.rawData
                && this.rawData.length
        },
        chartStyles() {
            return {
                height: '40vw',
                width: '100%',
                position: 'relative',
            }
        },
        isPctMetric() {
            return this.lmsMetric.key.startsWith('pct')
        },
        subtitle() {
            return _.isFunction(this.lmsMetric.subtitle)
                ? this.lmsMetric.subtitle(this)
                : this.lmsMetric.subtitle
        },
    },
    watch: {
        minDate: function(newVal, oldVal) {
            this.loadData()
        },
        maxDate: function(newVal, oldVal) {
            this.loadData()
        },
        lmsSchools: function(newVal, oldVal) {
            this.loadData()
        },
        lmsCourses: function(newVal, oldVal) {
            this.loadData()
        },
        dateGroupBy: function(newVal, oldVal) {
            this.loadData()
        },
        engagedPct: function(newVal, oldVal) {
            this.loadData()
        },
        lmsGroupBy: function(newVal, oldVal) {
            this.loadData()
        },
        lmsMetric: function(newVal, oldVal) {
            this.renderChart()
        },
    },
    async mounted () {
        await this.pollSyncStatus()
        this.loadData()
        if (!this.isSyncFinished) {
            this.intervalId = setInterval(this.pollSyncStatus, 30000)
        }
    },
    methods: {
        ...mapActions([
            'setAllLmsCourses',
        ]),
        loadData: async function() {
            this.loading = true

            try {
                const params = {
                    min_date: this.minDate,
                    max_date: this.maxDate,
                    group_by: `${this.dateGroupBy.key},${this.lmsGroupBy.key}`,
                    engaged_pct: this.engagedPct,
                    lms_school_names: _.map(this.lmsSchools, 'key').join(','),
                    course_names: _.map(this.lmsCourses, 'key').join(','),
                }
                const response = await Api.post('/api/v1/lms-analysis', params)

                this.rawData = _.get(response, 'results.lms_analysis') || {}
                this.renderChart()

                this.updateCourseDropdown(params) // no need to await this. render quickly.

                this.loading = false
            } catch (e) {
                console.error(e)
            }
        },
        updateCourseDropdown: async function(params) {
            const courseParams = _.extend({}, params, {
                course_names: '',
                group_by: 'course_name',
            })
            const courseResponse = await Api.post('/api/v1/lms-analysis', courseParams)
            const knownCourses = _.chain(courseResponse)
                .get('results.lms_analysis')
                .map(m => ({
                    key: m.course_name,
                    label: m.course_name,
                    optgroup: 'Course'
                }))
                .uniqBy('key')
                .value()

            this.setAllLmsCourses(knownCourses)
        },
        renderChart: function() {
            var self = this

            this.chartData = this.formatData(this.rawData)
            this.chartOptions = {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false, // now with html legend! this.chartData.datasets.length > 1,
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            maxRotation: 45,
                            minRotation: 45,
                            callback: function(value, index, values) {
                                return moment(value, 'YYYY-MM-DD').format('M/D/YY')
                            },
                        },
                    }],
                    yAxes: [{
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 1,
                            callback: function(value, index, values) {
                                return self.formatter(value)
                            },
                        },
                    }],
                },
                tooltips: {
                    callbacks: {
                        title: function(tooltipItems, data) {
                            const date = _.get(tooltipItems, '[0].xLabel')

                            return moment(date, 'YYYY-MM-DD').format('M/D/YY')
                        },
                        label: function(tooltipItem, data) {
                            const label = _.get(data, `datasets.${tooltipItem.datasetIndex}.label`)
                            const value = self.formatter(tooltipItem.yLabel)

                            return [
                                label,
                                value,
                            ].filter(x => x).join(': ')
                        },
                    },
                },
            }
        },
        formatData: function(rawData) {
            const labels = _.chain(rawData)
                .map(this.dateGroupBy.key)
                .uniq()
                .sort()
                .value()
            const lmsGroupBy = this.lmsGroupBy.key
                || (() => this.lmsMetric.label)
            const datasets = _.chain(rawData)
                .sortBy(this.dateGroupBy.key)
                .groupBy(lmsGroupBy)
                .map((arr, key) => ({
                    label: key,
                    data: _.map(labels, asof => this.getValueForMetric(_.find(arr, {[this.dateGroupBy.key]: asof}))),
                }))
                .sortBy('label')
                .sortBy(x => labels.length == 1 ? -x.data[0] : x.label)
                .value()

            this.addColors(datasets)

            return {
                labels,
                datasets,
            }
        },
        getValueForMetric: function(v) {
            if (!v) {
                return null
            }

            if (this.lmsMetric.key == 'pct_engaged_students') {
                return v.num_engaged_students / v.num_students
            } else if (this.lmsMetric.key == 'pct_avg_score') {
                return v.pct_avg_score / 100
            } else if (this.lmsMetric.key == 'num_assignments') {
                return v.num_assignments
            } else if (this.lmsMetric.key == 'num_submissions') {
                return v.num_submissions
            } else if (this.lmsMetric.key == 'num_assignments_student') {
                return v.num_assignments / v.num_distinct_students
            } else if (this.lmsMetric.key == 'num_submissions_student') {
                return v.num_submissions / v.num_distinct_students
            }

            // pct_work_completed
            return v.num_submissions / v.num_assignments
        },
        addColors: function(datasets) {
            const numDatasets = datasets.length
            // tableau.Classic20
            const defaultColors = ['#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5']
            let newColors = defaultColors.slice()

            // after 20, lighten/alpha each color a little so we don't repeat
            if (numDatasets > defaultColors.length) {
                for (let i = defaultColors.length; i < numDatasets; i++) {
                    newColors.push(Chart.helpers.color(newColors[i - defaultColors.length]).clearer(.2).rgbaString())
                }
            }

            _.each(datasets, (m, i) => {
                m.backgroundColor = numDatasets == 1
                    ? Chart.helpers.color(newColors[i]).clearer(.5).rgbaString() // for fill
                    : newColors[i]
                m.borderColor = newColors[i]
                m.pointBackgroundColor = Chart.helpers.color(newColors[i]).clearer(.5).rgbaString()
                m.pointBorderColor = newColors[i]
                m.fill = numDatasets == 1
                m.spanGaps = true
                m.cubicInterpolationMode = 'monotone'
                m.hidden = _.chain(this.chartData)
                    .get('datasets')
                    .find({label: m.label})
                    .get('hidden')
                    .value()
                m.stashedBackgroundColor = m.backgroundColor
                m.stashedBorderColor = m.borderColor
                m.stashedPointBackgroundColor = m.pointBackgroundColor
                m.stashedPointBorderColor = m.pointBorderColor
                m.stashedHidden = m.hidden
                m.justTookAction = false
            })
        },
        formatter: function(value) {
            return this.isPctMetric
                ? Math.round(100 * value) + '%'
                : Tss.Number.toCommas(value)
        },
        onLegendHover: function(dataset, datasetIndex) {
            // if we just clicked this, don't do weird stuff like re-show
            // something I just hid. wait till I mouseleave before resuming
            // normal behaviors.
            if (dataset.justTookAction) {
                return
            }

            // force show on hover
            dataset.hidden = false

            // dim all other datasets on hover
            _.each(this.chartData.datasets, (m, i) => {
                if (i != datasetIndex) {
                    let hoverColor = Chart.helpers.color(m.stashedBackgroundColor).clearer(.9).rgbaString()

                    m.backgroundColor = hoverColor
                    m.borderColor = hoverColor
                    m.pointBackgroundColor = hoverColor
                    m.pointBorderColor = hoverColor
                }
            })

            this.updateChart()
        },
        onLegendLeave: function(dataset, datasetIndex, justTookAction) {
            // show normal colors again
            _.each(this.chartData.datasets, (m, i) => {
                m.backgroundColor = m.stashedBackgroundColor
                m.borderColor = m.stashedBorderColor
                m.pointBackgroundColor = m.stashedPointBackgroundColor
                m.pointBorderColor = m.stashedPointBorderColor
                m.hidden = m.stashedHidden
                m.justTookAction = justTookAction
            })

            this.updateChart()
        },
        onLegendClick: function(dataset, datasetIndex) {
            let newHidden = !dataset.stashedHidden // toggle

            dataset.hidden = dataset.stashedHidden = newHidden

            this.onLegendLeave(dataset, datasetIndex, true)
            this.updateChart()
        },
        onLegendClickOnly: function(dataset, datasetIndex) {
            dataset.hidden = dataset.stashedHidden = false

            _.each(this.chartData.datasets, (m, i) => {
                if (i != datasetIndex) {
                    m.hidden = m.stashedHidden = true
                }
            })

            this.onLegendLeave(dataset, datasetIndex, true)
            this.updateChart()
        },
        showAll: function() {
            _.each(this.chartData.datasets, m => {
                m.hidden = m.stashedHidden = false
            })
            this.updateChart()
        },
        updateChart: function() {
            this.numHidden = _.filter(this.chartData.datasets, m => m.stashedHidden).length
            this.chartSemaphore++ // redraw
        },
        loadIntegrations: async function() {
            this.integrations = await EdlinkService.loadIntegrations(this.edlinkClientId, this.userEmail) || []
        },
        createEdlinkAccount: async function() {
            window.open('https://ed.link/integrate/156fc05d-5d86-46af-98f9-cb49f41b3ba1', '_blank')
        },
        attemptEdlinkSignIn: async function() {
            EdlinkService.init(this.edlinkClientId, _.get(this.integrations, 0))
        },
        pollSyncStatus: async function() {
            let response = null

            if (this.hasData) {
                this.isEdlinkConnected = true
                this.isSyncFinished = true
                if (this.intervalId) {
                    clearInterval(this.intervalId)
                }

                return
            }

            if (!this.isEdlinkConnected) {
                await this.loadIntegrations()
                for (let integration of this.integrations) {
                    response = await Api.get(`/api/v1/edlink/has-refresh-token/${integration.id}`)

                    this.isEdlinkConnected = _.get(response, 'results.has_refresh_token')
                    if (this.isEdlinkConnected) {
                        break
                    }
                }
            }

            if (!this.isEdlinkConnected) {
                this.isSyncFinished = false
                return
            }

            const params = {
                sync_types: 'edlink',
                limit: 1, // only the most recent
            }
            response = await Api.get('/api/v1/sync/summary', params)
            const sync = _.get(response, 'results[0]')

            if (!sync) {
                this.isSyncFinished = false
                this.syncStatus = "Starting sync..."
            } else if (sync.sync_status_id == 1) {
                this.isSyncFinished = false
                let numTableRuns = Math.max((sync.sync_table_runs || []).length, 1)
                this.syncStatus = `${numTableRuns} of 8 datasets have been loaded...`
            } else if (sync.sync_status_id == 3) {
                this.isSyncFinished = false
                this.syncStatus = `Sync encountered an error. Please check back later.`
            } else if (sync.sync_status_id == 2) {
                this.isSyncFinished = true
                this.syncStatus = `Sync complete!`

                if (this.intervalId) {
                    clearInterval(this.intervalId)
                    setTimeout(this.loadData, 2000)
                }
            }
        },
    },
}
</script>

<style lang="scss">
.lms-chart {
    .chart-legend {
        overflow: auto;
        height: 40vw;

        .legend-header {
            position: sticky;
            top: 0;
            background-color: white;
            margin-left: $g-pad;
        }

        ul {
            list-style: none;
            margin-left: 0;

            li {
                display: flex;
                margin-left: 0;

                a {
                    display: none;
                }

                &:hover {
                    cursor: pointer;

                    a {
                        display: inline-block;
                        margin-left: 5px;
                    }
                }

                .legend-label {
                    @extend .overflow-ellipsis;

                    &.dataset-hidden {
                        text-decoration: line-through;
                    }
                }

                .legend-color {
                    width: 10px;
                    height: 10px;
                    min-width: 10px;
                    display: inline-block;
                    margin-right: 5px;
                    margin-top: 4px;
                    border-width: 1px;
                    border-style: solid;

                    &.dataset-hidden {
                        background-color: white !important;
                    }
                }
            }
        }
    }
}
</style>