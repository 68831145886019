<template>
    <div id="grid-controls">
        <div v-if="error"
            class="alert alert-warning">
            <p>
                <i class="icon-info-sign"></i>
                <span>{{error}}</span>
            </p>
        </div>

        <section class="grid-controls flex">
            <div>
                <label>week of</label>
                <!-- FIXME refactor into TssDatepicker that just composes components instead of extending
                and gets the highlighted dates inside it in a static way using the service? -->
                <div class="tss-date">
                    <TssDatepicker
                        :dayCellContent="cellContent"
                        :highlighted="highlightedDates"
                        :value="dateObj"
                        :disabled="editing"
                        @selected="reloadGrid">
                    </TssDatepicker>
                    <i v-if="dateDesc"
                        :title="dateDesc"
                        tss-tooltip
                        class="icon-calendar holiday-indicator"></i>
                </div>
            </div>
            <div v-if="hasData"
                class="grow flex">
                <div v-if="!editing"
                    class="btn btn-orange"
                    title="Edit"
                    @click="setEditing(true)">
                    <i class="icon-pencil"></i>
                </div>
                <div v-else
                    class="grow flex">
                    <div class="grow">
                        <label>Bulk update attendance to</label>
                        <TssSelect
                            label="display_name"
                            v-model="selected"
                            :options="absenceTypesDisplay()"
                            :loading="absenceTypesLoading"
                            :disabled="saving"
                            @input="selectFirstIfEmpty">
                            <template slot="option" slot-scope="option">
                                <div :title="option.display_name" class="overflow-ellipsis">{{ option.display_name }}</div>
                            </template>
                        </TssSelect>
                    </div>
                    <div class="grow">
                        <input type="text"
                            placeholder="Comment"
                            @input="e => setBulkUpdateComment(e.target.value)"/>
                    </div>
                    <div class="btn-group">
                        <div title="Save"
                            class="btn btn-green"
                            :class="{ disabled: saving }"
                            @click="handleBulkUpdateClassAbsences">
                            Save
                        </div>
                        <div title="Cancel"
                            class="btn btn-gray"
                            :class="{ disabled: saving }"
                            @click="handleCancelUpdate">
                            Cancel
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import moment from 'moment-timezone';
import TssDatepicker from 'shared/TssDatepicker.vue'
import TssSelect from 'shared/TssSelect.vue'

export default {
    components: {
        TssDatepicker,
        TssSelect,
    },
    computed: {
        ...mapState([
            'absenceTypes',
            'absenceTypesLoading',
            'date',
            'dateDesc',
            'defaultAbsenceType',
            'editing',
            'error',
            'excuseClassAbsences',
            'importantDates',
            'saving',
        ]),
        ...mapGetters([
            'absenceTypesDisplay',
        ]),
        dateObj: function() {
            return moment(this.$store.state.date).toDate()
        },
        importantDateMap: function() {
            let dates = {}
            let termbins = _.get(this.$store.state.importantDates, 'termbins')

            _.each(termbins, termbin => {
                dates[termbin.start_date] = (dates[termbin.start_date] || '')
                    + (dates[termbin.start_date] ? ', ' : '')
                    + `${termbin.short_name} Start`
                dates[termbin.end_date] = (dates[termbin.end_date] || '')
                    + (dates[termbin.end_date] ? ', ' : '')
                    + `${termbin.short_name} End`
            })

            return dates
        },
        highlightedDates: function() {
            let dates = _.chain(this.importantDateMap)
                .keys()
                .map(dateStr => moment(dateStr).toDate())
                .value()

            return {
                dates
            }
        },
    },
    data: function() {
        return {
            selected: this.$store.state.defaultAbsenceType
        }
    },
    props: [
        'hasData',
    ],
    methods: {
        ...mapActions([
            'setDate',
            'setEditing',
            'setBulkUpdateAbsenceType',
            'setBulkUpdateComment',
            'fetchClassAbsences',
            'fetchBellSchedulePeriods',
            'bulkUpdateClassAbsences',
            'cancelUpdate',
        ]),
        selectFirstIfEmpty(absenceType) {
            if (!absenceType) {
                // don't allow them to select an empty option
                // so reset to the select to the default value which will re-fire
                // this event. Do it in a setTimeout to avoid a race condition.
                // NOTE: arrow function is important here to maintain 'this' reference
                // pointing to our component and not something else
                this.$nextTick(() => this.selected = this.defaultAbsenceType)
                return
            }

            this.setBulkUpdateAbsenceType(absenceType)
        },
        reloadGrid(dateObj) {
            let date = moment(dateObj).format('YYYY-MM-DD')

            if (date != this.date) {
                this.setDate(date)
                this.fetchClassAbsences()
                this.fetchBellSchedulePeriods()
            }
        },
        handleBulkUpdateClassAbsences() {
            if (!this.saving) {
                this.bulkUpdateClassAbsences()
                    .then(this.updateLegacyApp)
                fetch("totango/saveClassAttendanceStudentPage/Attendance")
            }
        },
        updateLegacyApp(responses) {
            $('#class-absences-table').trigger('filtering.go')
        },
        handleCancelUpdate() {
            if (!this.saving) {
                this.cancelUpdate()
            }
        },
        cellContent: function(day) {
            let desc = _.get(this.importantDateMap, moment(day.timestamp).format('YYYY-MM-DD')) || ''
            return `<div title="${desc}">${day.date}</div>`
        },
    },
}
</script>

<style>
#grid-controls {
    section.grid-controls {
        padding: 0 0 20px 0;
    }

    .flex {
        display: flex;
        align-items: flex-end;

        > * {
            flex-grow: 0;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }

        .grow {
            flex-grow: 1;
            flex-shrink: 0;
        }
    }    
}
</style>