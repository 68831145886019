var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "behavior-modal" },
    [
      _c("div", { staticClass: "left-column" }, [
        _c("section", { staticClass: "white" }, [
          _c("h3", { staticClass: "no-margin text-center" }, [
            _vm._v("\n                " + _vm._s(_vm.header) + "\n            ")
          ]),
          _vm._v(" "),
          _c("i", {
            staticClass: "icon-remove",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "white middle" },
          [
            _vm.loading
              ? _c("SpencilLoader")
              : [
                  _vm.isEdit && _vm.row.student
                    ? _c("div", { staticClass: "student-name" }, [
                        _c("img", {
                          staticClass: "avatar",
                          attrs: { src: _vm.row.student.photo }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "student-name-display" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/student/" + _vm.row.student.student_id,
                                tooltip: "",
                                tabindex: "-1"
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.row.student.display_name) +
                                  "\n                        "
                              )
                            ]
                          )
                        ])
                      ])
                    : _c(
                        "div",
                        {
                          staticClass: "row gutters",
                          class: {
                            invalid: _vm.row.validationErrors["students"]
                          },
                          attrs: { title: _vm.row.validationErrors["students"] }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col span-24" },
                            [
                              _c("StudentAndGroupSelect", {
                                attrs: {
                                  tabindex: _vm.getTabIndex("students"),
                                  multiple: true,
                                  value: _vm.row.studentsAndStudentGroups,
                                  initialStudentOptions: _vm.studentOptions,
                                  initialStudentGroupOptions:
                                    _vm.allStudentGroups,
                                  input:
                                    _vm.handleStudentsAndStudentGroupsChange,
                                  addAndClear: false,
                                  placeholder: _vm.getText("students_title")
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row gutters" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col span-24",
                        class: {
                          invalid: _vm.row.validationErrors["behavior_types"]
                        },
                        attrs: {
                          title: _vm.row.validationErrors["behavior_types"]
                        }
                      },
                      [
                        _c("BehaviorTypeSelect", {
                          attrs: {
                            tabindex: _vm.getTabIndex("behavior_types"),
                            multiple: !_vm.isEdit,
                            value: _vm.isEdit
                              ? _vm.row.behaviorTypes[0]
                              : _vm.row.behaviorTypes,
                            initialOptions: _vm.behaviorTypeOptions,
                            input: _vm.handleBehaviorTypesChange
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row gutters" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col span-24",
                        class: {
                          invalid: _vm.row.validationErrors["comments"]
                        },
                        attrs: { title: _vm.row.validationErrors["comments"] }
                      },
                      [
                        _c("textarea", {
                          staticClass: "comments",
                          class: {
                            smaller: _vm.hasPermission("edit_behavior_user")
                          },
                          attrs: {
                            tabindex: _vm.getTabIndex("comments"),
                            placeholder: "Comments"
                          },
                          domProps: { value: _vm.row.comments },
                          on: {
                            change: function(e) {
                              return _vm.handleRowChange({
                                comments: e.target.value
                              })
                            }
                          }
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row gutters" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col span-10",
                        class: {
                          invalid: _vm.row.validationErrors["multiplier"]
                        },
                        attrs: { title: _vm.row.validationErrors["multiplier"] }
                      },
                      [
                        _c("input", {
                          attrs: {
                            type: "number",
                            tabindex: _vm.getTabIndex("multiplier"),
                            disabled: !_vm.showMultiplier,
                            placeholder: "Amount"
                          },
                          domProps: { value: _vm.row.multiplier },
                          on: {
                            change: function(e) {
                              return _vm.handleRowChange({
                                multiplier: e.target.value
                              })
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.allLocations || _vm.allLocations.length
                      ? _c(
                          "div",
                          {
                            staticClass: "col span-14",
                            class: {
                              invalid: _vm.row.validationErrors["location"]
                            },
                            attrs: {
                              title: _vm.row.validationErrors["location"]
                            }
                          },
                          [
                            _c("LocationSelect", {
                              attrs: {
                                tabindex: _vm.getTabIndex("location"),
                                value: _vm.row.location,
                                initialOptions: _vm.allLocations,
                                placeholder: "Location",
                                input: function(obj) {
                                  return _vm.handleRowChange({ location: obj })
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row gutters" }, [
                    _vm.showTime
                      ? _c(
                          "div",
                          {
                            staticClass: "col span-10",
                            class: {
                              invalid: _vm.row.validationErrors["time"]
                            },
                            attrs: { title: _vm.row.validationErrors["time"] }
                          },
                          [
                            _c("input", {
                              attrs: {
                                tabindex: _vm.getTabIndex("time"),
                                type: "time",
                                placeholder: "Time"
                              },
                              domProps: { value: _vm.row.time },
                              on: {
                                change: function(e) {
                                  return _vm.handleRowChange({
                                    time: e.target.value
                                  })
                                }
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col",
                        class: {
                          invalid: _vm.row.validationErrors["date"],
                          "span-14": _vm.showTime,
                          "span-24": !_vm.showTime
                        },
                        attrs: { title: _vm.row.validationErrors["date"] }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tss-date" },
                          [
                            _c("TssDatepicker", {
                              ref: "datepicker",
                              attrs: {
                                dayCellContent: _vm.cellContent,
                                highlighted: _vm.highlightedDates,
                                value: _vm.dateObj,
                                tabindex: _vm.getTabIndex("date")
                              },
                              on: { selected: _vm.setDate }
                            }),
                            _vm._v(" "),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !!_vm.dateDesc,
                                  expression: "!!dateDesc"
                                }
                              ],
                              staticClass: "icon-calendar holiday-indicator",
                              attrs: { title: _vm.dateDesc, "tss-tooltip": "" }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.hasPermission("edit_behavior_user")
                    ? _c("div", { staticClass: "row gutters" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col span-24",
                            class: { invalid: !_vm.staffMember },
                            attrs: {
                              title: _vm.staffMember
                                ? ""
                                : "Please select a " +
                                  _vm.getText("staff_member_title")
                            }
                          },
                          [
                            _c("StaffMemberSelect", {
                              attrs: {
                                tabindex: _vm.getTabIndex("staff_member"),
                                placeholder: _vm.getText("staff_member_title"),
                                initialOptions: _vm.allStaffMembers,
                                value: _vm.staffMember,
                                input: _vm.setStaffMember
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.attrsToShow, function(attr, i) {
                    return _c("div", { key: i, staticClass: "row gutters" }, [
                      attr.demerit_attribute_values.length
                        ? _c(
                            "div",
                            {
                              staticClass: "col span-24",
                              class: {
                                invalid:
                                  _vm.row.validationErrors.attrs &&
                                  _vm.row.validationErrors.attrs[
                                    attr.demerit_attribute_type_id
                                  ]
                              },
                              attrs: {
                                title: _vm.row.validationErrors.attrs
                                  ? _vm.row.validationErrors.attrs[
                                      attr.demerit_attribute_type_id
                                    ]
                                  : ""
                              }
                            },
                            [
                              _c("DemeritAttributeValueSelect", {
                                attrs: {
                                  tabindex: _vm.getTabIndex("attrs") + i,
                                  value:
                                    _vm.row.attrs[
                                      attr.demerit_attribute_type_id
                                    ],
                                  initialOptions: attr.demerit_attribute_values,
                                  multiple: attr.multiple == 1,
                                  placeholder: attr.display_name,
                                  input: function(dav) {
                                    return _vm.handleAttrChange(
                                      attr.demerit_attribute_type_id,
                                      dav
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  })
                ]
          ],
          2
        ),
        _vm._v(" "),
        _c("section", { staticClass: "bg-gray-09 footer" }, [
          _c("div", { staticClass: "row" }, [
            !_vm.isEdit
              ? _c(
                  "div",
                  {
                    staticClass: "col span-18 btn-max-height overflow-y-hidden"
                  },
                  [
                    _vm.numBehaviors === 0
                      ? _c("div", [_vm._v(" ")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.numBehaviors > 0
                      ? _c(
                          "div",
                          {
                            class:
                              _vm.numBehaviors >= _vm.highlightThreshold
                                ? "background-orange padding-xsm  btn-max-height white"
                                : "padding-xsm  btn-max-height"
                          },
                          [
                            _vm.numBehaviors >= _vm.highlightThreshold
                              ? _c("i", { staticClass: "icon-warning-sign" })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { attrs: { "tss-tooltip": "" } }, [
                              _vm._v(
                                "\n                         " +
                                  _vm._s(_vm.numBehaviors) +
                                  " behaviors will be saved!\n                        "
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                )
              : _c("div", { staticClass: "col span-6" }, [
                  _vm.isEdit
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-red",
                          class: {
                            disabled:
                              _vm.saving || _vm.loading || _vm.deactivating
                          },
                          attrs: {
                            tabindex: "-1",
                            type: "button",
                            disabled:
                              _vm.saving || _vm.loading || _vm.deactivating
                          },
                          on: { click: _vm.handleClickDeactivate }
                        },
                        [
                          _vm.deactivating
                            ? _c("i", { staticClass: "icon-spinner icon-spin" })
                            : _c("i", { staticClass: "icon-trash" }),
                          _vm._v(
                            "\n                        Deactivate\n                    "
                          )
                        ]
                      )
                    : _c("div", [_vm._v(" ")])
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "col span-6 text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-green",
                  class: {
                    disabled:
                      _vm.saving ||
                      !_vm.hasAnyData ||
                      !_vm.canSave ||
                      _vm.loading ||
                      _vm.deactivating
                  },
                  attrs: {
                    tabindex: "-1",
                    type: "button",
                    disabled:
                      _vm.saving ||
                      !_vm.hasAnyData ||
                      !_vm.canSave ||
                      _vm.loading ||
                      _vm.deactivating,
                    title: _vm.canSave
                      ? "Save"
                      : _vm.hasAnyData
                      ? "There are one or more validation errors!"
                      : ""
                  },
                  on: { click: _vm.handleClickSave }
                },
                [
                  _vm.saving
                    ? _c("i", { staticClass: "icon-spinner icon-spin" })
                    : _vm._e(),
                  _vm._v("\n                        Save\n                    ")
                ]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.isEdit
        ? _c("ChangeHistory", {
            staticClass: "right-column",
            attrs: { "orm-class": "Demerit", "orm-id": this.params.demerit_id }
          })
        : _c(
            "div",
            { staticClass: "right-column favorites" },
            [_c("FavoriteBehaviorsList")],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }