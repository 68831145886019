<template>
    <section
        v-if="hasTrends"
        class="lms-trends no-bottom-padding no-top-padding"
    >
        <b>Trends</b>
        <div class="row gutters no-top-padding no-bottom-padding">
            <div v-for="(trend, i) in allTrends"
                :key="`trend-${i}`"
                class="col span-4 trend"
                :class="trend.val > 0 ? 'up' : 'down'"
                :title="tooltip(trend)"
                tss-tooltip
            >
                <div class="arrow">
                    <i :class="trend.val > 0 ? 'icon-arrow-up' : 'icon-arrow-down'"></i>
                </div>
                <div class="labels">
                    <div class="title">{{trend.label}}</div>
                    <div class="value">{{valueFormatter(trend.val)}}</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
    props: {
        chartData: {
            type: Object,
            default: () => ({})
        },
        formatter: {
            type: Function,
            default: x => x,
        },
        metric: {
            type: String,
            default: '',
        },
    },
    computed: {
        hasMultipleDatapoints() {
            return this.chartData
                && this.chartData.labels
                && this.chartData.labels.length > 1
        },
        hasTrends() {
            return this.growthTrends.length
                || this.dropoffTrends.length
        },
        latestIndex() {
            return this.chartData.labels.length - 1
        },
        latestAsof() {
            return this.chartData.labels[this.latestIndex]
        },
        latestAsofFormatted() {
            return moment(this.latestAsof, 'YYYY-MM-DD').format('M/D/YY')
        },
        compareToIndex() {
            const latestAsof = this.chartData.labels[this.latestIndex]
            const aWeekAgo = moment(latestAsof, 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD')

            let compareToIndex = 0
            for (let i = this.latestIndex - 1; i >= 0; i--) {
                let asof = this.chartData.labels[i]
                if (asof <= aWeekAgo) {
                    compareToIndex = i
                    break
                }
            }

            return compareToIndex
        },
        compareToAsof() {
            return this.chartData.labels[this.compareToIndex]
        },
        compareToAsofFormatted() {
            return moment(this.compareToAsof, 'YYYY-MM-DD').format('M/D/YY')
        },
        trends() {
            if (!this.hasMultipleDatapoints) {
                return []
            }

            return _.chain(this.chartData.datasets)
                .map(dataset => {
                    const val = dataset.data[this.latestIndex]
                    const compareToVal = dataset.data[this.compareToIndex]

                    return val === null || compareToVal === null
                        ? null
                        : {
                            label: dataset.label,
                            val: val - compareToVal,
                        }
                })
                .filter()
                .sortBy(trend => -trend.val) // - means sort descending
                .value()
        },
        growthTrends() {
            // top 3 gainers
            return _.chain(this.trends)
                .filter(trend => this.parseFloat(this.formatter(trend.val)) > 0)
                .slice(0, 3)
                .value()
        },
        dropoffTrends() {
            // top 3 losers
            return _.chain(this.trends)
                .filter(trend => this.parseFloat(this.formatter(trend.val)) < 0)
                .reverse() // reversed so the biggest loser is first
                .slice(0, 3)
                .value()
        },
        allTrends() {
            return _.concat(this.growthTrends, this.dropoffTrends)
        },
    },
    methods: {
        parseFloat: function(str) {
            if (typeof(str.replace) === 'function') {
                str = str.replace(/[$%,]/g, '')
            }

            const val = str === ''
                ? undefined
                : parseFloat(str)

            return val
        },
        valueFormatter: function(val) {
            const addSign = val > 0
                ? '+'
                : ''

            return `${addSign}${this.formatter(val)}`
        },
        tooltip: function(trend) {
            const upOrDown = trend.val > 0
                ? 'up'
                : 'down'
            const absValFormatted = this.formatter(Math.abs(trend.val))

            return `${this.metric} for ${trend.label} ${upOrDown} ${absValFormatted} between ${this.compareToAsofFormatted} and  ${this.latestAsofFormatted}`
        },
    },
}
</script>

<style lang="scss">
.lms-trends {
    .trend {
        display: flex;
        padding: 6px;
        border-radius: 8px;
        border: 1px solid #dadce0;
        white-space: nowrap;
        overflow: hidden;
        cursor: default;

        .labels {
            display: flex;
            flex-direction: column;
            @include fontSize(1.2, 1.6);
            @extend .overflow-ellipsis;
            font-weight: bold;

            > .title {
                @extend .overflow-ellipsis;
            }
        }

        .arrow {
            display: flex;
            height: 32px;
            width: 32px;
            min-height: 32px;
            min-width: 32px;
            max-height: 32px;
            max-width: 32px;
            align-items: center;
            border-radius: 8px;
            justify-content: center;
            margin-right: 8px;
            overflow: hidden;

            i {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        
        &.up {
            .arrow {
                color: $sr-green;
                background: lighten($sr-green, 40%);
            }

            .value {
                color: $sr-green;
            }
        }

        &.down {
            .arrow {
                color: $sr-red;
                background: lighten($sr-red, 40%);
            }

            .value {
                color: $sr-red;
            }
        }
    }
}
</style>