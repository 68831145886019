var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "progress-bar", attrs: { id: "async-task-manager" } },
    [
      _c("header", [_vm._v(_vm._s(_vm.header))]),
      _vm._v(" "),
      _vm._l(_vm.sortedRows, function(row) {
        return _c("div", { key: row.id, staticClass: "async-task-row" }, [
          _c("p", [
            _c("span", { staticClass: "name" }, [
              _vm._v(
                "\n                " + _vm._s(row.rowName) + "\n            "
              )
            ]),
            _vm._v(" "),
            !row.count
              ? _c("span", { staticClass: "initializing" }, [
                  _vm._v("\n                Initializing\n            ")
                ])
              : _c("span", { staticClass: "counter" }, [
                  _vm._v(
                    "\n                (" +
                      _vm._s(row.count) +
                      " of " +
                      _vm._s(row.total) +
                      ")\n            "
                  )
                ]),
            _vm._v(" "),
            row.count == row.total
              ? _c("span", { staticClass: "done" }, [
                  _vm._v("\n                Done!\n            ")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "task-progress-bar" }, [
            _c("div", {
              staticClass: "task-progress",
              style: "width: " + (row.count / row.total) * 100 + "%"
            })
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }