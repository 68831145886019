<template>
    <tbody>
        <tr
            v-for="(staffMember, i) in staffMembers"
            :key="`${staffMember.staff_member_id}`"
        >
            <td>
                <div class="flex">
                    <span class="overflow-ellipsis">
                        <a :href="`/staffmember/${staffMember.staff_member_id}`" tooltip>
                            {{ staffMember.display_name }}
                        </a>
                    </span>
                </div>
            </td>
            <td
                v-for="(bellSchedulePeriod, j) in bellSchedulePeriods"
                :key="`${staffMember.staff_member_id}_${bellSchedulePeriod.bell_schedule_period_id}`"
                :class="getClass(staffMember, bellSchedulePeriod)"
                :title="getTitle(staffMember, bellSchedulePeriod)"
                class="text-center"
                tss-tooltip
                tss-tooltip-size="medium"
            >
                <a
                    v-if="getUrl(staffMember, bellSchedulePeriod)"
                    :href="getUrl(staffMember, bellSchedulePeriod)"
                    class="block"
                >
                    {{ getDescription(staffMember, bellSchedulePeriod) }}
                </a>
                <span v-else>
                    {{ getDescription(staffMember, bellSchedulePeriod) }}
                </span>
            </td>
        </tr>
    </tbody>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { myParseFloat, getConditionalFormatting } from './store/helpers'
import EmptyState from 'shared/Loaders/EmptyState.vue'

export default {
    components: {
        EmptyState,
    },
    props: {
        sort: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...mapState([
            'date',
            'bellSchedulePeriods',
            'sectionPeriods',
            'classAbsences',
            'classAbsenceTotals',
            'settings',
            'textReplacements',
        ]),
        ...mapGetters([
            'getText',
        ]),
        staffMembers() {
            const isAsc = _.get(this.sort, 'dir') != 'desc'
            const staffMembers = _.chain(this.sectionPeriods)
                .map('staff_member')
                .keyBy('staff_member_id')
                .values()
                .sortBy(staffMember => {
                    if (this.sort && typeof(this.sort.col) == 'object') {
                        const value = this.getDescription(staffMember, this.sort.col)

                        return value == '' || value == 'n/a' || value == '—' // em-dash
                            ? (isAsc ? 1 : -1) * 1000 // always sort to bottom
                            : (value == 'Not Taken'
                            ? -1 // sort to top if asc
                            : myParseFloat(value))
                    }

                    return _.get(staffMember, 'display_name')
                })
                .value()

            return isAsc
                ? staffMembers
                : staffMembers.reverse()
        },
        staffMemberTitle() {
            return this.getText('staff_member_title')
        },
    },
    methods: {
        getSectionsThisPeriod(staffMember, bellSchedulePeriod) {
            return _.filter(
                this.sectionPeriods,
                sectionPeriod => {
                    return sectionPeriod.staff_member_id == staffMember.staff_member_id
                        && sectionPeriod.period_id == bellSchedulePeriod.period_id
                }
            )
        },
        getSectionsForAttendanceThisPeriod(sectionsThisPeriod) {
            return _.filter(
                sectionsThisPeriod,
                sectionPeriod => sectionPeriod.no_attendance == 0
            )
        },
        getClassAbsenceTotal(sectionsThisPeriod) {
            return _.chain(this.classAbsenceTotals)
                .filter(classAbsenceTotal => _.find(sectionsThisPeriod, sectionPeriod => sectionPeriod.section_period_id == classAbsenceTotal.section_period_id))
                .get(0)
                .value()
        },
        getDescription(staffMember, bellSchedulePeriod) {
            const sectionsThisPeriod = this.getSectionsThisPeriod(staffMember, bellSchedulePeriod)
            const classAbsenceTotal = this.getClassAbsenceTotal(sectionsThisPeriod)
            const sectionsForAttendanceThisPeriod = this.getSectionsForAttendanceThisPeriod(sectionsThisPeriod)
            const periodStartDateTime = `${this.date} ${bellSchedulePeriod.start_time}`
            const now = moment.tz(this.settings.tz).format('YYYY-MM-DD HH:mm:ss')

            if (classAbsenceTotal) {
                const numPresent = classAbsenceTotal.present_students
                const numEnrolled = classAbsenceTotal.enrolled_students
                const pct = 100 * numPresent / numEnrolled

                return Math.round(pct) + '%'
            }

            if (sectionsThisPeriod.length
                    && !sectionsForAttendanceThisPeriod.length) {
                return 'n/a'
            }

            if (!sectionsThisPeriod.length) {
                return ''
            }

            return periodStartDateTime > now
                ? '—' // em-dash
                : 'Not Taken'
        },
        getUrl(staffMember, bellSchedulePeriod) {
            const desc = this.getDescription(staffMember, bellSchedulePeriod)

            if (desc == 'n/a') {
                return ''
            }

            const sectionsThisPeriod = this.getSectionsThisPeriod(staffMember, bellSchedulePeriod)
            const classAbsenceTotal = this.getClassAbsenceTotal(sectionsThisPeriod)
            const sectionsForAttendanceThisPeriod = this.getSectionsForAttendanceThisPeriod(sectionsThisPeriod)
            const sectionPeriodId = classAbsenceTotal
                ? classAbsenceTotal.section_period_id
                : _.get(sectionsForAttendanceThisPeriod, '[0].section_period_id')
                    || _.get(sectionsThisPeriod, '[0].section_period_id')

            return sectionPeriodId
                ? `/absences/class?section_period_id=${sectionPeriodId}&date=${this.date}&staff_member_id=`
                : ''
        },
        getClass(staffMember, bellSchedulePeriod) {
            const val = this.getDescription(staffMember, bellSchedulePeriod)
            const rag = _.get(this.settings, ['attendance_pct'])

            return val == 'Not Taken'
                ? 'redstripe'
                : getConditionalFormatting(val, rag ? _.get(JSON.parse(rag), 'rag') : null)
        },
        getClassAbsenceSummary(classAbsenceTotal) {
            const classAbsencesThisSectionPeriod = _.get(
                this.classAbsences,
                classAbsenceTotal.section_period_id,
                []
            )
            const numPresentWithoutCode = classAbsenceTotal.enrolled_students
                - classAbsencesThisSectionPeriod.length
            const presentDesc = numPresentWithoutCode
                ? `
                    <tr>
                        <td class="label">Present: </td>
                        <td class="text-right">${numPresentWithoutCode}</td>
                    </tr>
                `
                : ''
            const absenceDesc = _.chain(classAbsencesThisSectionPeriod)
                .groupBy('absence_type_id')
                .map((absences, absenceTypeId) => `
                    <tr>
                        <td class="label">${absences[0].absence_type.name}: </td>
                        <td class="text-right">${absences.length}</td>
                    </tr>
                `)
                .join('')
                .value()

            return `
                <table>
                    ${presentDesc}
                    ${absenceDesc}
                </table>
            `
        },
        getTitle(staffMember, bellSchedulePeriod) {
            const sectionsThisPeriod = this.getSectionsThisPeriod(staffMember, bellSchedulePeriod)
            const classAbsenceTotal = this.getClassAbsenceTotal(sectionsThisPeriod)
            const sectionsForAttendanceThisPeriod = this.getSectionsForAttendanceThisPeriod(sectionsThisPeriod)

            if (classAbsenceTotal) {
                const courseName = classAbsenceTotal.section_period.section.course_definition.name
                const sectionNumber = classAbsenceTotal.section_period.section.number
                const sectionName = `${courseName} [${sectionNumber}]`
                const numPresent = classAbsenceTotal.present_students
                const numEnrolled = classAbsenceTotal.enrolled_students
                const fromDate = moment.tz(classAbsenceTotal.from_date, 'UTC').tz(this.settings.tz)
                const timeFormat = fromDate.format('YYYY-MM-DD') == this.date
                    ? '@ h:mma'
                    : 'M/D @ h:mma'
                const timeStr = fromDate.format(timeFormat)
                const takenByDesc = classAbsenceTotal.staff_member_id != staffMember.staff_member_id
                    ? `* Attendance taken for ${staffMember.last_name} by ${classAbsenceTotal.staff_member.display_name}.`
                    : ''
                const classAbsenceSummary = this.getClassAbsenceSummary(classAbsenceTotal)

                return `
                    <div class="text-left">Taken ${timeStr} for ${sectionName}.</div>
                    <br/>
                    <div class="summary">
                        ${classAbsenceSummary}
                    </div>
                    <div class="text-left">${takenByDesc}</div>
                `
            }

            // has sections but they're not for attendance (no_attendance=1)
            const noAttendance = sectionsThisPeriod.length
                && !sectionsForAttendanceThisPeriod.length
            const desc = this.getDescription(staffMember, bellSchedulePeriod)

            return desc == '—' // em-dash
                ? `Period hasn't started yet.`
                : noAttendance
                ? `Attendance intentionally isn't taken for this ${this.staffMemberTitle}'s sections this period.`
                : desc == ''
                ? `${this.staffMemberTitle} doesn't have a section this period.`
                : `${this.staffMemberTitle} hasn't taken attendance yet this period.`
        },
    },
}
</script>