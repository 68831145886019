<template>
    <thead>
        <tr>
            <th width="30%">
                Section

                <div>
                    &nbsp;
                </div>
            </th>
            <th v-for="col in tableData.colData" :class="col.clazz" width="14%" style="min-width: 14%; max-width: 14%">
                {{ dayOfWeek(col.key) }}
                <br/>
                {{ monthAndDay(col.key) }}

                <div v-if="editing && !col.is_holiday">
                    <a @click="fillDown(col.key)">
                        Set all to {{bulkUpdateAbsenceType.code || 'Present'}}
                        <i v-if="bulkUpdateComment" class="icon-comment"></i>
                        <i class="icon-arrow-down"></i>
                    </a>
                </div>
                <div v-else>
                    &nbsp;
                </div>
            </th>
        </tr>
    </thead>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    computed: mapState([
        'bulkUpdateAbsenceType',
        'bulkUpdateComment',
        'editing',
        'loading',
        'tableData',
    ]),
    methods: {
        ...mapActions([
            'fillDown'
        ]),
        dayOfWeek: function(date) {
            return moment(date).format('dddd')
        },
        monthAndDay: function(date) {
            return moment(date).format('MMMM D')
        },
    }
}
</script>