var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "favorite-behaviors-list" },
    [
      _c("h3", { staticClass: "text-center" }, [_vm._v("Favorites")]),
      _vm._v(" "),
      _vm.favoriteAndRecentBehaviors.length
        ? _c(
            "ul",
            { staticClass: "scroll-wrapper" },
            _vm._l(_vm.favoriteAndRecentBehaviors, function(behavior) {
              return _c(
                "li",
                {
                  key: _vm.getKey(behavior),
                  staticClass: "favorite-behavior-row",
                  class: { selected: _vm.selected == behavior }
                },
                [
                  _c("i", {
                    staticClass: "red",
                    class: _vm.icon(behavior),
                    on: {
                      click: function(e) {
                        return _vm.favorite(behavior)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "overflow-ellipsis fav-header",
                      class: {
                        "no-sub-header": !(
                          behavior.location_display_name || behavior.comments
                        )
                      },
                      attrs: { title: _vm.getBehaviorDisplayName(behavior) },
                      on: {
                        click: function(e) {
                          return _vm.select(behavior)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.getBehaviorDisplayName(behavior)) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  behavior.location_display_name || behavior.comments
                    ? _c(
                        "p",
                        { staticClass: "overflow-ellipsis fav-sub-header" },
                        [
                          behavior.location_display_name
                            ? _c("span", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(behavior.location_display_name) +
                                    "\n                "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          behavior.location_display_name && behavior.comments
                            ? _c("span", [
                                _vm._v(
                                  "\n                    -\n                "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          behavior.comments
                            ? _c(
                                "span",
                                {
                                  staticClass: "fav-comments",
                                  attrs: { title: behavior.comments }
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(behavior.comments) +
                                      "\n                "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          )
        : _c("EmptyState", {
            attrs: {
              icon: "icon-heart-empty",
              title: "No Recent Behaviors",
              details:
                "Recent behaviors will show up here once you've saved some records. Heart them to save as favorites for quick access."
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }