var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.guardian
    ? _c("div", { staticClass: "manage-guardian-access" }, [
        _c(
          "section",
          { staticClass: "white code-section" },
          [
            _c("h3", { staticClass: "no-margin text-center" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.guardian.user.display_name) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.guardianStudentsICanAccess,
                  "default-sort": {
                    prop: "student.first_name",
                    order: "ascending"
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "student.first_name",
                    label: "First Name",
                    sortable: ""
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "student.last_name",
                    label: "Last Name",
                    sortable: ""
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "student.school_name",
                    label: "School",
                    sortable: ""
                  }
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Revoke Access" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.guardianStudentsToRevoke,
                                  expression: "guardianStudentsToRevoke"
                                }
                              ],
                              attrs: { type: "checkbox", name: "revoke" },
                              domProps: {
                                value: scope.row,
                                checked: Array.isArray(
                                  _vm.guardianStudentsToRevoke
                                )
                                  ? _vm._i(
                                      _vm.guardianStudentsToRevoke,
                                      scope.row
                                    ) > -1
                                  : _vm.guardianStudentsToRevoke
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.guardianStudentsToRevoke,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = scope.row,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.guardianStudentsToRevoke = $$a.concat(
                                          [$$v]
                                        ))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.guardianStudentsToRevoke = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.guardianStudentsToRevoke = $$c
                                  }
                                }
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3839179395
                  )
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.guardianStudentsICannotAccess.length
          ? _c(
              "section",
              [
                _c("span", [
                  _vm._v(
                    "This parent/guardian also has access to a student at a different school. Someone at that school must revoke access to that student. You can copy and send along the student number and/or link below."
                  )
                ]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.guardianStudentsICannotAccess,
                      "default-sort": {
                        prop: "student.first_name",
                        order: "ascending"
                      }
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "student.sis_id",
                        label: "Student Number",
                        sortable: ""
                      }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "student.school_name",
                        label: "School",
                        sortable: ""
                      }
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "student.student_id",
                        label: "Link to Share",
                        sortable: ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/student/" +
                                        scope.row.student.student_id,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v("Student Page")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4278248677
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("section", { staticClass: "bg-gray-09 footer" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col span-24 text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-grey close",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("\n                    Cancel\n                ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-red",
                  attrs: {
                    type: "button",
                    disabled:
                      _vm.resetting || !_vm.guardianStudentsToRevoke.length
                  },
                  on: { click: _vm.removeGuardianStudents }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.resetting ? "Revoking" : "Revoke") +
                      "\n                "
                  )
                ]
              )
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row gutters access-info" }, [
      _c("div", { staticClass: "col span-24" }, [
        _c("p", [
          _vm._v(
            "You may revoke this parent/guardian’s access to one or all students. When you do, we’ll reset the student access code and invalidate all previous codes. A new code will have to be distributed to other parents/guardians who should be able to access this student in the portal."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }