<template>
    <div v-sticky="{ zIndex: 10, stickyTop: 25 }"
        v-if="!loading"
        class="page-toolbar"
        data-module="Corner Buttons"
        data-vertical="Behaviors"
    >
        <div class="btn-group">
            <div
                v-if="numAbsentStudents"
                class="btn"
                @click="toggleAbsentStudents"
            >
                <i :class="{ 'icon-eye-open': !showAbsentStudents, 'icon-eye-close': showAbsentStudents }" />
                &nbsp;
                {{ toggleAbsentStudentsMessage }}
            </div>
            <a v-if="hasPermission('reset_detention')"
                class="btn"
                data-feature="Reset Balances"
                @click="showResetModal"
            >
                <i class="icon-undo" />
                &nbsp;
                Reset {{ getText('campus_cash_all') }} Balances
            </a>
            <div
                class="btn btn-green"
                :class="{ disabled: saving }"
                data-feature="Save (Icon)"
                v-shortkey="{ctrls: ['ctrl', 's'], metas: ['meta', 's']}" @shortkey="handleClickSave"
                @click="handleClickSave"
                :title="canSave ? 'Save' : (hasAnyData ? 'There are one or more validation errors!' : '')"
            >
                <i class="icon-save" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import VueSticky from 'vue-sticky'
import VueScrollTo from 'vue-scrollto'
import ResetCashModal from './ResetCashModal.vue'

export default {
    data() {
        return {
            modalOptions: {
                height: 'auto',
                adaptive: true,
                scrollable: true,
                resizable: true
            },
        }
    },

    components: {
        ResetCashModal,
    },

    directives: {
        sticky: VueSticky
    },

    computed: {
        ...mapState([
            'loading',
            'saving',
            'canSave',
            'showAbsentStudents',
            'hasAnyData',
            'rows',
            'students',
            'studentGroupStudents',
        ]),
        ...mapGetters([
            'hasPermission',
            'getText',
        ]),
        toggleAbsentStudentsMessage: function() {
            var showHide = this.showAbsentStudents ? 'Hide' : 'Show'
            var studentsTitle = this.getText('students_title')

            return `${showHide} Absent (${this.numAbsentStudents})`
        },
        numAbsentStudents: function() {
            return this.rows.filter(row => row.absent).length
                + _.chain(this.rows)
                    .map(row => _.chain(row.studentsAndStudentGroups)
                                    .filter('student_group_id')
                                    .map(studentGroup => _.get(this.studentGroupStudents, studentGroup.student_group_id, []).filter(s => s.absent).length)
                                    .sum()
                                    .value()
                                + row.absentStudentGroupStudents.length
                    )
                    .sum()
                    .value()
        },
    },
    methods: {
        ...mapActions([
            'handleSaveRows',
            'scrollToErrors',
            'toggleAbsentStudents',
        ]),
        scrollToErrors: function() {
            let row = _.find(this.rows, row => !row.valid)
            let rowId = row ? `#behavior_row_${row.index}` : '#behaviors-app'

            VueScrollTo.scrollTo(rowId, 500, {
                onDone: x => Growl.error({ message: "Please fix validation errors and try again.", duration: 2000 })
            })
        },
        handleClickSave: _.debounce(function() {
            if (this.saving || !this.hasAnyData) {
                return null
            } else if (!this.canSave) {
                return this.scrollToErrors()
            }

            fetch('/totango/logBehaviorBehaviorsPage/Culture')
            this.handleSaveRows()
        }, 500, { leading: true }),
        showResetModal: function() {
            this.$modal.show(ResetCashModal, {}, this.modalOptions)
        },
    }
}
</script>

<style lang="scss">
.page-toolbar {
    position: relative;
    height: 0; // needed so toolbar doesnt affect layout, caused issues when react sticky converts to position fixed.

    .btn-group {
        text-align: right;
        top: -25px;
        right: -25px;
        position: absolute;
    }

    .btn {
        margin: 0 !important;
        font-size: 1.2rem;
        line-height: 1.2rem;
        padding-top: 12px;
    }
}
</style>