import { render, staticRenderFns } from "./EmptyState.vue?vue&type=template&id=3a5cf5e7&"
import script from "./EmptyState.vue?vue&type=script&lang=js&"
export * from "./EmptyState.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/sr/prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a5cf5e7')) {
      api.createRecord('3a5cf5e7', component.options)
    } else {
      api.reload('3a5cf5e7', component.options)
    }
    module.hot.accept("./EmptyState.vue?vue&type=template&id=3a5cf5e7&", function () {
      api.rerender('3a5cf5e7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "client/shared/Loaders/EmptyState.vue"
export default component.exports