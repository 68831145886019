<template>
    <div class="tss-checkbox-v">
        <span :class="{ checked: checked }" @click="toggle"></span>
        <label v-show="label" @click="toggle" class="tss-checkbox-label">
            {{ label }}
        </label>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'checked',
        event: 'change',
    },
    props: {
        checked: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        }
    },
    methods: {
        toggle() {
            this.$emit('change', !this.checked)
        }
    },
}
</script>

<style lang="scss">
.tss-checkbox-v {
    @extend .disable-user-select;

    > span {
        @extend .disable-user-select;
    }

    > label.tss-checkbox-label {
        padding-left: 0px !important;
        color: inherit !important;
    }
}
</style>