var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tbody",
    _vm._l(_vm.tableData.rowData, function(row, i) {
      return _c(
        "tr",
        [
          _c("td", [
            _c("div", { staticClass: "flex" }, [
              _c(
                "a",
                {
                  staticClass: "overflow-ellipsis",
                  attrs: { href: row.url, title: row.display_name }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(row.display_name) +
                      "\n                "
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.tableData.colData, function(col, j) {
            return _c("GridCell", {
              class: col.clazz,
              attrs: {
                rowIndex: i,
                colIndex: j,
                cellData: _vm.tableData.cellData[i][j]
              }
            })
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }