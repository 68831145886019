var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        {
          directives: [
            {
              name: "sticky",
              rawName: "v-sticky",
              value: { zIndex: 10, stickyTop: 25 },
              expression: "{ zIndex: 10, stickyTop: 25 }"
            }
          ],
          staticClass: "page-toolbar",
          attrs: {
            "data-module": "Corner Buttons",
            "data-vertical": "Behaviors"
          }
        },
        [
          _c("div", { staticClass: "btn-group" }, [
            _vm.numAbsentStudents
              ? _c(
                  "div",
                  {
                    staticClass: "btn",
                    on: { click: _vm.toggleAbsentStudents }
                  },
                  [
                    _c("i", {
                      class: {
                        "icon-eye-open": !_vm.showAbsentStudents,
                        "icon-eye-close": _vm.showAbsentStudents
                      }
                    }),
                    _vm._v(
                      "\n             \n            " +
                        _vm._s(_vm.toggleAbsentStudentsMessage) +
                        "\n        "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("reset_detention")
              ? _c(
                  "a",
                  {
                    staticClass: "btn",
                    attrs: { "data-feature": "Reset Balances" },
                    on: { click: _vm.showResetModal }
                  },
                  [
                    _c("i", { staticClass: "icon-undo" }),
                    _vm._v(
                      "\n             \n            Reset " +
                        _vm._s(_vm.getText("campus_cash_all")) +
                        " Balances\n        "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "shortkey",
                    rawName: "v-shortkey",
                    value: { ctrls: ["ctrl", "s"], metas: ["meta", "s"] },
                    expression: "{ctrls: ['ctrl', 's'], metas: ['meta', 's']}"
                  }
                ],
                staticClass: "btn btn-green",
                class: { disabled: _vm.saving },
                attrs: {
                  "data-feature": "Save (Icon)",
                  title: _vm.canSave
                    ? "Save"
                    : _vm.hasAnyData
                    ? "There are one or more validation errors!"
                    : ""
                },
                on: {
                  shortkey: _vm.handleClickSave,
                  click: _vm.handleClickSave
                }
              },
              [_c("i", { staticClass: "icon-save" })]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }