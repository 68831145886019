<template>
    <div id="student-class-absence-grid-app">
        <GridControls v-if="defaultAbsenceType"
            :hasData="hasData">
        </GridControls>

        <SpencilLoader v-if="loading"
             class="short">
        </SpencilLoader>
        <EmptyState v-else-if="!hasData"
             class="short"
             title="No sections found"
             details="There are no enrollments for these dates">
        </EmptyState>
        <div v-else>
            <table class="demerit_table simple full-width">
                <GridHeader/>
                <GridBody/>
            </table>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import GridBody from './GridBody.vue'
import GridControls from './GridControls.vue'
import GridHeader from './GridHeader.vue'
import ClassAbsenceModal from '../ClassAbsenceModal/ClassAbsenceModal.vue'
import EmptyState from 'shared/Loaders/EmptyState.vue'
import SpencilLoader from 'shared/Loaders/SpencilLoader.vue'
import _ from 'tss/lodash'

export default {
    components: {
        GridBody,
        GridControls,
        GridHeader,
        ClassAbsenceModal,
        EmptyState,
        SpencilLoader,
    },
    props: [
        'school_id',
        'term_id',
        'student_id',
        'student_display_name',
        'student_photo_url',
        'staff_member_id',
        'excuse_class_absences',
        'date',
        'settings',
    ],
    data() {
        return {
            modalOptions: {
                root: this.$root,
                height: '600px',
                width: '700px',
                minHeight: 600,
                minWidth: 700,
                maxHeight: 600,
                maxWidth: 700,
            },
            modalEvents: {
                'before-close': this.beforeClose
            },
        }
    },
    computed: {
        ...mapState([
            'absenceTypes',
            'defaultAbsenceType',
            'loading',
            'loadingAbsenceTypes',
            'tableData',
            'changed',
            'saving',
        ]),
        hasData() {
            return !this.$store.state.loading
                && this.$store.state.tableData
                && this.$store.state.tableData.colData
                && this.$store.state.tableData.colData.length
        }
    },
    methods: {
        ...mapActions([
            'fetchImportantDates',
            'fetchClassAbsenceTypes',
            'fetchMe',
            'fetchClassAbsences',
            'fetchBellSchedulePeriods',
            'setSettings',
            'setStudentId',
            'setStudentDisplayName',
            'setStudentPhotoUrl',
            'setSchoolId',
            'setTermId',
            'setStaffMemberId',
            'setExcuseClassAbsences',
            'setDate',
            'setChanged',
        ]),

        showClassAbsenceModal: function(params) {
            console.log(params)
            this.$modal.show(ClassAbsenceModal, { params }, this.modalOptions, this.modalEvents)
        },
        beforeClose: function(event) {
            if (this.changed && !this.saving) {
                const message = "It looks like you have unsaved changes. Are you sure?"
                if (!confirm(message)) {
                    return event.stop()
                }
            }

            this.setChanged(false)
        },
    },

    async mounted() {
        this.setStudentId(this.student_id)
        this.setStudentDisplayName(this.student_display_name)
        this.setStudentPhotoUrl(this.student_photo_url)
        this.setSchoolId(this.school_id)
        this.setTermId(this.term_id)
        this.setSettings(this.settings)
        this.setStaffMemberId(this.staff_member_id)
        this.setExcuseClassAbsences(this.excuse_class_absences)
        this.setDate(this.date)

        await this.fetchClassAbsenceTypes()
        await this.fetchMe()
        this.fetchClassAbsences()
        this.fetchImportantDates()
        this.fetchBellSchedulePeriods()

        document.addEventListener('VueEvent.ClassAbsenceModal', event => {
            this.showClassAbsenceModal(event.detail)
        })
    },
}
</script>

<style lang="scss">
#student-class-absence-grid-app {
    .flex {
        display: flex;
    }

    div.btn {
        @extend .no-transition;
    }

    table {
        table-layout: fixed;

        tr {
            height: $btn-height + 14; // for 6px padding and 1px border
            
            td {
                .padme {
                    padding-left: 1em;
                    
                    i {
                        margin-left: 5px;
                        margin-right: 5px;
                        margin-top: 3px;
                    }
                }
            }
        }
    }
}
</style>