<template>
    <TssSelect ref="studentGroupSelect"
        label="display_name"
        v-model="selected"
        :options="options"
        :loading="isLoading"
        :multiple="multiple"
        :tabindex="tabindex"
        :disabled="disabled"
        :dropdownPlaceholder="placeholder">
    </TssSelect>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import _ from 'tss/lodash'
import TssSelect from '../TssSelect.vue'
import StudentGroupService from 'services/StudentGroup'

export default {
    components: {
        TssSelect,
    },
    data() {
        return {
            options: [],
            isLoading: true,
        }
    },
    props: [
        'input',
        'value',
        'initialOptions',
        'multiple',
        'tabindex',
        'placeholder',
        'disabled',
    ],
    computed: {
        ...mapState([
            'schoolId',
            'loggedInUserId',
        ]),
        selected: {
            get() {
                return this.value
            },
            set(value) {
                this.input(value)
                    .then(retval => {
                        if (!retval) {
                            this.$refs.studentGroupSelect.updateMutableValue(this.value)
                        }
                    })
            }
        },
    },
    mounted: function() {
        if (this.initialOptions === undefined) { // prop not passed, do load
            if (this.loggedInUserId) {
                this.getStudentGroups()
                    .then(this.formatAndSetOptions)
            } else {
                console.error("Programmer Error -- no loggedInUserId")
            }
        } else if (this.initialOptions) { // prop passed as non-null, set options
            this.formatAndSetOptions(this.initialOptions)
        } // else prop passed as null, wait for prop to update/watch to set options
    },
    methods: {
        getStudentGroups() {
            const studentGroupParams = {
                active: 1,
                school_ids: this.schoolId,
                user_id: this.loggedInUserId,
            }
            return StudentGroupService.getStudentGroupsByGradeLevel(studentGroupParams)
        },
        formatAndSetOptions(sortedRecords) {
            this.options = sortedRecords
            this.isLoading = false
        },
    },
    watch: {
        initialOptions: function(newVal, oldVal) {
            this.formatAndSetOptions(newVal)
        },
    },
}
</script>