<template>
    <section class="behavior-row-list no-padding">
        <SavingIndicator v-if="saving"/>

        <BehaviorRow v-for="(row, i) in rows"
            :key="i"
            :row="row"/>

        <div id="state-wrapper">
            <SpencilLoader v-if="loading" class="mini"/>
            <EmptyState v-else-if="!studentGroup || !studentGroup.student_group_id"
                 class="mini"
                 icon="icon-group"
                 title="Select a group"
                 :details="`Select a group above to log behaviors for multiple ${studentsLower} in that group. Or choose individuals from the '${studentsTitle}' menu.`"/>
            <EmptyState v-else-if="!students.length"
                 class="mini"
                 icon="icon-user"
                 :title="`No ${studentsLower} found`"
                 :details="`There are no ${studentsLower} in this group.`"/>
         </div>
    </section>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import BehaviorRow from './BehaviorRow.vue'
import SpencilLoader from 'shared/Loaders/SpencilLoader.vue'
import SavingIndicator from 'shared/Loaders/SavingIndicator.vue'
import EmptyState from 'shared/Loaders/EmptyState.vue'
import VueScrollTo from 'vue-scrollto'

export default {
    components: {
        BehaviorRow,
        SpencilLoader,
        SavingIndicator,
        EmptyState,
        VueScrollTo,
    },

    computed: {
        ...mapState([
            'rows',
            'loading',
            'saving',
            'studentGroup',
            'students',
        ]),
        ...mapGetters([
            'getText',
        ]),
        studentsLower() {
            return this.getText('students_lower')
        },
        studentsTitle() {
            return this.getText('students_title')
        },
    },

    watch: {
        loading: function(newVal) {
            if (newVal) {
                VueScrollTo.scrollTo('#state-wrapper', 1000)
            } else if (this.students.length) {
                setTimeout(() => VueScrollTo.scrollTo('[name="behavior_student_row_0"]', 10, { duration: 10, offset: -20 }, 50))
            }
        },
    },
}
</script>
