<template>
    <div v-if="guardian" class="manage-guardian-access">
        <section class="white code-section">
            <h3 class="no-margin text-center">
                {{ guardian.user.display_name }}
            </h3>
            <div class="row gutters access-info">
                <div class="col span-24">
                    <p>You may revoke this parent/guardian’s access to one or all students. When you do, we’ll reset the student access code and invalidate all previous codes. A new code will have to be distributed to other parents/guardians who should be able to access this student in the portal.</p>
                </div>
            </div>
            <el-table
                :data="guardianStudentsICanAccess"
                :default-sort="{prop: 'student.first_name', order: 'ascending'}"
                style="width: 100%">
                <el-table-column prop="student.first_name" label="First Name" sortable>
                </el-table-column>
                <el-table-column prop="student.last_name" label="Last Name" sortable>
                </el-table-column>
                <el-table-column prop="student.school_name" label="School" sortable>
                </el-table-column>
                <el-table-column label="Revoke Access">
                    <template slot-scope="scope">
                        <input
                            type="checkbox"
                            name="revoke"
                            :value="scope.row"
                            v-model="guardianStudentsToRevoke">
                    </template>
                </el-table-column>
            </el-table>
        </section>
        <section v-if="guardianStudentsICannotAccess.length">
            <span>This parent/guardian also has access to a student at a different school. Someone at that school must revoke access to that student. You can copy and send along the student number and/or link below.</span>
            <el-table
                :data="guardianStudentsICannotAccess"
                :default-sort="{prop: 'student.first_name', order: 'ascending'}"
                style="width: 100%">
                <el-table-column prop="student.sis_id" label="Student Number" sortable>
                </el-table-column>
                <el-table-column prop="student.school_name" label="School" sortable>
                </el-table-column>
                <el-table-column prop="student.student_id" label="Link to Share" sortable>
                    <template slot-scope="scope">
                        <a :href="'/student/' + scope.row.student.student_id" target="_blank">Student Page</a>
                    </template>
                </el-table-column>
            </el-table>
        </section>
        <section class="bg-gray-09 footer">
            <div class="row">
                <div class="col span-24 text-right">
                    <button
                        type="button"
                        class="btn btn-grey close"
                        @click="$emit('close')">
                        Cancel
                    </button>
                    <button
                        type="button"
                        class="btn btn-red"
                        :disabled="resetting || !guardianStudentsToRevoke.length"
                        @click="removeGuardianStudents">
                        {{ resetting ? 'Revoking' : 'Revoke' }}
                    </button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import _ from 'lodash'

export default {
    props: ['guardianId'],
    data() {
        return {
            resetting: false,
            guardianStudentsToRevoke: [],
        }
    },
    computed: {
        ...mapState([
            'guardians',
        ]),
        ...mapGetters([
            'studentId',
        ]),
        guardian() {
            if (this.guardianId) {
                return _.find(this.guardians, guardian => guardian.guardian_id == this.guardianId)
            }
        },
        guardianStudentsICanAccess() {
            return _.filter(this.guardian.guardian_students, guardianStudent => {
                return guardianStudent.guardian_student_id
            })
        },
        guardianStudentsICannotAccess() {
            return _.filter(this.guardian.guardian_students, guardianStudent => {
                return !guardianStudent.guardian_student_id
            })
        },
    },
    methods: {
        ...mapActions([
            'bulkRevokeStudentAccess'
        ]),
        // deactivate guardian student for anyone we're revoking access to
        // reset student access code for anyone we're revoking access to
        // reset student access code in state for the student we're viewing if revoked
        // remove guardian from state if we revoked the student we're viewing
        async removeGuardianStudents() {
            this.resetting = true
            await Promise.all([
                this.bulkRevokeStudentAccess(this.guardianStudentsToRevoke),
                // this happens fast and it feels like nothing is happening
                setTimeout(() => {}, 1500)
            ])
            this.resetting = false
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss">
.manage-guardian-access {
    td {
        @extend .overflow-ellipsis;
    }
    .row.access-info {
        padding-top: 0px;
    }
    .footer {
        bottom: 0px;
        width: 100%;
        box-sizing: border-box;
    }
    h3 {
        user-select: text;
    }
    .btn {
        line-height: 16px;
        font-family: $font-stack !important;
    }
}
</style>
