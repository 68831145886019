<template>
    <div class="absence-modal">
        <div class="left-column">
            <section class="white">
                <h3 class="no-margin text-center">
                    {{ header }}
                </h3>
                <div class="description">
                </div>
                <i class="icon-remove" @click="$emit('close')"></i>
            </section>
            <section class="white middle">
                <SpencilLoader v-if="loading"/>
                <template v-else>
                    <div class="row gutters">
                        <div class="student-name">
                            <img class="avatar" :src="studentPhotoUrl"/>
                            <div class="student-name-display">
                                <a :href="`/student/${studentId}`" tooltip
                                    >{{ studentDisplayName }}</a>
                                <br/>
                                {{ dateDisplay }}
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col span-24">
                            <TssSelect
                                v-model="selectedAbsenceType"
                                label="display_name"
                                :options="absenceTypesDisplay"
                                :loading="absenceTypesLoading"
                                @input="selectFirstIfEmpty">
                            </TssSelect>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col span-24">
                            <textarea
                                rows="5"
                                placeholder="Comments"
                                v-model="comments"/>
                        </div>
                    </div>
                </template>
            </section>
            <section class="bg-gray-09 footer">
                <div class="row">
                    <div class="col span-6">
                        <button
                            v-if="isEdit"
                            tabindex="-1"
                            type="button"
                            class="btn btn-red"
                            :class="{disabled: loading || saving || deactivating}"
                            :disabled="loading || saving || deactivating"
                            @click="handleClickDeactivate">
                            <i v-if="deactivating" class="icon-spinner icon-spin"></i>
                            <i v-else class="icon-trash"></i>
                            Deactivate
                        </button>
                        <div v-else>&nbsp;</div>
                    </div>
                    <div class="col span-18 text-right">
                        <button
                            tabindex="-1"
                            type="button"
                            class="btn btn-green"
                            :class="{disabled: loading || saving || deactivating}"
                            :disabled="loading || saving || deactivating"
                            @click="handleClickSave">
                            <i v-if="saving" class="icon-spinner icon-spin"></i>
                            Save
                        </button>
                    </div>
                </div>
            </section>
        </div>

        <ChangeHistory
            v-if="recordId"
            orm-class="Absence"
            :orm-id="recordId"
            class="right-column">
        </ChangeHistory>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import SpencilLoader from 'shared/Loaders/SpencilLoader.vue'
import ChangeHistory from 'shared/ChangeHistory.vue'
import TssSelect from 'shared/TssSelect.vue'
import AbsenceService from 'services/Absence'

export default {
    components: {
        SpencilLoader,
        ChangeHistory,
        TssSelect,
    },

    props: [
        'params',
    ],

    data() {
        return {
            loading: false,
            saving: false,
            deactivating: false,
            record: {},
            comments: null,
            selectedAbsenceType: null,
            forceVisibleAbsenceTypeId: null,
        }
    },

    mounted: async function() {
        console.log(this.params)
        const id = this.recordId

        if (id) {
            this.loading = true
            this.forceVisibleAbsenceTypeId = this.params.force_visible_absence_type_id
            const response = await AbsenceService.get(id, {expand: 'student.photo'})
            console.log(response)
            this.record = _.get(response, 'results.absence')
            this.comments = this.record.comments,
            this.selectedAbsenceType = _.find(this.$store.state.absenceTypes, { absence_type_id: this.record.absence_type_id }),
            this.loading = false
        } else {
            console.log("No id. What is this message??")
        }

        this.setChanged(false)
    },

    computed: {
        ...mapState([
            'staffMemberId',
            'absenceTypes',
            'absenceTypesLoading',
            'defaultAbsenceType',
        ]),
        recordId() {
            return _.get(this.params, 'absence_id')
        },
        isEdit() {
            return this.recordId
        },
        header() {
            return 'Edit Attendance'
        },
        studentId() {
            return _.get(this.record, 'student.student_id')
        },
        studentPhotoUrl() {
            return _.get(this.record, 'student.photo')
        },
        studentDisplayName() {
            return _.get(this.record, 'student.display_name')
        },
        dateDisplay() {
            return moment(this.record.date).format('dddd, M/D/YYYY');
        },
        absenceTypesDisplay() {
            return _.chain(this.$store.state.absenceTypes)
                .cloneDeep()
                .filter(d => {
                    // only show an absence type in the dropdown if
                    // a) it's the absence type in the database for this cell, OR
                    // b) it's active and you have permission to see it
                    // c) and it's not auto-created
                    const isAutoCreated = _.filter(d.suspension_types, st => {
                        return st.active == '1'
                            && (!d.school_id
                                || !st.school_id
                                || d.school_id == st.school_id)
                    }).length

                    return this.record.absence_type_id == d.absence_type_id
                        || (this.forceVisibleAbsenceTypeId // for editing from the incidents page
                            && d.absence_type_id == this.forceVisibleAbsenceTypeId)
                        || (d.active == '1'
                            && d.hidden == '0'
                            && !isAutoCreated)
                })
                .map(d => {
                    d.code = d.code || 'Present'

                    return d
                })
                .value()
        },
    },

    methods: {
        ...mapActions([
            'setChanged',
            'setSaving',
        ]),

        selectFirstIfEmpty(absenceType) {
            if (!absenceType) {
                // don't allow them to select an empty option
                // so reset to the select to the default value which will re-fire
                // this event. Do it in a setTimeout to avoid a race condition.
                // NOTE: arrow function is important here to maintain 'this' reference
                // pointing to our component and not something else
                this.$nextTick(() => this.selectedAbsenceType = this.defaultAbsenceType)
                return
            }
        },

        handleClickDeactivate: _.debounce(async function() {
            this.setSaving(true)
            this.deactivating = true
            const id = this.recordId
            const response = await AbsenceService.deactivate(id)

            this.updateLegacyApp(response, id)

            this.deactivating = false
            this.$emit('close')
        }, 500, { leading: true }),

        updateLegacyApp: function(response, id) {
            _.extend(response.results, {
                'class': 'Absence',
                'id': id,
            })

            window.handleInPlaceEdit(response)
        },

        handleClickSave: _.debounce(async function() {
            this.setSaving(true)
            if (this.isEdit) {
                this.saving = true

                const id = this.recordId
                const record = this.getRecordToSave()
                const response = await AbsenceService.update(record)
                const dataResponse = await AbsenceService.getData(id)

                this.updateLegacyApp(dataResponse, id)

                this.saving = false
            } else {
                // create isn't handled
            }
            this.$emit('close')
        }, 500, { leading: true }),

        getRecordToSave: function() {
            const absence_type_id = this.selectedAbsenceType.absence_type_id
                || this.record.absence_type_id
            const active = this.selectedAbsenceType.absence_type_id ? 1 : 0

            return {
                absence_id: this.recordId,
                staff_member_id: this.staffMemberId,
                comments: this.comments,
                absence_type_id,
                active,
            }
        },

        isChanged() {
            return this.record.absence_type_id != this.selectedAbsenceType.absence_type_id
                || this.record.active != '1'
                || (this.record.comments || '') != (this.comments || '')
        },
    },

    watch: {
        selectedAbsenceType: function() {
            this.setChanged(this.isChanged())
        },
        comments: function() {
            this.setChanged(this.isChanged())
        },
    },
}
</script>

<style lang="scss">
.absence-modal {
    position: relative;
    height: 600px;
    width: 700px;
    display: flex;

    section {
        width: 100%;
        box-sizing: border-box;
    }

    .left-column {
        width: 400px;

        display: flex;
        flex-direction: column;

        .middle,
        .loading-state {
            flex: 1;
        }
    }

    .middle {
        height: 427px;
        max-height: 427px;
        overflow: visible; // so that dropdowns show up
        padding-top: 10px;
    }

    .right-column {
        height: 600px;
        width: 300px;
        display: inline-block;
        background: #fff;
        box-sizing: border-box;
        border-left: 3px solid $section-border;
        padding: $g-pad;

        &.favorites {
            padding-left: 0;
        }

        .changelogWrapper {
            height: 490px;
        }

        .scroll-wrapper {
            height: 490px;
        }
    }

    .invalid .v-select {
        div.dropdown-toggle {
            border: 2px solid $sr-gray-06 !important;
        }
    }

    .loading-state {
        margin-top: 50px;
        margin-bottom: 20px;
    }

    &:not(.odd) {
        background-color: $sr-gray-09;
    }

    textarea {
        resize: vertical;
        max-height: 140px;
    }

    .row.gutters:not(:first-child) {
        padding-top: 5px;
    }

    .icon-remove {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        color: lighten($sr-gray-02, 30%);
        font-size: 1.2em;

        &:hover {
            color: lighten($sr-gray-02, 40%);
        }
    }

    .student-name {
        max-width: 350px;
        display: flex;

        a {
            @extend .semi-bold;
        }

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        > div {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .student-name-display {
            margin-left: 5px;
            margin-bottom: 13px;
        }
    }
}
</style>