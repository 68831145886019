var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "lms-analysis-filter-bar gray",
      attrs: { "data-module": "Page Controls", "data-vertical": "LMS Analysis" }
    },
    [
      _c("div", { staticClass: "row gutters" }, [
        _c(
          "div",
          { staticClass: "col span-4" },
          [
            _c("label", { staticClass: "label" }, [_vm._v("Timeline")]),
            _vm._v(" "),
            _c("TssSelect", {
              attrs: {
                "dropdown-placeholder": "No Option Selected",
                options: _vm.allDateGroupBys,
                value: _vm.dateGroupBy
              },
              on: { input: _vm.setDateGroupBy }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col span-4" },
          [
            _c("label", { staticClass: "label" }, [_vm._v("Group By")]),
            _vm._v(" "),
            _c("TssSelect", {
              attrs: {
                placeholder: "No Option Selected",
                options: _vm.allLmsGroupBys,
                value: _vm.lmsGroupBy
              },
              on: { input: _vm.setLmsGroupBy }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col span-4" },
          [
            _c("label", { staticClass: "label" }, [_vm._v("Metric")]),
            _vm._v(" "),
            _c("TssSelect", {
              attrs: {
                placeholder: "No Option Selected",
                options: _vm.allLmsMetrics,
                value: _vm.lmsMetric
              },
              on: { input: _vm.setLmsMetric }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.lmsMetric.key == "pct_engaged_students",
                expression: "lmsMetric.key == 'pct_engaged_students'"
              }
            ],
            staticClass: "col span-4"
          },
          [
            _c("label", { staticClass: "label" }, [_vm._v("Engagement %")]),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "number",
                name: "engaged_pct",
                value: "0",
                min: "0",
                max: "100",
                step: "1"
              },
              on: {
                change: function(e) {
                  return _vm.setEngagedPct(e.target.value)
                }
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col text-right",
            class:
              _vm.lmsMetric.key == "pct_engaged_students" ? "span-8" : "span-12"
          },
          [
            _c("label", { staticClass: "label" }, [_vm._v(" ")]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-blue",
                attrs: { type: "button" },
                on: { click: _vm.showFilterModal }
              },
              [
                _c("i", { staticClass: "icon-filter" }),
                _vm._v("\n                Filters\n            ")
              ]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }