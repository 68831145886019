<template>
    <div class="class-absence-modal">
        <div class="left-column">
            <section class="white">
                <h3 class="no-margin text-center">
                    {{ header }}
                </h3>
                <div class="description">
                </div>
                <i class="icon-remove" @click="$emit('close')"></i>
            </section>
            <section class="white middle">
                <SpencilLoader v-if="loading"/>
                <template v-else>
                    <div class="row gutters">
                        <div class="student-name">
                            <img class="avatar" :src="studentPhotoUrl"/>
                            <div class="student-name-display">
                                <a :href="`/student/${studentId}`" tooltip
                                    >{{ studentDisplayName }}</a>
                                {{ absenceDisplay }}
                                <br/>
                                <a :href="`/absences/class?section_period_id=${record.section_period_id}&staff_member_id=&date=${record.date}`"
                                    >{{ sectionDisplayName }}</a>
                                <br/>
                                {{ dateDisplay }}
                            </div>
                        </div>
                    </div>
                    <div v-if="!isCommentsOnlyMode" class="row gutters">
                        <div class="col span-24">
                            <TssSelect
                                v-model="selectedAbsenceType"
                                label="display_name"
                                :options="absenceTypesDisplay(this.record)"
                                :loading="absenceTypesLoading"
                                @input="selectFirstIfEmpty">
                            </TssSelect>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col span-24">
                            <textarea
                                ref="comments"
                                rows="5"
                                placeholder="Comments"
                                v-model="comments"/>
                        </div>
                    </div>
                </template>
            </section>
            <section class="bg-gray-09 footer">
                <div class="row">
                    <div class="col span-6">
                        <button
                            v-if="isEdit"
                            tabindex="-1"
                            type="button"
                            class="btn btn-red"
                            :class="{disabled: saving || deactivating}"
                            :disabled="saving || deactivating"
                            @click="handleClickDeactivate">
                            <i v-if="deactivating" class="icon-spinner icon-spin"></i>
                            <i v-else class="icon-trash"></i>
                            Deactivate
                        </button>
                        <div v-else>&nbsp;</div>
                    </div>
                    <div class="col span-18 text-right">
                        <button
                            tabindex="-1"
                            type="button"
                            class="btn btn-green"
                            :class="{disabled: saving || deactivating}"
                            :disabled="saving || deactivating"
                            @click="handleClickDone">
                            <i v-if="saving" class="icon-spinner icon-spin"></i>
                            {{ doneOrSaveButtonText }}
                        </button>
                    </div>
                </div>
            </section>
        </div>

        <ChangeHistory
            v-if="params.class_absence_id"
            orm-class="ClassAbsence"
            :orm-id="params.class_absence_id"
            class="right-column">
        </ChangeHistory>
        <ChangeHistory
            v-else-if="params.class_absence_total_id"
            orm-class="ClassAbsenceTotal"
            :orm-id="params.class_absence_total_id"
            class="right-column">
        </ChangeHistory>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import SpencilLoader from 'shared/Loaders/SpencilLoader.vue'
import ChangeHistory from 'shared/ChangeHistory.vue'
import TssSelect from 'shared/TssSelect.vue'
import ClassAbsenceService from 'services/ClassAbsence'

export default {
    components: {
        SpencilLoader,
        ChangeHistory,
        TssSelect,
    },

    props: [
        'rowIndex',
        'colIndex',
        'k',
        'params',
        'mode',
    ],

    data() {
        return {
            loading: false,
            saving: false,
            deactivating: false,
            record: {},
            comments: null,
            selectedAbsenceType: null,
        }
    },

    mounted: async function() {
        console.log(this.params)
        const id = this.recordId

        if (this.isEdit) {
            this.loading = true
            const params = {
                expand: 'student.photo',
            }
            const response = await ClassAbsenceService.get(id, params)
            this.record = _.get(response, 'results.class_absence')
            this.loading = false
        } else if (this.isCommentsOnlyMode) {
            this.record = this.params
        } else {
            console.log("No id. What is this message??")
            return
        }

        this.comments = this.record.comments
        this.selectedAbsenceType = _.find(this.$store.state.absenceTypes, { absence_type_id: this.record.absence_type_id }) || this.record.absence_type

        if (this.isCommentsOnlyMode) {
            this.$nextTick(() => this.$refs.comments.focus())
        }

        this.setChanged(false)
    },

    computed: {
        ...mapState([
            'staffMemberId',
            'absenceTypes',
            'absenceTypesLoading',
            'defaultAbsenceType',
            'excuseClassAbsences',
            'hasLockClassAttendance',
        ]),
        ...mapGetters([
            'absenceTypesDisplay',
        ]),
        isCommentsOnlyMode() {
            return this.mode == 'commentsOnly'
        },
        recordId() {
            return _.get(this.params, 'class_absence_id')
        },
        isEdit() {
            return !this.isCommentsOnlyMode
                && this.recordId
        },
        header() {
            return !this.isCommentsOnlyMode
                ? 'Edit Class Attendance'
                : 'Edit Comments'
        },
        studentId() {
            return _.get(this.record, 'student.student_id')
        },
        studentPhotoUrl() {
            return _.get(this.record, 'student.photo')
        },
        studentDisplayName() {
            return _.get(this.record, 'student.display_name')
        },
        sectionDisplayName() {
            return _.get(this.params, 'sectionDisplayName')
        },
        doneOrSaveButtonText() {
            return !this.isCommentsOnlyMode
                ? 'Save'
                : 'Done'
        },
        dateDisplay() {
            return moment(this.record.date).format('dddd, M/D/YYYY');
        },
        absenceDisplay() {
            const displayName = this.selectedAbsenceType
                ? this.selectedAbsenceType.code || this.selectedAbsenceType.display_name
                : null

            return this.isCommentsOnlyMode && displayName ? `| ${displayName}` : ''
        },
    },

    methods: {
        ...mapActions([
            'setComments',
            'fetchClassAbsences',
            'setChanged',
            'setSaving',
        ]),

        selectFirstIfEmpty(absenceType) {
            if (!absenceType) {
                // don't allow them to select an empty option
                // so reset to the select to the default value which will re-fire
                // this event. Do it in a setTimeout to avoid a race condition.
                // NOTE: arrow function is important here to maintain 'this' reference
                // pointing to our component and not something else
                this.$nextTick(() => this.selectedAbsenceType = this.defaultAbsenceType)
                return
            }
        },

        handleClickDeactivate: _.debounce(async function() {
            this.setSaving(true)
            this.deactivating = true
            const id = this.recordId
            const response = await ClassAbsenceService.deactivate(id)

            this.updateLegacyApp(response, id)

            this.deactivating = false
            this.$emit('close')
        }, 500, { leading: true }),

        updateLegacyApp: function(response, id) {
            _.extend(response.results, {
                'class': 'ClassAbsence',
                'id': id,
            })

            window.handleInPlaceEdit(response)
        },

        handleClickDone: _.debounce(async function() {
            if (this.isEdit) {
                this.setSaving(true)
                this.saving = true

                const id = this.recordId
                const record = this.getRecordToSave()
                const response = await ClassAbsenceService.update(record)
                const dataResponse = await ClassAbsenceService.getData(id)

                this.updateLegacyApp(dataResponse, id)
                this.fetchClassAbsences()

                this.saving = false
            } else {
                this.setComments({
                    rowIndex: this.rowIndex,
                    colIndex: this.colIndex,
                    k: this.k,
                    comments: this.comments,
                })
            }
            this.$emit('close')
        }, 500, { leading: true }),

        getRecordToSave: function() {
            const absence_type_id = this.selectedAbsenceType.absence_type_id
                || this.record.absence_type_id
            const active = this.selectedAbsenceType.absence_type_id ? 1 : 0

            return {
                class_absence_id: this.recordId,
                staff_member_id: this.staffMemberId,
                comments: this.comments,
                absence_type_id,
                active,
            }
        },

        isChanged() {
            return this.record.absence_type_id != this.selectedAbsenceType.absence_type_id
                || (this.record.comments || '') != (this.comments || '')
        },
    },

    watch: {
        selectedAbsenceType: function() {
            this.setChanged(this.isChanged())
        },
        comments: function() {
            this.setChanged(this.isChanged())
        },
    },
}
</script>

<style lang="scss">
.class-absence-modal {
    position: relative;
    height: 600px;
    width: 700px;
    display: flex;

    section {
        width: 100%;
        box-sizing: border-box;
    }

    .left-column {
        width: 400px;

        display: flex;
        flex-direction: column;

        .middle,
        .loading-state {
            flex: 1;
        }
    }

    .middle {
        height: 427px;
        max-height: 427px;
        overflow: visible; // so that dropdowns show up
        padding-top: 10px;
    }

    .right-column {
        height: 600px;
        width: 300px;
        display: inline-block;
        background: #fff;
        box-sizing: border-box;
        border-left: 3px solid $section-border;
        padding: $g-pad;

        &.favorites {
            padding-left: 0;
        }

        .changelogWrapper {
            height: 490px;
        }

        .scroll-wrapper {
            height: 490px;
        }
    }

    .invalid .v-select {
        div.dropdown-toggle {
            border: 2px solid $sr-gray-06 !important;
        }
    }

    .loading-state {
        margin-top: 50px;
        margin-bottom: 20px;
    }

    &:not(.odd) {
        background-color: $sr-gray-09;
    }

    textarea {
        resize: vertical;
        max-height: 140px;
    }

    .row.gutters:not(:first-child) {
        padding-top: 5px;
    }

    .icon-remove {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        color: lighten($sr-gray-02, 30%);
        font-size: 1.2em;

        &:hover {
            color: lighten($sr-gray-02, 40%);
        }
    }

    .student-name {
        max-width: 350px;
        display: flex;

        a {
            @extend .semi-bold;
        }

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        > div {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .student-name-display {
            margin-left: 5px;
            margin-bottom: 13px;
        }
    }
}
</style>