var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lms-chart" },
    [
      _vm.loading
        ? _c("SpencilLoader")
        : _vm.hasData
        ? [
            _c("LmsTrends", {
              attrs: {
                "chart-data": _vm.chartData,
                formatter: _vm.formatter,
                metric: _vm.lmsMetric.label
              }
            }),
            _vm._v(" "),
            _c("section", [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col span-24" }, [
                  _c("h2", { staticClass: "text-center no-margin" }, [
                    _vm._v(_vm._s(_vm.lmsMetric.label))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.subtitle))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col span-18" },
                  [
                    _vm.chartData.labels.length == 1
                      ? _c("BarChart", {
                          attrs: {
                            "chart-data": _vm.chartData,
                            options: _vm.chartOptions,
                            styles: _vm.chartStyles,
                            semaphore: _vm.chartSemaphore
                          }
                        })
                      : _c("LineChart", {
                          attrs: {
                            "chart-data": _vm.chartData,
                            options: _vm.chartOptions,
                            styles: _vm.chartStyles,
                            semaphore: _vm.chartSemaphore
                          }
                        })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col span-6 chart-legend" }, [
                  _c(
                    "div",
                    { staticClass: "legend-header" },
                    [
                      _c("span", { staticClass: "bold" }, [
                        _vm._v(
                          "\n                            Legend\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.numHidden > 0
                        ? [
                            _vm._v(
                              "\n                             | \n                            "
                            ),
                            _c("a", { on: { click: _vm.showAll } }, [
                              _vm._v(
                                "\n                                show all\n                            "
                              )
                            ])
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    _vm._l(this.chartData.datasets, function(dataset, i) {
                      return _c(
                        "li",
                        {
                          key: _vm.lmsMetric.key + "_" + i,
                          on: {
                            mouseover: function() {
                              return _vm.onLegendHover(dataset, i)
                            },
                            mouseleave: function() {
                              return _vm.onLegendLeave(dataset, i)
                            }
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "legend-color",
                            class: dataset.stashedHidden
                              ? "dataset-hidden"
                              : "",
                            style:
                              "background-color: " +
                              dataset.stashedBackgroundColor +
                              "; border-color: " +
                              dataset.stashedBorderColor,
                            on: {
                              click: function() {
                                return _vm.onLegendClick(dataset, i)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "legend-label",
                              class: dataset.stashedHidden
                                ? "dataset-hidden"
                                : "",
                              on: {
                                click: function() {
                                  return _vm.onLegendClick(dataset, i)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(dataset.label) +
                                  "\n                            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return (function() {
                                    return _vm.onLegendClickOnly(dataset, i)
                                  })($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                only\n                            "
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              ])
            ])
          ]
        : !_vm.isEdlinkConnected
        ? _c("section", { staticClass: "text-center" }, [
            _c("h2", [_vm._v("Connect Schoolrunner to Edlink")]),
            _vm._v(" "),
            _c("p", { staticClass: "margin-bottom" }, [
              _vm._v(
                "\n            To view LMS data in Schoolrunner you first need to complete the connection between Schoolrunner and Edlink!\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-blue",
                attrs: { disabled: _vm.integrations.length },
                on: { click: _vm.createEdlinkAccount }
              },
              [_vm._v("\n            Step 1: Create Edlink Account\n        ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-orange",
                attrs: { disabled: !_vm.integrations.length },
                on: { click: _vm.attemptEdlinkSignIn }
              },
              [_vm._v("\n            Step 2: Log in with Edlink\n        ")]
            )
          ])
        : !_vm.isSyncFinished
        ? _c("div", { staticClass: "loading-state" }, [
            _c("div", { staticClass: "spencil-loader-container" }, [
              _c("div", { staticClass: "spencil-loader with-loader-message" }, [
                _c("span", { staticClass: "spencil-loader-message" }, [
                  _vm._v(_vm._s(_vm.syncStatus))
                ])
              ])
            ])
          ])
        : _c("EmptyState", { attrs: { title: "No LMS data found" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }