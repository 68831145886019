var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.row.visible,
            expression: "row.visible"
          }
        ],
        key: "behavior_row_" + _vm.row.index,
        staticClass: "behavior-row",
        class: {
          odd: _vm.row.visibleIndex % 2 != 0,
          student: !!_vm.row.student
        },
        attrs: {
          id: "behavior_row_" + _vm.row.index,
          name: _vm.rowName,
          "data-module": "Behavior row",
          "data-vertical": "Behaviors"
        }
      },
      [
        _c("div", { staticClass: "row gutters flex-row" }, [
          _c("div", { staticClass: "col span-5" }, [
            _c(
              "div",
              { staticClass: "row gutters" },
              [
                _vm.row.student
                  ? _c("StudentAvatar", {
                      staticClass: "col span-24",
                      attrs: { student: _vm.row.student }
                    })
                  : _c(
                      "div",
                      {
                        staticClass: "col span-24",
                        class: {
                          invalid: _vm.row.validationErrors["students"]
                        },
                        attrs: { title: _vm.row.validationErrors["students"] }
                      },
                      [
                        _c("StudentAndGroupSelect", {
                          attrs: {
                            tabindex: _vm.row.index * 10000 + 1,
                            multiple: false,
                            value: _vm.row.selectedStudentsAndStudentGroups,
                            initialStudentOptions: _vm.allStudents,
                            initialStudentGroupOptions: _vm.allStudentGroups,
                            input: _vm.handleStudentsAndStudentGroupsChange,
                            addAndClear: true,
                            placeholder: _vm.getText("students_title")
                          }
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.studentsOrGroupsToShow, function(
                          studentOrGroup,
                          index
                        ) {
                          return _c(
                            "div",
                            { staticClass: "removable-expandable" },
                            [
                              studentOrGroup.student_id &&
                              (!studentOrGroup.absent || _vm.showAbsentStudents)
                                ? [
                                    _c(
                                      "span",
                                      { staticClass: "overflow-ellipsis" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "/student/" +
                                                studentOrGroup.student_id,
                                              tooltip: "",
                                              tabindex: "-1",
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  studentOrGroup.display_name +
                                                    (studentOrGroup.absent
                                                      ? " [" +
                                                        _vm.absenceCode(
                                                          studentOrGroup
                                                        ) +
                                                        "]"
                                                      : "")
                                                ) +
                                                "\n                                    "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "icon-remove",
                                      attrs: { title: "Remove" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeStudentOrGroup(index)
                                        }
                                      }
                                    })
                                  ]
                                : studentOrGroup.student_group_id
                                ? [
                                    _c(
                                      "span",
                                      { staticClass: "overflow-ellipsis" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "/studentgroup/" +
                                                studentOrGroup.student_group_id,
                                              title:
                                                studentOrGroup.group_name +
                                                _vm.getGroupNumStudentsDesc(
                                                  studentOrGroup
                                                ),
                                              tabindex: "-1",
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  studentOrGroup.group_name +
                                                    _vm.getGroupNumStudentsDesc(
                                                      studentOrGroup
                                                    )
                                                ) +
                                                "\n                                    "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        !(
                                          studentOrGroup.student_group_id in
                                          _vm.studentGroupStudents
                                        )
                                          ? _c("i", {
                                              staticClass:
                                                "icon-spinner icon-spin"
                                            })
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    studentOrGroup.student_group_id in
                                    _vm.studentGroupStudents
                                      ? _c("i", {
                                          staticClass: "icon-fullscreen",
                                          attrs: {
                                            title:
                                              "Expand and list all " +
                                              _vm.getText("students_lower") +
                                              " separately"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.expandGroup(
                                                studentOrGroup
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "icon-remove",
                                      attrs: { title: "Remove" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeStudentOrGroup(index)
                                        }
                                      }
                                    })
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        }),
                        _vm._v(" "),
                        this.row.studentsOrGroupsToShowLimit &&
                        this.row.studentsAndStudentGroups.length >
                          this.row.studentsOrGroupsToShowLimit
                          ? _c("div", [
                              _c(
                                "a",
                                {
                                  staticClass: "show-more",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.clearStudentsOrGroupsToShowLimit(
                                        $event
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                show " +
                                      _vm._s(
                                        this.row.studentsAndStudentGroups
                                          .length -
                                          this.row.studentsOrGroupsToShowLimit
                                      ) +
                                      " more\n                            "
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ],
                      2
                    )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col span-6" },
            [
              _c("div", { staticClass: "row gutters" }, [
                _c(
                  "div",
                  {
                    staticClass: "col span-24",
                    class: {
                      invalid: _vm.row.validationErrors["behavior_types"]
                    },
                    attrs: { title: _vm.row.validationErrors["behavior_types"] }
                  },
                  [
                    _c("BehaviorTypeSelect", {
                      attrs: {
                        tabindex: _vm.row.index * 10000 + 2,
                        multiple: true,
                        value: _vm.row.behaviorTypes,
                        initialOptions: _vm.behaviorTypeOptions,
                        input: _vm.handleBehaviorTypesChange
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              !_vm.allLocations || _vm.allLocations.length
                ? _c("div", { staticClass: "row gutters" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col span-24",
                        class: {
                          invalid: _vm.row.validationErrors["location"]
                        },
                        attrs: { title: _vm.row.validationErrors["location"] }
                      },
                      [
                        _c("LocationSelect", {
                          attrs: {
                            tabindex: _vm.row.index * 10000 + 5,
                            value: _vm.row.location,
                            initialOptions: _vm.allLocations,
                            placeholder: "Location",
                            input: function(obj) {
                              return _vm.handleRowChange({ location: obj })
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.attrsToShow, function(attr, i) {
                return _c("div", { key: i, staticClass: "row gutters" }, [
                  attr.demerit_attribute_values.length
                    ? _c(
                        "div",
                        {
                          staticClass: "col span-24",
                          class: {
                            invalid:
                              _vm.row.validationErrors.attrs &&
                              _vm.row.validationErrors.attrs[
                                attr.demerit_attribute_type_id
                              ]
                          },
                          attrs: {
                            title: _vm.row.validationErrors.attrs
                              ? _vm.row.validationErrors.attrs[
                                  attr.demerit_attribute_type_id
                                ]
                              : ""
                          }
                        },
                        [
                          _c("DemeritAttributeValueSelect", {
                            attrs: {
                              tabindex: _vm.row.index * 10000 + 7 + i,
                              value:
                                _vm.row.attrs[attr.demerit_attribute_type_id],
                              initialOptions: attr.demerit_attribute_values,
                              multiple: attr.multiple == 1,
                              placeholder: attr.display_name,
                              input: function(dav) {
                                return _vm.handleAttrChange(
                                  attr.demerit_attribute_type_id,
                                  dav
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col span-3" }, [
            _c("div", { staticClass: "row gutters" }, [
              _c(
                "div",
                {
                  staticClass: "col span-24",
                  class: { invalid: _vm.row.validationErrors["multiplier"] },
                  attrs: { title: _vm.row.validationErrors["multiplier"] }
                },
                [
                  _c("input", {
                    attrs: {
                      type: "number",
                      tabindex: _vm.row.index * 10000 + 3,
                      disabled: !_vm.showMultiplier,
                      placeholder: "Amount"
                    },
                    domProps: { value: _vm.row.multiplier },
                    on: {
                      change: function(e) {
                        return _vm.handleRowChange({
                          multiplier: e.target.value
                        })
                      }
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row gutters" }, [
              _c(
                "div",
                {
                  staticClass: "col span-24",
                  class: { invalid: _vm.row.validationErrors["time"] },
                  attrs: { title: _vm.row.validationErrors["time"] }
                },
                [
                  _vm.getSetting("show_demerit_time") == 1
                    ? _c("input", {
                        attrs: {
                          tabindex: _vm.row.index * 10000 + 6,
                          type: "time",
                          placeholder: "Time"
                        },
                        domProps: { value: _vm.row.time },
                        on: {
                          change: function(e) {
                            return _vm.handleRowChange({ time: e.target.value })
                          }
                        }
                      })
                    : _c("span", [
                        _vm._v(
                          "\n                             \n                        "
                        )
                      ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col span-10 flex-row flex-one" }, [
            _c("div", { staticClass: "row gutters flex-row flex-one" }, [
              _c(
                "div",
                {
                  staticClass: "col span-24 flex-row flex-one",
                  class: { invalid: _vm.row.validationErrors["comments"] },
                  attrs: { title: _vm.row.validationErrors["comments"] }
                },
                [
                  _c("textarea", {
                    staticClass: "comments",
                    attrs: {
                      tabindex: _vm.row.index * 10000 + 4,
                      placeholder: "Comments"
                    },
                    domProps: { value: _vm.row.comments },
                    on: {
                      change: function(e) {
                        return _vm.handleRowChange({ comments: e.target.value })
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-popover",
                    {
                      key: "popover-" + _vm.row.index,
                      staticClass: "dropdown",
                      attrs: {
                        placement: "bottom-end",
                        trigger: "click",
                        width: 205,
                        "visible-arrow": false,
                        transition: "none",
                        "popper-options": { boundariesElement: "body" },
                        "popper-class":
                          "dropdown dropdown-right dropdown-menu open"
                      },
                      model: {
                        value: _vm.popoverVisible,
                        callback: function($$v) {
                          _vm.popoverVisible = $$v
                        },
                        expression: "popoverVisible"
                      }
                    },
                    [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "menu-action",
                            on: {
                              click: function($event) {
                                ;(_vm.popoverVisible = false),
                                  _vm.handleAddRow(_vm.row.index)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "icon-plus" }),
                            _vm._v("Add Row\n                                ")
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "menu-action",
                            on: {
                              click: function($event) {
                                ;(_vm.popoverVisible = false),
                                  _vm.handleCopyRow(_vm.row.index)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "icon-copy" }),
                            _vm._v("Copy Row\n                                ")
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "menu-action",
                            on: {
                              click: function($event) {
                                ;(_vm.popoverVisible = false),
                                  _vm.handleFillDown(_vm.row.index)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "icon-arrow-down" }),
                            _vm._v(
                              "Fill Down Row\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "menu-action",
                            on: {
                              click: function($event) {
                                ;(_vm.popoverVisible = false),
                                  _vm.handleFillCommentsDown(_vm.row.index)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "icon-comments-alt" }),
                            _vm._v(
                              "Fill Down Comments Only\n                                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "menu-action",
                            on: {
                              click: function($event) {
                                ;(_vm.popoverVisible = false),
                                  _vm.handleRemoveRow(_vm.row.index)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "icon-remove" }),
                            _vm._v(
                              "Remove Row\n                                "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "icon-cog",
                        attrs: { slot: "reference" },
                        slot: "reference"
                      })
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }