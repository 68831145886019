<template>
    <div id="lms-analysis-app">
        <h1>
            Engage
        </h1>
        <el-tabs v-if="!loading"
            :value="showVideo ? 'video' : 'lms'"
        >
            <el-tab-pane v-if="showVideo"
                label="Video"
                name="video"
            >
                <VideoPageControls />
                <VideoChart
                    :zoom-client-id="zoom_client_id"
                    :zoom-redirect-uri="zoom_redirect_uri"
                />
            </el-tab-pane>
            <el-tab-pane v-if="showLms"
                label="LMS"
                name="lms"
                :lazy="true"
            >
                <LmsPageControls />
                <LmsChart
                    :edlink-client-id="edlink_client_id"
                    :user-email="logged_in_user_email"
                />
            </el-tab-pane>
        </el-tabs>
        <EmptyState v-if="!showVideo && !showLms"
            title="Video and LMS integrations not enabled"
        />
    </div>
</template>

<script>
import _ from 'tss/lodash'
import moment from 'moment-timezone'
import { mapActions, mapState, mapGetters } from 'vuex'
import EmptyState from 'shared/Loaders/EmptyState.vue'
import LmsPageControls from './LmsPageControls.vue'
import LmsChart from './LmsChart.vue'
import VideoPageControls from './VideoPageControls.vue'
import VideoChart from './VideoChart.vue'

export default {
    // components
    // props
    // data
    // computed
    // watch
    // LIFECYCLE_HOOKS
    // methods
    components: {
        EmptyState,
        LmsPageControls,
        LmsChart,
        VideoPageControls,
        VideoChart,
    },

    props: [
        'school_id',
        'logged_in_user_id',
        'logged_in_user_email',
        'edlink_client_id',
        'zoom_client_id',
        'zoom_redirect_uri',
        'min_date',
        'max_date',
        'text',
        'settings',
        'auth',
    ],

    computed: {
        ...mapState([
            'loading',
        ]),
        lmsAnalysisSetting() {
            return _.get(this.settings, 'lms_analysis')
                || 'lms,video'
        },
        showVideo() {
            return (this.lmsAnalysisSetting || '')
                .split(',')
                .includes('video')
        },
        showLms() {
            return (this.lmsAnalysisSetting || '')
                .split(',')
                .includes('lms')
        },
    },

    async mounted() {
        this.setSchool(this.school_id)
        this.setLoggedInUserId(this.logged_in_user_id)
        this.setMinDate(this.min_date)
        this.setMaxDate(this.max_date)
        this.setTextReplacements(this.text)
        await this.loadSchools()
        await this.loadLmsSchools()
        this.setLoading(false)
        this.setAuth(this.auth)
        this.setSettings(this.settings)
    },

    methods: {
        ...mapActions([
            'setLoading',
            'setSchool',
            'setLoggedInUserId',
            'setMinDate',
            'setMaxDate',
            'setTextReplacements',
            'setAuth',
            'setSettings',
            'loadLmsSchools',
            'loadSchools',
        ]),
    },
}
</script>