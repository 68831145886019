<template>
    <TssSelect
        label="display_name"
        v-model="selected"
        :options="options"
        :loading="isLoading"
        :multiple="multiple"
        :tabindex="tabindex"
        :disabled="disabled"
        :dropdownPlaceholder="placeholder">
    </TssSelect>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import _ from 'tss/lodash'
import TssSelect from '../TssSelect.vue'
import StaffMemberService from 'services/StaffMember'

export default {
    components: {
        TssSelect,
    },
    data() {
        return {
            options: [],
            isLoading: true,
        }
    },
    props: [
        'input',
        'value',
        'initialOptions',
        'multiple',
        'tabindex',
        'placeholder',
        'disabled',
    ],
    computed: {
        ...mapState([
            'schoolId',
        ]),
        selected: {
            get() {
                return this.value
            },
            set(value) {
                this.input(value)
            }
        },
    },
    mounted: function() {
        if (this.initialOptions === undefined) { // prop not passed, do load
            this.getStaffMembers()
                .then(this.formatAndSetOptions)
        } else if (this.initialOptions) { // prop passed as non-null, set options
            this.formatAndSetOptions(this.initialOptions)
        } // else prop passed as null, wait for prop to update/watch to set options
    },
    methods: {
        getStaffMembers() {
            this.isLoading = true
            return StaffMemberService.get({
                active: 1,
                school_ids: this.schoolId
            })
        },
        formatAndSetOptions(records) {
            let formattedRecords = this.formatForSelect(records)
            let sortedOptions = this.sortOptions(formattedRecords)
            
            this.setOptions(sortedOptions)
        },
        formatForSelect(records) {
            return records
        },
        sortOptions(records) {
            return _.sortBy(records, 'display_name')
        },
        setOptions(sortedRecords) {
            this.options = sortedRecords
            this.isLoading = false
        },
    },
    watch: {
        initialOptions: function(newVal, oldVal) {
            this.formatAndSetOptions(newVal)
        },
    },
}
</script>