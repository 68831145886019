<template>
    <TssSelect
        label="display_name"
        v-model="selected"
        :options="options"
        :loading="isLoading"
        :multiple="multiple"
        :tabindex="tabindex"
        :disabled="disabled"
        :dropdownPlaceholder="placeholder"
        :addAndClear="addAndClear">
    </TssSelect>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import _ from 'tss/lodash'
import TssSelect from '../TssSelect.vue'
import StudentService from 'services/Student'
import StudentGroupService from 'services/StudentGroup'

export default {
    components: {
        TssSelect,
    },
    data() {
        return {
            options: [],
            studentOptions: null,
            studentGroupOptions: null,
            isLoading: true,
        }
    },
    props: [
        'input',
        'value',
        'initialStudentOptions',
        'initialStudentGroupOptions',
        'multiple',
        'tabindex',
        'placeholder',
        'disabled',
        'addAndClear'
    ],
    computed: {
        ...mapState([
            'schoolId',
            'loggedInUserId',
        ]),
        selected: {
            get() {
                return this.value
            },
            set(value) {
                this.input(value)
            }
        },
    },
    mounted: function() {
        if (this.initialStudentOptions === undefined) { // prop not passed, do load
            this.getStudents()
                .then(this.formatAndSetStudentOptions)
        } else if (this.initialStudentOptions) { // prop passed as non-null, set options
            this.formatAndSetStudentOptions(this.initialStudentOptions)
        } // else prop passed as null, wait for prop to update/watch to set options

        if (this.initialStudentGroupOptions === undefined) { // prop not passed, do load
            this.getStudentGroups()
                .then(this.formatAndSetStudentGroupOptions)
        } else if (this.initialStudentGroupOptions) { // prop passed as non-null, set options
            this.formatAndSetStudentGroupOptions(this.initialStudentGroupOptions)
        } // else prop passed as null, wait for prop to update/watch to set options
    },
    methods: {
        getStudents() {
            return StudentService.get({
                active: 1,
                school_ids: this.schoolId,
            })
        },
        formatAndSetStudentOptions(records) {
            let formattedRecords = this.formatStudentsForSelect(records)
            let sortedOptions = this.sortStudentOptions(formattedRecords)

            this.studentOptions = sortedOptions
            this.setOptions()
        },
        formatStudentsForSelect(records) {
            return records.map(record => {
                return _.extend(record, {
                    optgroup: record.optgroup || this.placeholder,
                })
            })
        },
        sortStudentOptions(records) {
            return _.sortBy(records, ['order_key', 'optgroup', 'display_name'])
        },
        getStudentGroups() {
            const studentGroupParams = {
                active: 1,
                school_ids: this.schoolId,
                user_id: this.loggedInUserId,
            }
            return StudentGroupService.getStudentGroupsByGradeLevel(studentGroupParams)
        },
        formatAndSetStudentGroupOptions(records) {
            let formattedRecords = this.formatStudentGroupsForSelect(records)
            let sortedOptions = this.sortStudentGroupOptions(formattedRecords)

            this.studentGroupOptions = sortedOptions
            this.setOptions()
        },
        formatStudentGroupsForSelect(records) {
            return records
        },
        sortStudentGroupOptions(records) {
            return records
        },
        setOptions() {
            if (this.studentOptions === null
                    || this.studentGroupOptions === null) {
                return // wait till both are done
            }

            this.options = []
                .concat(this.studentOptions)
                .concat(this.studentGroupOptions)
            this.isLoading = false
        },
    },
    watch: {
        initialStudentOptions: function(newVal, oldVal) {
            this.formatAndSetStudentOptions(newVal)
        },
        initialStudentGroupOptions: function(newVal, oldVal) {
            this.formatAndSetStudentGroupOptions(newVal)
        },
    },
}
</script>