var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "lms-analysis-app" } },
    [
      _c("h1", [_vm._v("\n        Engage\n    ")]),
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "el-tabs",
            { attrs: { value: _vm.showVideo ? "video" : "lms" } },
            [
              _vm.showVideo
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "Video", name: "video" } },
                    [
                      _c("VideoPageControls"),
                      _vm._v(" "),
                      _c("VideoChart", {
                        attrs: {
                          "zoom-client-id": _vm.zoom_client_id,
                          "zoom-redirect-uri": _vm.zoom_redirect_uri
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showLms
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "LMS", name: "lms", lazy: true } },
                    [
                      _c("LmsPageControls"),
                      _vm._v(" "),
                      _c("LmsChart", {
                        attrs: {
                          "edlink-client-id": _vm.edlink_client_id,
                          "user-email": _vm.logged_in_user_email
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.showVideo && !_vm.showLms
        ? _c("EmptyState", {
            attrs: { title: "Video and LMS integrations not enabled" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }