var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "behavior-modal-app" } },
    [
      Object.keys(_vm.saveProgress).length
        ? _c("TssProgressBar", {
            attrs: { rows: _vm.saveProgress, header: "Saving Progress" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }