<template>
    <transition name="fade">
        <section
            v-show="row.visible"
            :id="`behavior_row_${row.index}`"
            :key="`behavior_row_${row.index}`"
            class="behavior-row"
            :class="{ odd: row.visibleIndex % 2 != 0, student: !!row.student }"
            :name="rowName"
            data-module="Behavior row"
            data-vertical="Behaviors">
            <div class="row gutters flex-row">
                <div class="col span-5">
                    <div class="row gutters">
                        <StudentAvatar
                            v-if="row.student"
                            :student="row.student"
                            class="col span-24"
                        />
                        <div
                            v-else
                            class="col span-24"
                            :class="{ invalid: row.validationErrors['students'] }"
                            :title="row.validationErrors['students']"
                        >
                            <StudentAndGroupSelect
                                :tabindex="(row.index * 10000) + 1"
                                :multiple="false"
                                :value="row.selectedStudentsAndStudentGroups"
                                :initialStudentOptions="allStudents"
                                :initialStudentGroupOptions="allStudentGroups"
                                :input="handleStudentsAndStudentGroupsChange"
                                :addAndClear="true"
                                :placeholder="getText('students_title')"
                            />
                            <div v-for="(studentOrGroup, index) in studentsOrGroupsToShow"
                                class="removable-expandable"
                                >
                                <template v-if="studentOrGroup.student_id && (!studentOrGroup.absent || showAbsentStudents)">
                                    <span class="overflow-ellipsis">
                                        <a :href="`/student/${studentOrGroup.student_id}`" tooltip tabindex="-1" target="_blank">
                                            {{studentOrGroup.display_name + (studentOrGroup.absent ? ' [' + absenceCode(studentOrGroup) + ']' : '')}}
                                        </a>
                                    </span>
                                    <i @click="removeStudentOrGroup(index)"
                                        title="Remove"
                                        class="icon-remove"></i>
                                </template>
                                <template v-else-if="studentOrGroup.student_group_id">
                                    <span class="overflow-ellipsis">
                                        <a :href="`/studentgroup/${studentOrGroup.student_group_id}`"
                                            :title="studentOrGroup.group_name + getGroupNumStudentsDesc(studentOrGroup)"
                                            tabindex="-1"
                                            target="_blank"
                                        >
                                            {{studentOrGroup.group_name + getGroupNumStudentsDesc(studentOrGroup)}}
                                        </a>
                                        <i v-if="!(studentOrGroup.student_group_id in studentGroupStudents)" class="icon-spinner icon-spin"></i>
                                    </span>
                                    <i v-if="studentOrGroup.student_group_id in studentGroupStudents"
                                        @click="expandGroup(studentOrGroup)"
                                        class="icon-fullscreen"
                                        :title="'Expand and list all ' + getText('students_lower') + ' separately'"></i>
                                    <i @click="removeStudentOrGroup(index)"
                                        title="Remove"
                                        class="icon-remove"></i>
                                </template>
                            </div>
                            <div v-if="this.row.studentsOrGroupsToShowLimit && this.row.studentsAndStudentGroups.length > this.row.studentsOrGroupsToShowLimit">
                                <a @click.prevent="clearStudentsOrGroupsToShowLimit" class="show-more">
                                    show {{this.row.studentsAndStudentGroups.length - this.row.studentsOrGroupsToShowLimit}} more
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col span-6">
                    <div class="row gutters">
                        <div class="col span-24"
                                :class="{ invalid: row.validationErrors['behavior_types'] }"
                                :title="row.validationErrors['behavior_types']">
                            <BehaviorTypeSelect
                                :tabindex="(row.index * 10000) + 2"
                                :multiple="true"
                                :value="row.behaviorTypes"
                                :initialOptions="behaviorTypeOptions"
                                :input="handleBehaviorTypesChange"/>
                        </div>
                    </div>

                    <div v-if="!allLocations || allLocations.length"
                            class="row gutters">
                        <div class="col span-24"
                                :class="{ invalid: row.validationErrors['location'] }"
                                :title="row.validationErrors['location']">
                            <LocationSelect
                                :tabindex="(row.index * 10000) + 5"
                                :value="row.location"
                                :initialOptions="allLocations"
                                placeholder="Location"
                                :input="obj => handleRowChange({ location: obj })"/>
                        </div>
                    </div>

                    <div v-for="(attr, i) in attrsToShow" :key="i" class="row gutters">
                        <!-- if no active attrs don't show dropdown -->
                        <!-- otherwise you may get stuck with a required field -->
                        <!-- that has no options -->
                        <div v-if="attr.demerit_attribute_values.length" class="col span-24"
                                :class="{ invalid: row.validationErrors.attrs && row.validationErrors.attrs[attr.demerit_attribute_type_id] }"
                                :title="row.validationErrors.attrs ? row.validationErrors.attrs[attr.demerit_attribute_type_id] : ''">
                            <DemeritAttributeValueSelect
                                :tabindex="(row.index * 10000) + 7 + i"
                                :value="row.attrs[attr.demerit_attribute_type_id]"
                                :initialOptions="attr.demerit_attribute_values"
                                :multiple="attr.multiple == 1"
                                :placeholder="attr.display_name"
                                :input="dav => handleAttrChange(attr.demerit_attribute_type_id, dav)"/>
                        </div>
                    </div>
                </div>

                <div class="col span-3">
                    <div class="row gutters">
                        <div class="col span-24"
                                :class="{ invalid: row.validationErrors['multiplier'] }"
                                :title="row.validationErrors['multiplier']">
                            <input
                                type="number"
                                :tabindex="(row.index * 10000) + 3"
                                :disabled="!showMultiplier"
                                :value="row.multiplier"
                                placeholder="Amount"
                                @change="e => handleRowChange({ multiplier: e.target.value })"/>
                        </div>
                    </div>

                    <div class="row gutters">
                        <div class="col span-24"
                                :class="{ invalid: row.validationErrors['time'] }"
                                :title="row.validationErrors['time']">
                            <input v-if="getSetting('show_demerit_time') == 1"
                                :tabindex="(row.index * 10000) + 6"
                                type="time"
                                :value="row.time"
                                placeholder="Time"
                                @change="e => handleRowChange({ time: e.target.value })"/>
                            <span v-else>
                                &nbsp;
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col span-10 flex-row flex-one">
                    <div class="row gutters flex-row flex-one">
                        <div class="col span-24 flex-row flex-one"
                                :class="{ invalid: row.validationErrors['comments'] }"
                                :title="row.validationErrors['comments']">
                            <textarea
                                :tabindex="(row.index * 10000) + 4"
                                class="comments"
                                :value="row.comments"
                                placeholder="Comments"
                                @change="e => handleRowChange({ comments: e.target.value })"/>
                            <el-popover
                                :key="`popover-${row.index}`"
                                class="dropdown"
                                placement="bottom-end"
                                trigger="click"
                                v-model="popoverVisible"
                                :width="205"
                                :visible-arrow="false"
                                transition="none"
                                :popper-options="{ boundariesElement: 'body' }"
                                popper-class="dropdown dropdown-right dropdown-menu open">
                                <div>
                                    <div class="menu-action"
                                        @click="popoverVisible = false, handleAddRow(row.index)">
                                        <i class="icon-plus"></i>Add Row
                                    </div>
                                    <div class="menu-action"
                                        @click="popoverVisible = false, handleCopyRow(row.index)">
                                        <i class="icon-copy"></i>Copy Row
                                    </div>
                                    <div class="menu-action"
                                        @click="popoverVisible = false, handleFillDown(row.index)">
                                        <i class="icon-arrow-down"></i>Fill Down Row
                                    </div>
                                    <div class="menu-action"
                                        @click="popoverVisible = false, handleFillCommentsDown(row.index)">
                                        <i class="icon-comments-alt"></i>Fill Down Comments Only
                                    </div>
                                    <div class="menu-action"
                                        @click="popoverVisible = false, handleRemoveRow(row.index)">
                                        <i class="icon-remove"></i>Remove Row
                                    </div>
                                </div>
                                <i slot="reference" class="icon-cog"></i>
                            </el-popover>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </transition>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { shallowDiff } from './store/helpers'
import _ from 'tss/lodash'
import LocationSelect from 'shared/Selects/LocationSelect.vue'
import BehaviorTypeSelect from 'shared/Selects/BehaviorTypeSelect.vue'
import DemeritAttributeValueSelect from 'shared/Selects/DemeritAttributeValueSelect.vue'
import StudentAndGroupSelect from 'shared/Selects/StudentAndGroupSelect.vue'
import StudentAvatar from 'shared/StudentAvatar.vue'

export default {
    data() {
        return {
            popoverVisible: false,
        }
    },

    components: {
        LocationSelect,
        BehaviorTypeSelect,
        DemeritAttributeValueSelect,
        StudentAndGroupSelect,
        StudentAvatar,
    },

    props: [
        'row',
    ],

    computed: {
        ...mapState([
            'allStudents',
            'allStudentGroups',
            'allLocations',
            'students',
            'showAbsentStudents',
            'studentGroupStudents',
        ]),
        ...mapGetters([
            'behaviorTypesForDisplay',
            'demeritAttrsForDisplay',
            'getText',
            'getSetting',
        ]),
        showMultiplier() {
            let showMult = !!(_.filter(this.row.behaviorTypes, { show_multiplier: '1' }).length 
            && (this.row.behaviorTypes.length && this.row.behaviorTypes[0].show_multiplier == 1))

            if (!showMult && this.row.multiplier != 1) {
                this.handleRowChange({ multiplier: 1 })
            }

            return showMult
        },
        behaviorTypeOptions() {
            return this.behaviorTypesForDisplay()
        },
        /**
         * @return array [i => DemeritAttributeType]
         * active attributes
         */
        attrsToShow() {
            let demeritTypeIds = _.map(this.row.behaviorTypes, o => o.demerit_type_id)
            let attrTypes = this.demeritAttrsForDisplay(this.behavior)

            let genericAttrs = _.filter(attrTypes,
                attr => !attr.demerit_type_id // e.g. core values, etc.
            )
            let behaviorAttrs = _.map(demeritTypeIds,
                demerit_type_id => _.find(attrTypes, { demerit_type_id })
            )
            let attrs = _.filter((genericAttrs || []).concat(behaviorAttrs))
            let newRowAttrs = _.pick(this.row.attrs, _.map(attrs, 'demerit_attribute_type_id'))
            let diff = shallowDiff(newRowAttrs, this.row.attrs)

            // keep row attrs consistent with which attrs are actually visible
            if (diff.length) {
                this.handleRowChange({ 'attrs': newRowAttrs })
            }

            return attrs
        },
        rowName() {
            if (this.row.student) {
                let studentIndex = _.indexOf(this.students, this.row.student)

                return `behavior_student_row_${studentIndex}`
            }

            return `behavior_blank_row_${this.row.index}`
        },
        studentsOrGroupsToShow() {
            return this.row.studentsOrGroupsToShowLimit
                ? this.row.studentsAndStudentGroups.slice(0, this.row.studentsOrGroupsToShowLimit)
                : this.row.studentsAndStudentGroups
        }
    },

    methods: {
        ...mapActions([
            'setStudentGroup',
            'setDate',
            'setStaffMember',
            'handleAddRow',
            'handleCopyRow',
            'handleRemoveRow',
            'handleFillDown',
            'handleFillCommentsDown',
            'updateRow',
            'updateStudentGroupStudents',
        ]),

        handleAttrChange: function(demeritAttributeTypeId, demeritAttributeValue) {
            let attrs = _.extend({}, this.row.attrs)

            attrs[demeritAttributeTypeId] = demeritAttributeValue

            this.handleRowChange({ attrs })
        },

        handleRowChange: function(changes) {
            this.updateRow({
                changes,
                index: this.row.index,
            })
        },

        handleStudentsAndStudentGroupsChange: function(studentOrStudentGroup) {
            // Reset limit if we ever dip below the original limit, even if we
            // manually clicked the show more link to remove the limit
            const defaultLimit = 20

            if (!studentOrStudentGroup) {
                return
            }

            let studentsAndStudentGroups = [].concat(this.row.studentsAndStudentGroups).concat([studentOrStudentGroup])

            if (!this.row.studentsOrGroupsToShowLimit
                    && studentsAndStudentGroups.length < defaultLimit
                    && this.row.studentsAndStudentGroups.length > defaultLimit) {
                this.handleRowChange({
                    studentsOrGroupsToShowLimit: defaultLimit,
                })
            }

            this.handleRowChange({
                selectedStudentsAndStudentGroups: studentOrStudentGroup,
            })

            if (!studentsAndStudentGroups.length) {
                this.handleRowChange({
                    absentStudentGroupStudents: [],
                })
            }

            this.updateStudentGroupStudents({
                studentsAndStudentGroups,
                index: this.row.index
            })

            this.$nextTick(() => {
                this.handleRowChange({
                    selectedStudentsAndStudentGroups: null,
                })
            })
        },

        handleBehaviorTypesChange: function(behaviorTypes) {
            this.handleRowChange({ behaviorTypes })

            // set default values based on the selected behaviors
            if (behaviorTypes) {
                _.each(behaviorTypes, behaviorType => {
                    _.chain(behaviorType.demerit_default_attribute_values)
                        .filter(ddav => ddav.active == 1)
                        .each(ddav => {
                            let dav = ddav.demerit_attribute_value

                            this.handleAttrChange(dav.demerit_attribute_type_id, dav)
                        })
                        .value()
                })
            }
        },

        absenceCode: function(student) {
            return _.get(student, 'absence.absence_type.code', '')
        },

        getGroupNumStudentsDesc: function(studentGroup) {
            if (!(studentGroup.student_group_id in this.studentGroupStudents)) {
                return ''
            }

            const allStudents = this.studentGroupStudents[studentGroup.student_group_id]
            const absentStudents = _.filter(allStudents, 'absent')
            const numAbsentStudents = absentStudents.length
            const numPresentStudents = allStudents.length - numAbsentStudents
            const desc = this.showAbsentStudents
                ? allStudents.length
                : numPresentStudents

            return ` (${desc})`
        },

        removeStudentOrGroup: function(index) {
            let studentsAndStudentGroups = [...this.row.studentsAndStudentGroups]
            let studentOrGroup = studentsAndStudentGroups[index]
            let absentStudentGroupStudents = _.without(this.row.absentStudentGroupStudents, studentOrGroup)

            studentsAndStudentGroups.splice(index, 1)

            this.updateStudentGroupStudents({
                absentStudentGroupStudents,
                studentsAndStudentGroups,
                index: this.row.index
            })
        },

        expandGroup: function(studentGroup) {
            const allStudents = this.studentGroupStudents[studentGroup.student_group_id]
            const absentStudents = allStudents.filter(s => s.absent)
            const presentStudents = allStudents.filter(s => !s.absent)
            const studentsToInsert = this.showAbsentStudents
                ? allStudents
                : presentStudents
            const absentStudentGroupStudents = [...this.row.absentStudentGroupStudents, ...absentStudents]
            const insertIndex = _.indexOf(this.row.studentsAndStudentGroups, studentGroup)
            let studentsAndStudentGroups = _.without(this.row.studentsAndStudentGroups, studentGroup)

            studentsAndStudentGroups.splice(insertIndex, 0, ...studentsToInsert)

            this.handleRowChange({
                absentStudentGroupStudents,
                studentsAndStudentGroups,
            })
        },

        clearStudentsOrGroupsToShowLimit: function() {
            this.handleRowChange({
                studentsOrGroupsToShowLimit: 0,
            })
        }
    },

    watch: {
        showAbsentStudents: function(newVal, oldVal) {
            let studentsAndStudentGroups
            let students

            if (newVal) {
                studentsAndStudentGroups = [...this.row.studentsAndStudentGroups, ...this.row.absentStudentGroupStudents]
                students = [...this.row.students, ...this.row.absentStudentGroupStudents]
            } else {
                studentsAndStudentGroups = _.without(this.row.studentsAndStudentGroups, ...this.row.absentStudentGroupStudents)
                students = _.without(this.row.students, ...this.row.absentStudentGroupStudents)
            }

            this.handleRowChange({
                students,
                studentsAndStudentGroups,
            })
        },
    }
}
</script>

<style lang="scss">
.behavior-row {
    position: relative;

    input.invalid,
    .invalid input {
        border: 2px solid $sr-red !important;
    }

    &:not(.odd) {
        background-color: $sr-gray-09;
    }

    .section-textarea {
        height: 100%;
        padding: 10px ($g-pad / 2) 0;
        resize: vertical;
        margin-right: 15px;
    }

    .row.gutters:not(:first-child) {
        padding-top: 5px;
    }

    i.icon-cog {
        cursor: pointer;
        position: absolute;
        right: -11px;
        top: 0px;
        @extend .transition-fast;
        @include fontSize($size-body-large, $size-body-large);
        color: $sr-gray-04;
        &:hover {
            color: $sr-gray-03;
        }
    }

    .btn-group {
        button {
            // margin-left: 0px !important;
            margin-right: 2px;
            &:last-child {
                margin-right: 0px;
            }

            margin-top: 0px;
            background: $sr-gray-07;
            width: 48px !important;

            &:hover,
            &.active {
                background: $sr-gray-03;

                i,
                i:hover {
                    color: $white;
                }
            }

            &.active {
                background: $sr-blue;

                i,
                i:hover {
                    color: $white;
                }
            }

            &.disabled {
                background: $sr-gray-07 !important;

                i,
                i:hover {
                    color: $sr-gray-03;
                }
            }

            i,
            i:hover {
                color: $sr-gray-03;
            }
        }
    }

    .show-more {
        padding: 0 8px;
    }

    .removable-expandable {
        display: flex;
        align-items: center;
        padding: 0 8px;

        :first-child {
            flex: 1;
        }

        &:hover {
            background: $sr-gray-08 !important;
        }

        &:nth-child(odd) {
            background: white;
        }

        &:nth-child(even) {
            background: $sr-gray-09;
        }

        i + i {
            margin-left: 10px;
        }

        .icon-remove,
        .icon-fullscreen {
            cursor: pointer;

            &:hover {
                color: $sr-gray-03;
            }
        }

        .icon-remove {
            font-size: 1.3em;
        }
    }
}
</style>