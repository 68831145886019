var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "student-credentials-app" } },
    [
      _vm.loading
        ? _c("SpencilLoader")
        : _c(
            "div",
            [
              _c(
                "el-collapse",
                {
                  staticClass: "credential-sections",
                  model: {
                    value: _vm.activeSections,
                    callback: function($$v) {
                      _vm.activeSections = $$v
                    },
                    expression: "activeSections"
                  }
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "Student Access", name: "Student" } },
                    [
                      _c("div", { staticClass: "row gutters no-padding" }, [
                        _c(
                          "div",
                          {
                            staticClass: "btn",
                            class: { disabled: _vm.downloadingStudent },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.downloadStudentCredentials($event)
                              }
                            }
                          },
                          [
                            _vm.studentUserExists
                              ? _c("i", { staticClass: "icon-download-alt" })
                              : _vm._e(),
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.downloadingStudent
                                    ? "Downloading..."
                                    : "Download Login Instructions"
                                ) +
                                "\n                    "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row gutters no-padding" },
                        [
                          _vm.accessCode
                            ? _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.includeGuardian,
                                    callback: function($$v) {
                                      _vm.includeGuardian = $$v
                                    },
                                    expression: "includeGuardian"
                                  }
                                },
                                [
                                  _vm._v(
                                    "Include Parent/Guardian Login Instructions"
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-collapse-item",
                    {
                      attrs: {
                        title: "Parent/Guardian Access",
                        name: "Guardian"
                      }
                    },
                    [
                      _c("div", { staticClass: "row gutters no-padding" }, [
                        _c(
                          "div",
                          {
                            staticClass: "btn",
                            class: { disabled: _vm.generatingAccessCode },
                            on: { click: _vm.showStudentAccessCodeModal }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.accessCodeModalText) +
                                "\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.accessCode
                          ? _c(
                              "div",
                              {
                                staticClass: "btn",
                                class: { disabled: _vm.downloadingGuardian },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.downloadGuardianCredentials(
                                      $event
                                    )
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "icon-download-alt" }),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.downloadingGuardian
                                        ? "Downloading..."
                                        : "Download Login Instructions"
                                    ) +
                                    "\n                    "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                { staticClass: "white" },
                [
                  _vm.guardians.length
                    ? _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.guardians,
                            "default-sort": {
                              prop: "user.display_name",
                              order: "ascending"
                            }
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "user.display_name",
                              label: "Parent/Guardian",
                              sortable: ""
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "user.email",
                              label: "Email",
                              sortable: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "mailto:" + scope.row.user.email
                                          }
                                        },
                                        [_vm._v(_vm._s(scope.row.user.email))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              722013885
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop:
                                "getGuardianStudentCreatedDate(guardian_students)",
                              label: "Paired With Student On",
                              sortable: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.getGuardianStudentCreatedDate(
                                              scope.row.guardian_students
                                            )
                                          ) +
                                          "\n                    "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1200594770
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "revoke", label: "Manage Access" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("i", {
                                        staticClass: "icon icon-pencil",
                                        on: {
                                          click: function($event) {
                                            return _vm.showGuardianAccessModal(
                                              scope.row
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1100902808
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }