<template>
    <span class="favorite-behaviors-list">
        <h3 class="text-center">Favorites</h3>

        <ul v-if="favoriteAndRecentBehaviors.length" class="scroll-wrapper">
            <li class="favorite-behavior-row"
                v-for="behavior in favoriteAndRecentBehaviors"
                :key="getKey(behavior)"
                :class="{selected: selected == behavior}"
            >
                <i :class="icon(behavior)"
                    class="red"
                    @click="e => favorite(behavior)">
                </i>
                
                <p @click="e => select(behavior)"
                    :title="getBehaviorDisplayName(behavior)"
                    class="overflow-ellipsis fav-header"
                    :class="{'no-sub-header': !(behavior.location_display_name || behavior.comments)}"
                >
                    {{ getBehaviorDisplayName(behavior) }}
                </p>
                <p v-if="behavior.location_display_name || behavior.comments"
                    class="overflow-ellipsis fav-sub-header"
                >
                    <span v-if="behavior.location_display_name">
                        {{ behavior.location_display_name }}
                    </span>
                    <span v-if="behavior.location_display_name && behavior.comments">
                        -
                    </span>
                    <span v-if="behavior.comments"
                        :title="behavior.comments"
                        class="fav-comments"
                    >
                        {{ behavior.comments }}
                    </span>
                </p>
            </li>
        </ul>
        <EmptyState v-else
            icon="icon-heart-empty"
            title="No Recent Behaviors"
            details="Recent behaviors will show up here once you've saved some records. Heart them to save as favorites for quick access.">
        </EmptyState>
    </span>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import EmptyState from 'shared/Loaders/EmptyState.vue'
import Vue from 'vue'

export default {
    components: {
        EmptyState,
    },
    data() {
        return {
            selected: null,
            savingKey: null,
        }
    },
    computed: {
        ...mapState([
            'favoriteBehaviors',
            'recentBehaviors',
        ]),

        favoriteAndRecentBehaviors() {
            return [].concat(this.favoriteBehaviors).concat(this.recentBehaviors)
        },
    },
    methods: {
        ...mapActions([
            'populateFromFavorite',
            'toggleFavoriteBehavior',
        ]),

        getKey(behavior) {
            return `${behavior.favorite_behavior_id}_${behavior.demerit_id}`
        },

        isFavorite(behavior) {
            return behavior.favorite_behavior_id && behavior.active == 1
        },

        getBehaviorDisplayName(behavior) {
            console.log(behavior)
            let nonDefaultAttrDisplay = _.map(behavior.attributes, 'value').join(', ') // FIXME remove default attrs

            return behavior.display_name
                + (nonDefaultAttrDisplay ? ` - ${nonDefaultAttrDisplay}` : '')
        },

        getBehaviorLocationAndComments(behavior) {
            let comments = behavior.comments
                ? (behavior.location_display_name ? ' - ' : '') + behavior.comments
                : ''

            return behavior.location_display_name + comments
        },

        icon(behavior) {
            return (this.savingKey == this.getKey(behavior)
                ? 'icon-beat '
                : '')
                + (this.isFavorite(behavior)
                    ? 'icon-heart'
                    : 'icon-heart-empty')
        },

        favorite: async function(behavior) {
            this.savingKey = this.getKey(behavior)
            await this.toggleFavoriteBehavior(behavior)
            this.savingKey = null
        },

        select(behavior) {
            this.selected = behavior
            this.populateFromFavorite(behavior)
        },
    },
}
</script>

<style lang="scss">
.favorite-behaviors-list {
    h3 {
        padding-left: 25px;
    }

    .scroll-wrapper {
        overflow-x: hidden;
        overflow-y: scroll;
        list-style: none;
        padding: 0;
    }

    .favorite-behavior-row {
        width: 100% !important;
        text-align: left !important;
        display: block;
        background-color: #fff;
        position: relative;
        margin-left: $g-pad;
        border-top: 1px solid $section-border;
        cursor: pointer;
        height: 45px;

        > i[class*="icon-"] {
            position: absolute;
            top: 50%;
            bottom: 0;
            left: 10px;
            margin-top: -8px;
            margin-bottom: 16px;
            cursor: pointer;

            &:hover {
                color: lighten($sr-red, 10%) !important;
            }
        }

        > p {
            display: block;
            position: relative;
            margin: 0 30px 0 35px;
            outline: none;
            text-decoration: none !important;

            &.fav-header {
                padding-top: 3px;

                &.no-sub-header {
                    line-height: 40px;
                }
            }

            &.fav-sub-header {
                font-size: smaller;
                padding-bottom: 2px;
                margin-top: -5px;

                .fav-comments {
                    color: $sr-gray-04;
                }
            }
        }

        &.selected,
        &:hover {
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0px;
                right: 0px;
                height: 100%;
                background: $sr-gray-07;
            }

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                width: 0;

                border-right: 11px solid $sr-gray-07; // width of the actual gray arrow
                left: -11px; // how far left to move the arrow (match border-right)

                border-top: 22px solid transparent;
                border-bottom: 22px solid transparent;
                margin-top: -22px; // how far down to vertically center the tip of the arrow (match border-top and border-bottom)
            }
        }

        &.selected,
        &.selected:hover {
            > p,
            i[class*="icon-"] {
                color: $white !important;
            }

            &:before {
                background: $sr-orange;
            }

            &:after {
                border-right-color: $sr-orange;
            }
        }
    }

    .empty-state {
        padding: 0;
        padding-left: 25px;
        padding-top: 50px;

        i {
            font-size: 6em;
        }

        h2 {
            margin-top: 12px;
            font-size: 2rem;
        }
    }
}

.icon-beat {
    animation: icon-beat 4s ease infinite;
}

@keyframes icon-beat {
    0% {
        transform: scale(1);
    }
    5% {
        transform: scale(1.25);
    }
    20% {
        transform: scale(1);
    }
    30% {
        transform: scale(1);
    }
    35% {
        transform: scale(1.25);
    }
    50% {
        transform: scale(1);
    }
    55% {
        transform: scale(1.25);
    }
    70% {
        transform: scale(1);
    }
}
</style>