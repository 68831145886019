var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editable-cell" }, [
    _vm.data.url
      ? _c("div", [
          _vm.cellSaving
            ? _c("div", { staticClass: "padme" }, [
                _c("span", { staticClass: "overflow-ellipsis" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.data.absence_type_code) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "icon-spinner icon-spin",
                  attrs: { title: "saving...", "tss-tooltip": "" }
                })
              ])
            : _vm.editing &&
              !_vm.saving &&
              _vm.data.can_i_edit &&
              !_vm.data.is_holiday &&
              !_vm.data.no_attendance
            ? _c(
                "div",
                { staticClass: "select-and-icon" },
                [
                  _c("TssSelect", {
                    class: { changed: _vm.data.changed },
                    attrs: {
                      label: "short_display_name",
                      flippable: false,
                      options: _vm.absenceTypesDisplay(_vm.cell),
                      loading: _vm.absenceTypesLoading,
                      filterBy: _vm.filterStartsWith
                    },
                    on: { input: _vm.selectFirstIfEmpty },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "option",
                          fn: function(option) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "overflow-ellipsis",
                                  attrs: {
                                    title: option.display_name,
                                    "tss-tooltip": ""
                                  }
                                },
                                [_vm._v(_vm._s(option.code))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4018553549
                    ),
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  }),
                  _vm._v(" "),
                  (_vm.selected && _vm.selected.absence_type_id) ||
                  _vm.data.class_absence_id
                    ? _c("i", {
                        staticClass: "icon-comment",
                        class: { "has-comments": _vm.data.comments },
                        attrs: { title: _vm.data.comments, "tss-tooltip": "" },
                        on: { click: _vm.showClassAbsenceModal }
                      })
                    : _vm._e()
                ],
                1
              )
            : _c("div", { staticClass: "flex padme" }, [
                _vm.data.class_absence_id &&
                (!_vm.data.can_i_edit ||
                  (_vm.data.staff_member_id == _vm.loggedInStaffMemberId &&
                    _vm.hasLockClassAttendance))
                  ? _c("i", {
                      staticClass: "gray icon-lock",
                      attrs: {
                        title:
                          "Locked for editing by " +
                          _vm.data.teacher_display_name,
                        "tss-tooltip": ""
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.data.is_holiday && !_vm.data.no_attendance
                  ? _c(
                      "a",
                      {
                        staticClass: "overflow-ellipsis block",
                        attrs: {
                          href: _vm.data.url,
                          title: _vm.getTitle(_vm.data),
                          "tss-tooltip": ""
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.getDisplayName(_vm.data)) +
                            "\n            "
                        )
                      ]
                    )
                  : _c(
                      "span",
                      {
                        staticClass: "overflow-ellipsis",
                        class: { faded: _vm.editing },
                        attrs: {
                          title: _vm.getTitle(_vm.data),
                          "tss-tooltip": ""
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.getDisplayName(_vm.data)) +
                            "\n            "
                        )
                      ]
                    ),
                _vm._v(" "),
                _vm.data.comments
                  ? _c("i", {
                      staticClass: "icon-comment has-comments",
                      attrs: { title: _vm.data.comments, "tss-tooltip": "" }
                    })
                  : _vm._e()
              ]),
          _vm._v(" "),
          _vm.data.period_id
            ? _c("div", { staticClass: "padme" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.timeDisplay(_vm.data)) +
                    "\n        "
                )
              ])
            : _vm._e()
        ])
      : _c("div", { staticClass: "padme" }, [
          _vm._v("\n        " + _vm._s(_vm.data.display_name) + "\n    ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }