var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "grid-controls" } }, [
    _vm.error
      ? _c("div", { staticClass: "alert alert-warning" }, [
          _c("p", [
            _c("i", { staticClass: "icon-info-sign" }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.error))])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "grid-controls flex" }, [
      _c("div", [
        _c("label", [_vm._v("week of")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tss-date" },
          [
            _c("TssDatepicker", {
              attrs: {
                dayCellContent: _vm.cellContent,
                highlighted: _vm.highlightedDates,
                value: _vm.dateObj,
                disabled: _vm.editing
              },
              on: { selected: _vm.reloadGrid }
            }),
            _vm._v(" "),
            _vm.dateDesc
              ? _c("i", {
                  staticClass: "icon-calendar holiday-indicator",
                  attrs: { title: _vm.dateDesc, "tss-tooltip": "" }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.hasData
        ? _c("div", { staticClass: "grow flex" }, [
            !_vm.editing
              ? _c(
                  "div",
                  {
                    staticClass: "btn btn-orange",
                    attrs: { title: "Edit" },
                    on: {
                      click: function($event) {
                        return _vm.setEditing(true)
                      }
                    }
                  },
                  [_c("i", { staticClass: "icon-pencil" })]
                )
              : _c("div", { staticClass: "grow flex" }, [
                  _c(
                    "div",
                    { staticClass: "grow" },
                    [
                      _c("label", [_vm._v("Bulk update attendance to")]),
                      _vm._v(" "),
                      _c("TssSelect", {
                        attrs: {
                          label: "display_name",
                          options: _vm.absenceTypesDisplay(),
                          loading: _vm.absenceTypesLoading,
                          disabled: _vm.saving
                        },
                        on: { input: _vm.selectFirstIfEmpty },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "option",
                              fn: function(option) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "overflow-ellipsis",
                                      attrs: { title: option.display_name }
                                    },
                                    [_vm._v(_vm._s(option.display_name))]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1089418200
                        ),
                        model: {
                          value: _vm.selected,
                          callback: function($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "grow" }, [
                    _c("input", {
                      attrs: { type: "text", placeholder: "Comment" },
                      on: {
                        input: function(e) {
                          return _vm.setBulkUpdateComment(e.target.value)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "btn-group" }, [
                    _c(
                      "div",
                      {
                        staticClass: "btn btn-green",
                        class: { disabled: _vm.saving },
                        attrs: { title: "Save" },
                        on: { click: _vm.handleBulkUpdateClassAbsences }
                      },
                      [
                        _vm._v(
                          "\n                        Save\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "btn btn-gray",
                        class: { disabled: _vm.saving },
                        attrs: { title: "Cancel" },
                        on: { click: _vm.handleCancelUpdate }
                      },
                      [
                        _vm._v(
                          "\n                        Cancel\n                    "
                        )
                      ]
                    )
                  ])
                ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }