<template>
    <div id="behaviors-app">
        <PageStickyButtons />
        <TssProgressBar
            v-if="Object.keys(saveProgress).length"
            :rows="saveProgress"
            header="Saving Progress" />
        <h1>
            {{ getText('page_title') }} <a href="/behaviors/history" class="edit">view full history</a>
        </h1>
        <PageControls />
        <BehaviorRowList />
    </div>
</template>

<script>
import _ from 'tss/lodash'
import moment from 'moment-timezone'
import { mapActions, mapState, mapGetters } from 'vuex'
import TssProgressBar from 'shared/TssProgressBar.vue'
import PageStickyButtons from './PageStickyButtons.vue'
import PageControls from './PageControls.vue'
import BehaviorRowList from './BehaviorRowList.vue'

export default {
    props: [
        'school_id',
        'logged_in_user_id',
        'staff_member',
        'student_group_id',
        'date',
        'text',
        'settings',
        'auth',
    ],

    components: {
        TssProgressBar,
        PageStickyButtons,
        PageControls,
        BehaviorRowList,
    },

    computed: {
        ...mapState([
            'loading',
            'saveProgress',
            'hasAnyData',
        ]),
        ...mapGetters([
            'getSetting',
            'getText',
        ]),
    },

    methods: {
        ...mapActions([
            'setSchool',
            'setLoggedInUserId',
            'setDate',
            'setTextReplacements',
            'setLoading',
            'setAuth',
            'setSettings',
            'setStudentGroup',
            'setStaffMember',
            'loadDates',
            'loadLocations',
            'loadBehaviorTypes',
            'loadStudentGroups',
            'loadDemeritAttributeTypes',
            'loadStudents',
            'loadStaffMembers',
        ]),
    },

    async mounted() {
        this.setSchool(this.school_id)
        this.setLoggedInUserId(this.logged_in_user_id)
        this.setDate(this.date)
        this.setTextReplacements(this.text)
        this.setLoading(false)
        this.setAuth(this.auth)
        this.setSettings(this.settings)

        if (this.student_group_id) {
            this.setStudentGroup({ student_group_id: this.student_group_id })
        }

        if (this.staff_member) {
            this.setStaffMember(this.staff_member)
        }

        // load all in parallel
        this.loadBehaviorTypes()
        this.loadDates() // termbin start/end dates
        this.loadLocations()
        this.loadDemeritAttributeTypes()
        this.loadStudents()
        this.loadStaffMembers()
        this.loadStudentGroups()
    },
}
</script>