<template>
    <div class="manage-student-access-code">
        <SpencilLoader v-if="!accessCode"></SpencilLoader>
        <div v-if="accessCode">
            <section class="white code-section">
                <h3 class="no-margin text-center">
                    <span
                        class="access-code"
                        :class="{ 'highlight-code': highlightCode }">
                        {{ accessCode }}
                    </span>
                        <i
                            class="icon-copy green"
                            title="copy to clipboard"
                            v-clipboard:copy="accessCode"
                            v-clipboard:success="copySuccess"
                            v-clipboard:error="copyError"
                            >
                        </i>
                </h3>
                <p class="text-center">
                    <!-- don't use v-else here because we want neither to show if copySucceeded is null -->
                    <span v-if="copySucceeded == true" class="green" >Copied to clipboard!</span>
                    <span v-if="copySucceeded == false" class="red">Failed to copy.</span>
                </p>
                <div class="row gutters">
                    <div class="col span-24">
                        <p v-if="!newCode">
                            This code can be used to create an account with access to this student’s information in the Schoolrunner portal. It can also be used by an existing account to add this student’s info to the portal.
                        </p>
                        <p v-else>
                            Access code has been reset! This new code can be used to create new guardian accounts or add this student to an existing account.
                        </p>
                    </div>
                </div>
            </section>
            <section class="white code-section no-margin">
                <div class="col span-24">
                    <p>Resetting this code prevents anyone else from using the old code to gain access to this student's info in the portal. It does not revoke access from parents/guardians who signed up with the old code.</p>
                </div>
            </section>
            <section class="bg-gray-09 footer">
                <div class="row">
                    <div class="col span-24 text-right">
                        <button
                            type="button"
                            class="btn btn-grey close"
                            @click="$emit('close')">
                            Done
                        </button>
                        <button
                            type="button"
                            class="btn btn-red"
                            :disabled="resetting"
                            @click="resetAccessCode">
                            {{ resetting ? 'Resetting' : 'Reset Access Code'  }}
                        </button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import SpencilLoader from 'shared/Loaders/SpencilLoader.vue'

export default {
    components: { SpencilLoader },
    data() {
        return {
            newCode: '',
            resetting: false,
            highlightCode: false,
            copySucceeded: null
        }
    },
    computed: {
        ...mapGetters([
            'accessCode',
            'studentId'
        ])
    },
    methods: {
        ...mapActions([
            'createStudentAccessCode'
        ]),
        resetAccessCode: async function() {
            if (window.confirm("Are you sure you want to reset the access code? The new code will need to be distributed to any parent/guardian who doesn't already have access to this student.")) {
                this.resetting = true
                await this.createStudentAccessCode(this.studentId)
                this.newCode = this.accessCode
                this.resetting = false
                this.highlightCode = true;
                setTimeout(() => { this.highlightCode = false }, 2000)
            }
        },
        copySuccess: function() {
            this.copySucceeded = true
        },
        copyError: function() {
            this.copySucceeded = false
        },
    },
}
</script>

<style lang="scss">
.manage-student-access-code {
    .access-code {
        transition: all .2s;

        &.highlight-code {
            color: $sr-green;
            font-size: 3rem;
        }
    }
    .code-section {
        margin-top: 20px;
    }
    .footer {
        bottom: 0px;
        width: 100%;
        box-sizing: border-box;
    }
    h3 span {
        user-select: text;
    }
    .btn {
        line-height: 16px;
        font-family: $font-stack !important;
    }
    .icon-copy {
        // font-weight: bold;
        &:hover {
            color: lighten($sr-green, 10%) !important;
            cursor: pointer;
        }
    }
}
</style>
